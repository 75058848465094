import { ApiResponse } from "../api/ApiResponse";
import ISubscriptionListMap from "../subscription/list/ISubscriptionListMap";
import MarketListCacheProvider from "./internal/MarketListCacheProvider";
import { IMarket, MarketCategory } from "./type";

export class MarketServiceImpl {
    private get marketsCache(): ISubscriptionListMap<IMarket, unknown> {
        return MarketListCacheProvider.get();
    }

    public getMarkets(sportId: string): Promise<ApiResponse<IMarket[]>> | IMarket[] {
        return this.marketsCache.get(sportId);
    }

    public subscribe(sportId: string, callback: (transactions: IMarket[]) => void): () => void {
        return this.marketsCache.subscribe(sportId, callback);
    }

    public getCategoryName(category: MarketCategory): string {
        switch (category) {
            case MarketCategory.Win: return "Win";
            case MarketCategory.Statistics: return "Statistics";
            case MarketCategory.Score: return "Score";
            case MarketCategory.Handicap: return "Handicap";
            case MarketCategory.Player: return "Player";
            case MarketCategory.Goals: return "Goals";
            default:
                throw new Error(`Unknown market category id ${category}`);
        }
    }

    private static throwMarketNotFound(marketId: string): never {
        throw new Error("Did not find a market with id " + marketId);
    }

    public findMarket(marketId: string, markets: IMarket[], overrideNotFoundMessage?: string): IMarket {
        for (const market of markets) {
            if (marketId === market.id) {
                return market;
            }
        }

        if (overrideNotFoundMessage !== undefined) {
            throw new Error(overrideNotFoundMessage);
        }

        MarketServiceImpl.throwMarketNotFound(marketId);
    }

    public findMarketCategory(marketId: string, markets: IMarket[]): MarketCategory {
        return this.findMarket(marketId, markets).category;
    }

    public findMarketName(marketId: string, markets: IMarket[]): string {
        return this.findMarket(marketId, markets).name;
    }

    public findPositionInCategory(marketId: string, markets: IMarket[]): number {
        let positionInCategory = 0;
        const lookingForCategory = this.findMarketCategory(marketId, markets);

        for (const market of markets) {
            if (market.id === marketId) {
                return positionInCategory;
            }

            if (lookingForCategory === this.findMarketCategory(market.id, markets)) {
                ++positionInCategory;
            }
        }

        MarketServiceImpl.throwMarketNotFound(marketId);
    }
}

export default new MarketServiceImpl();
