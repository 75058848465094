import AnimatedList from "./AnimatedList";
import ExpandingRow from "./expanding-row/ExpandingRow";
import { ExpandingRowListProps, IHeaderData } from "./types";

function ExpandingRowList<DataType extends object, HeaderDataType extends IHeaderData, AdditionalData extends object = {}>({
    rowClassName,
    component,
    headerComponent,
    headerRowClassName,
    onRowClick,
    ...props
}: ExpandingRowListProps<DataType, HeaderDataType, AdditionalData>) {

    return (
        <AnimatedList
            rowConfiguration={{
                wrapperComponent: ExpandingRow,
                rowComponent: component,

                className: rowClassName,
                onClick: onRowClick,
            }}
            headerConfiguration={headerComponent && {
                wrapperComponent: ExpandingRow,
                rowComponent: headerComponent,

                className: headerRowClassName,
            }}
            {...props}
        />
    );
}

export default ExpandingRowList;
