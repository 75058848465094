import { ReadyOrNot, isReady } from ".";
import { createSuccess, ApiResponse, isSuccess, ApiSuccessResponse } from "../api/ApiResponse";

const makeAllPromises = (sources: ReadyOrNot<any>[]): Promise<any>[] => {
    return sources.map(async source => {
        if (isReady(source)) {
            return Promise.resolve(source);
        }

        const response = await (source as Promise<ApiResponse<any>>);
        if (isSuccess(response)) {
            return response.data;
        }

        throw new Error(response.message);
    });
};

const allReady = (sources: ReadyOrNot<any>[]) => sources.every(isReady);

function all(sources: ReadyOrNot<any>[]): any[] | Promise<ApiSuccessResponse<any[]>> {
    return allReady(sources) ?
        [...sources] :
        Promise.all(makeAllPromises(sources))
            .then(createSuccess);
}

export default all;
