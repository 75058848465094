import { useCallback, useState } from "react";
import * as React from "react";
import { SubmitFunction } from "./types";
import DumbForm from "./internal/DumbForm";
import SubmitRequest from "./internal/SubmitRequest";
import MessageBox from "../message-box/MessageBox";
import { IConfirmPrompt } from "../confirm/types";
import UseObserver, { Subscriber } from "../subscription/observer/UseObserver";

export interface RequestFormProps {
    children: React.ReactNode;
    disabled?: boolean;
    legend: string;
    onSubmit: SubmitFunction;
    submitCaption: string;
    onSubmitSuccess?: () => void;
    confirmation?: IConfirmPrompt;
    clearMessageEvents?: Subscriber<void>;
}

const RequestForm: React.FC<RequestFormProps> = ({onSubmit, onSubmitSuccess, ...props}) => {
    const [makeSubmitRequest, inProgress, successMessage, errorMessage, clearPane] = SubmitRequest.useSubmit(onSubmit, onSubmitSuccess);
    const [isConfirming, setIsConfirming] = useState(false);
    const onDismiss = useCallback(() => setIsConfirming(false), []);
    const onConfirm = useCallback(() => {
        setIsConfirming(false);
        makeSubmitRequest(null);
    }, [makeSubmitRequest]);
    const openConfirmation = useCallback((e: React.FormEvent) => {
        e.preventDefault();
        setIsConfirming(true);
    }, []);

    UseObserver.useOnObserverChange(props.clearMessageEvents, clearPane);

    return (
        <>
            <DumbForm onSubmit={props.confirmation ? openConfirmation : makeSubmitRequest}
                disabled={props.disabled || inProgress}
                errorMessage={errorMessage}
                legend={props.legend}
                loading={inProgress}
                submitCaption={props.submitCaption}
                successMessage={successMessage}>
                {props.children}
            </DumbForm>
            {
                props.confirmation ? <MessageBox
                    isOpen={isConfirming}
                    title={props.confirmation.title}
                    description={props.confirmation.body}
                    yesCaption={props.confirmation.confirmCaption}
                    noCaption={props.confirmation.cancelCaption}
                    onNo={onDismiss}
                    onYes={onConfirm}
                /> : null
            }
        </>
    );
};

export default RequestForm;
