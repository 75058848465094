import { Fetch, createErrorFetch, createDoneFetch, isDone } from "../../fetch";
import useAccountsOfType from "./useAccountsOfType";
import { AccountType, AccountLike } from "../types";
import NeverChange from "../../lifecycle/NeverChange";
import CanonicalNameService from "../canon/CanonicalNameService";

function useAccountOfType(type: AccountType, id: string): Fetch<AccountLike> {
    NeverChange.useNeverChange(id);

    const accounts = useAccountsOfType.useAccountsOfType(type);

    if (isDone(accounts)) {
        const out = accounts.payload.find(({name}) => id === CanonicalNameService.getCanonicalName(name));

        return out ?
            createDoneFetch(out) :
            createErrorFetch(`Could not find account of type ${type} with id: ${id}`);
    }

    return accounts;
}

const exports = {
    useAccountOfType,
};

export default exports;
