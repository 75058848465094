import * as React from "react";
import Money from "../../../money/Money";

interface Props {
    adjustment: number | string;
    currentBalance: number;
    currencyCode: string;
}

const DescribeAdjustment: React.FC<Props> = ({adjustment, currentBalance, currencyCode}) => {
    const adjustmentAsNumber = Number(adjustment);

    if (isNaN(adjustmentAsNumber) && adjustment !== "") {
        return null;
    }

    if (adjustmentAsNumber === 0 || adjustment === "") {
        return <span>Balance is currently <Money amount={currentBalance} currency={currencyCode} /></span>;
    }

    const newBalance = currentBalance + adjustmentAsNumber;

    if (adjustment > 0) {
        return <span>Balance will increase to <Money amount={newBalance} currency={currencyCode} /></span>;
    }

    return <span>Balance will decrease to <Money amount={newBalance} currency={currencyCode} /></span>;

};

export default DescribeAdjustment;
