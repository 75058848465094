import API from "../api";
import LoginUserErrors from "./LoginUserErrors";

interface LoginBody {
    email: string;
}

const createBody = (email: string): LoginBody => ({email});

class LoginService {
    private loginAttempCallbacks: Set<() => void> = new Set();

    public async loginUser(emailAddress: string): Promise<void> {
        const response = await this.makeLoginRequest(emailAddress);
        if (response.successful === false) {
            LoginUserErrors.throwHumanReadableError(response.status);
        }
    }

    public registerLoginAttemptListener(onLoginAttempt: () => void): () => void {
        this.loginAttempCallbacks.add(onLoginAttempt);

        return () => {
            this.loginAttempCallbacks.delete(onLoginAttempt);
        };
    }

    private makeLoginRequest(emailAddress: string) {
        this.loginAttempCallbacks.forEach((callback) => {
            callback();
        });

        return API.postJson("login", createBody(emailAddress))
            .catch((e) => {
                throw new Error(`Unexpected error: "${e.message}"`);
            });
    }
}

export default new LoginService();
