import { AxiosError, AxiosResponse } from "axios";
import { HttpResponse } from "./HttpResponse";

const SUCCESS = Object.freeze({successful: true});
const FAILED = Object.freeze({successful: false});

class ResponseAdapter {
    public adaptResponse<DataType>(response: AxiosResponse): HttpResponse<DataType> {
        return {
            ...response,
            ...SUCCESS,
        };
    }

    public adaptError<DataType = any>(error: AxiosError): HttpResponse<DataType> {
        const asResponse: AxiosResponse<DataType> = error.response as AxiosResponse<DataType>;
        const errorReplacementMaybe = (asResponse && asResponse.statusText) ? undefined : {statusText: error.message};

        return Object.assign({}, asResponse, FAILED, errorReplacementMaybe);
    }
}

export default new ResponseAdapter();
