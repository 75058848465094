import TransactionsListCacheProvider from "./TransactionsListCacheProvider";
import ISubscriptionListMap from "../../subscription/list/ISubscriptionListMap";
import { ITransaction, ISink } from "../types";
import { createAccountKey } from "./key";
import CanonicalNameService from "../../accounts/canon/CanonicalNameService";

const sinkToKey = (sink: ISink) => createAccountKey(sink.type, CanonicalNameService.getCanonicalName(sink.name));

class WaitOnOriginatorTransactions {
    private get transactionsCache(): ISubscriptionListMap<ITransaction, unknown> {
        return TransactionsListCacheProvider.get();
    }

    public async waitOn(destination: ISink, source?: ISink) {
        await this.transactionsCache.get(sinkToKey(source || destination));
    }
}

export default new WaitOnOriginatorTransactions();
