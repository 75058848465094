import styles from "../BetRow.module.scss";
import { IBet } from "../../../service/types";
import { SelectedBetForEditingState } from "../../../hooks/WithEditingBet";
import ActionsInteraction from "./interactions/ActionsInteraction";
import StopEditingInteraction from "./interactions/StopEditingInteraction";

interface InteractionsProps {
    bet: IBet;
    eventId: string;
    mouseEnteredRow: boolean;
    selectedBetForEditingState: SelectedBetForEditingState;
}

const Interactions = ({
    bet,
    eventId,
    mouseEnteredRow,
    selectedBetForEditingState,
}: InteractionsProps) => {
    const isEditing = bet.id === selectedBetForEditingState[0];

    return (
        <div className={styles.right}>
            {
                isEditing && <StopEditingInteraction clearEditingBet={selectedBetForEditingState[2]} />
            }
            {
                !isEditing && <ActionsInteraction
                    bet={bet}
                    eventId={eventId}
                    mouseEnteredRow={mouseEnteredRow}
                    selectedBetForEditingState={selectedBetForEditingState}
                />
            }
        </div>
    );
};

export default Interactions;
