import NeverChange from "../../lifecycle/NeverChange";
import Once from "../../lifecycle/Once";
import ReactHooks from "../../react/ReactHooks";
import BetService from "../service/BetService";

function useBetIsCancelling(betId: string) {
    NeverChange.useNeverChange(betId);

    const [isCancelling, setIsCancelling] = ReactHooks.useState(BetService.queryBetCancellation(betId));

    Once.useEffectOnce(() => {
        return BetService.subscribeToBetCancellation(betId, (newIsCancelling: boolean) => {
            setIsCancelling(newIsCancelling);
        });
    });

    return isCancelling;
}

function useBetIsDeleting(betId: string) {
    NeverChange.useNeverChange(betId);

    const [isDeleting, setIsDeleting] = ReactHooks.useState(BetService.queryBetDeletion(betId));

    Once.useEffectOnce(() => {
        return BetService.subscribeToBetDeletion(betId, (newIsDeleting: boolean) => {
            setIsDeleting(newIsDeleting);
        });
    });

    return isDeleting;
}

const exports = {
    useBetIsCancelling,
    useBetIsDeleting,
};

export default exports;
