import { SettleBetData } from "./types";

class SettleBetStore implements SettleBetData {
    private readonly partResults = new Map<number, string>();

    setPartPoints(part: number, value: string)  {
        this.partResults.set(part, value);
    }

    getPartPoints(part: number): string {
        const storedValue = this.partResults.get(part);

        return storedValue === undefined ? "" : storedValue;
    }
}

export default SettleBetStore;
