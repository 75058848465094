import AxiosProvider from "./axios-provider";
import RequestExecutor from "./RequestExecutor";
import createConfig, { BLANK_CONFIG } from "./createConfig";

function post(uri: string) {
    return RequestExecutor.wrapAxios(AxiosProvider.getInstance().post(uri, undefined, BLANK_CONFIG));
}

function deleteIt(uri: string) {
    return RequestExecutor.wrapAxios(AxiosProvider.getInstance().delete(uri, BLANK_CONFIG));
}

function postJson<DataType>(uri: string, body: object) {
    return RequestExecutor.wrapAxios<DataType>(AxiosProvider.getInstance().post(uri, body, BLANK_CONFIG));
}

function putJson(uri: string, body: object) {
    return RequestExecutor.wrapAxios(AxiosProvider.getInstance().put(uri, body, BLANK_CONFIG));
}

function patchJson(uri: string, body: object) {
    return RequestExecutor.wrapAxios(AxiosProvider.getInstance().patch(uri, body, BLANK_CONFIG));
}

function postText<DataType>(uri: string, body: string) {
    return RequestExecutor.wrapAxios<DataType>(AxiosProvider.getInstance().post(uri, body, createConfig(undefined,
        {
            "Content-Type": "text/plain",
        },
    )));
}

function get<DataType>(uri: string, params?: object, headers?: object) {
    return RequestExecutor.wrapAxios<DataType>(AxiosProvider.getInstance().get(uri, createConfig(params, headers)));
}

const exports = {
    get,
    patchJson,
    post,
    postJson,
    postText,
    putJson,
    delete: deleteIt,
};

export default exports;
