import { useState } from "react";
import Once from "../../lifecycle/Once";
import UseOnScreenSizeChange from "../../screen/hooks/UseOnScreenSizeChange";

const hooks = {
    useOverlayControl,
    useAutoCloseWhenNotPhoneOverlayControl,
};

function useOverlayControl(): [boolean, () => void, () => void] {
    const [isActive, setIsActive] = useState(false);

    const open = Once.useCallbackOnce(() => {
        setIsActive(true);
    });

    const close = Once.useCallbackOnce(() => {
        setIsActive(false);
    });

    return [isActive, open, close];
}

function useAutoCloseWhenNotPhoneOverlayControl(): [boolean, () => void, () => void] {
    const output = hooks.useOverlayControl();
    UseOnScreenSizeChange.useOnNolongerPhone(output[2]);
    return output;
}

const exports = {
    hooks,
};

export default exports;
