import { useState } from "react";
import * as React from "react";
import AuthenticationService from "../../../authentication/AuthenticationService";
import Once from "../../../lifecycle/Once";

export interface LoggedInProps {
    children: React.ReactNode;
}

const LoggedIn: React.FC<LoggedInProps> = (props) => {
    const [isAuthenticated, setIsAuthenticated] = useState(AuthenticationService.isLoggedIn());

    Once.useEffectOnce(() => {
        return AuthenticationService.registerAuthenticationListener(() => {
            setIsAuthenticated(AuthenticationService.isLoggedIn());
        });
    });

    if (isAuthenticated) {
        return (
            <>
                {props.children}
            </>
        );
    }

    return null;
};

export default LoggedIn;
