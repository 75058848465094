import { SubmitFunction } from "../../../../form/types";
import EventsService from "../../../EventsService";
import DateFactory from "../../../../date/DateFactory";
import { useCallback } from "react";

function useSubmit(name: string, sportId: string, dateYyyyMmDd: string, time: string): SubmitFunction {
    const doSubmit: SubmitFunction = useCallback(async () => {
        await EventsService.addEvent({
            name,
            sportId,
            when: DateFactory.parseInUsersTimeZone(dateYyyyMmDd, time),
        });

        return "New event created";
    }, [name, sportId, dateYyyyMmDd, time]);

    return doSubmit;
}

const exports = {
    useSubmit,
};

export default exports;
