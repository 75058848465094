import { useCallback } from "react";
import * as React from "react";
import SelectResult from "../../../markets/components/select/SelectResult";
import { IMarket } from "../../../markets/type";
import ScoreInput from "../../../score/components/ScoreInput";
import CategoryToInputType from "./CategoryToInputType";

interface Props {
    market: IMarket;
    onChange: (newValue: string) => void;
    value: string;
}

const ResultInput: React.FC<Props> = ({market, onChange, value}) => {
    const inputType = CategoryToInputType.categoryToInputType(market.category);

    const onSelectChange: (e: React.ChangeEvent<HTMLSelectElement>) => void = useCallback((e) => {
        onChange(e.target.value);
    }, [onChange]);

    const onValueChanged = useCallback((newValue: string) => {
        onChange(newValue);
    }, [onChange]);

    if (inputType === "results-dropdown") {
        return (
            <SelectResult
                data-testid="result-input-dropdown"
                onChange={onSelectChange}
                options={market.results}
                value={value}
            />
        );
    }

    if (inputType === "score") {
        return (
            <ScoreInput
                onChange={onValueChanged}
                value={value}
            />
        );
    }

    return (
        <input />
    );
};

export default ResultInput;
