import * as React from "react";
import classnames from "classnames";
import styles from "./VerticalDots.module.scss";

const VerticalDots = ({className, ...props}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
    return (
        <div
            className={classnames(styles.verticalDots, className)}
            {...props}
        >
            ⋮
        </div>
    );
};

export default VerticalDots;
