import { ISport } from "../Sport";
import { IReadOnlyStore } from "../../subscription/store/IStore";
import SubscriptionStoreFactory from "../../subscription/store/SubscriptionStoreFactory";
import LoadSports from "./loadSports";

export class SportsStoreProviderImpl {
    private store?: IReadOnlyStore<ISport[]>;

    public get(): IReadOnlyStore<ISport[]> {
        if (!this.store) {
            this.store = SubscriptionStoreFactory.create(() => LoadSports.loadSports());
        }

        return this.store;
    }
}

export default new SportsStoreProviderImpl();
