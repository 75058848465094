import { useState } from "react";
import Once from "../../lifecycle/Once";
import MountHooks from "../../lifecycle/mount/hooks";
import { Fetch, fromResponse, createLoadingFetch, createDoneFetch, isReady, ReadyOrNot } from "..";

function useFetchRequest<T>(makeRequest: () => ReadyOrNot<T>): [Fetch<T>, (value: T) => void] {
    const isMounted = MountHooks.useIsMounted();

    const [initialRequest] = useState(() => makeRequest());

    const [fetch, setFetch] = useState(() => {

        if (isReady(initialRequest)) {
            return createDoneFetch(initialRequest);
        }

        return createLoadingFetch<T>();
    });

    const overrideData = Once.useCallbackOnce((value: T) => {
        setFetch(createDoneFetch(value));
    });

    Once.useEffectOnce(() => {
        if (isReady(initialRequest)) {
            return;
        }

        initialRequest
            .then((response) => {
                if (isMounted.current) {
                    setFetch(fromResponse(response));
                }
            });

    });

    return [fetch, overrideData];
}

const exports = {
    useFetchRequest,
};

export default exports;
