import * as React from "react";
import Button from "../../../../button/Button";
import Phone from "../../../../screen/Phone";
import styles from "./ActionButton.module.scss";

interface Props {
    caption: string;
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const ActionButton: React.FC<Props> = ({caption, onClick}) => {
    return (
        <Phone>
            <Button
                className={styles.action}
                data-testid="action-button"
                kind="secondary"
                onClick={onClick}
            >
                    {caption}
            </Button>
        </Phone>
    );
};

export default ActionButton;
