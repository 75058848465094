import Navigate from "../../../routing/Navigate";
import { IEvent } from "../../types";

function useOnEventRowClick() {
    return ({id}: IEvent) => {
        Navigate.to(`/events/${id}`);
    };
}

const exports = {
    useOnEventRowClick,
};

export default exports;
