import { IAccount } from "../../../../accounts/types";
import NumberFormatter from "../../../../numbers/format/NumberFormatter";
import Odds from "../../../../odds/components/Odds";
import StakeAtOdds from "../../../../odds/components/StakeAtOdds";
import { IStakeAndOdds } from "../../../service/types";

interface StakeProps {
    account?: IAccount;
    bet: IStakeAndOdds;
}

const Stake = ({
    account,
    bet,
}: StakeProps) => {
    return account ?
        <StakeAtOdds currencyCode={account.currencyCode} odds={bet.odds} stake={bet.stake} /> :
        <span data-testid="stake-no-currency">{
            NumberFormatter.toLocaleString(bet.stake)}<Odds odds={bet.odds} />
        </span>;
};

export default Stake;
