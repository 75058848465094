import * as React from "react";
import classnames from "classnames";
import Cross from "../icons/cross.svg";
import styles from "./CloseButton.module.scss";

interface Props {
    className?: string;
    description: string;
    onClick: (event: React.MouseEvent<HTMLImageElement>) => void;
}

const CloseButton: React.FC<Props> = (props) => {

    return (
        <img
            alt={props.description}
            aria-expanded={true}
            aria-label={props.description}
            className={classnames(styles.close, props.className)}
            onClick={props.onClick}
            role="button"
            src={Cross}
            tabIndex={0}
        />
    );
};

export default CloseButton;
