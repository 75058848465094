import MarketService from "../../markets/MarketService";
import { IMarket } from "../../markets/type";
import { IBet } from "../service/types";

class CompareBetsFactory {
    public createBetComparator: (markets: IMarket[]) => (a: IBet, b: IBet) => number = (markets: IMarket[]) => {
        const findCategory = (marketId: string) => MarketService.findMarketCategory(marketId, markets);
        const findPositionInCategory = (marketId: string) => MarketService.findPositionInCategory(marketId, markets);

        const compareBets = (a: IBet, b: IBet): number => {
            const categoryA = findCategory(a.selection.marketId);
            const categoryB = findCategory(b.selection.marketId);

            if (categoryA === categoryB) {
                const positionInCategoryA = findPositionInCategory(a.selection.marketId);
                const positionInCategoryB = findPositionInCategory(b.selection.marketId);

                if (positionInCategoryA === positionInCategoryB) {
                    return b.placedAt.getTime() - a.placedAt.getTime();
                }

                return positionInCategoryA - positionInCategoryB;
            }

            return categoryA - categoryB;
        };

        return compareBets;
    }
}

export default new CompareBetsFactory();
