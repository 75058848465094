import { useEffect, useState } from "react";
import Once from "../../../lifecycle/Once";
import UseOverlayIsOpen from "../UseOverlayIsOpen";

function useMouseEnteredFunctions(): [boolean, () => void, () => void] {
    const [isEntered, setIsEntered] = useState(false);
    const onMouseEnter = Once.useCallbackOnce(() => setIsEntered(true));
    const onMouseLeave = Once.useCallbackOnce(() => setIsEntered(false));

    const overlayIsOpen = UseOverlayIsOpen.useOverlayIsOpen();
    useEffect(() => {
        return onMouseLeave;
    }, [overlayIsOpen, onMouseLeave]);

    return [isEntered, onMouseEnter, onMouseLeave];
}

const exports = {
    useMouseEnteredFunctions,
};

export default exports;
