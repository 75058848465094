import IHasId from "../id/IHasId";

export interface ISport extends IHasId {
    name: string;
}

const createSport = (id: string, name: string): ISport => ({
    id,
    name,
});

export {
    createSport,
};
