import { useState } from "react";
import * as React from "react";
import styles from "./Overlay.module.scss";
import { CSSTransition } from "react-transition-group";
import classnames from "classnames";
import WithSelection from "../window/selection/hooks/WithSelection";
import UseNextId from "../numbers/UseNextId";
import Once from "../lifecycle/Once";
import OverlayService from "./OverlayService";

interface InternalProps {
    "data-testid"?: string;
    onClick?: () => void;
    className?: string;
}

interface OverlayProps extends InternalProps {
    open: boolean;
}

const Internal: React.FC<InternalProps> = (props) => {
    WithSelection.useRemoveAllRangesOnMount();

    return (
        <div className={classnames(styles.overlay, props.className)} onClick={props.onClick} data-testid={props["data-testid"]}>
            {props.children}
        </div>
    );
};

const Overlay: React.FC<OverlayProps> = (props) => {
    const overlayId = UseNextId.useNextId("overlay-");

    const [previousOpenProp, setPreviousOpenProp] = useState<boolean>();

    if (props.open && !previousOpenProp) {
        OverlayService.notifyOpen(overlayId);
        setPreviousOpenProp(props.open);
    }

    if (previousOpenProp && !props.open) {
        OverlayService.notifyClosed(overlayId);
        setPreviousOpenProp(props.open);
    }

    Once.useEffectOnce(() => () => OverlayService.notifyClosed(overlayId));

    return (
        <CSSTransition
            unmountOnExit={true}
            mountOnEnter={true}
            appear={props.open}
            in={props.open}
            timeout={150}
            classNames={{
                enter: styles.animEnter,
                enterActive: styles.animEnterActive,
                exit: styles.animExit,
                exitActive: styles.animExitActive,
            }}
        >
            <Internal {...props} />
        </CSSTransition>
    );
};

export default Overlay;
