import { useState, useCallback } from "react";
import * as React from "react";
import GotoLogin from "../routing/components/redirects/GotoLogin";
import LogoutService from "./LogoutService";
import RequestForm from "../form/RequestForm";
import { SubmitFunction } from "../form/types";
import { IConfirmPrompt } from "../confirm/types";

const CONFIRM_CONFIGURATION: IConfirmPrompt = {
    title: "Logout",
    body: "Are you sure you want to log out of your account on this device?",
    confirmCaption: "Yes, logout",
    cancelCaption: "No",
};

const Logout: React.FC = () => {
    const [isComplete, setIsComplete] = useState(false);

    const onSubmitClick: SubmitFunction = async () => {
        await LogoutService.logout();
        return "Logged out";
    };

    const onSubmitSuccess = useCallback(() => {
        setIsComplete(true);
    }, [setIsComplete]);

    if (isComplete) {
        return <GotoLogin redirectNotificationType="logged-out" />;
    }

    return (
        <RequestForm
            onSubmit={onSubmitClick}
            onSubmitSuccess={onSubmitSuccess}
            legend="Logout of your account"
            submitCaption="Logout"
            confirmation={CONFIRM_CONFIGURATION}
        >
            <>
            </>
        </RequestForm>
    );
};

export default Logout;
