import { useMemo } from "react";
import * as React from "react";
import AccountList from "../list/AccountList";
import Card from "../../../card/Card";
import FetchGuard from "../../../fetch/FetchGuard";
import { AccountType } from "../../types";
import useAccountsOfType from "../../hooks/useAccountsOfType";
import { capitaliseFirstLetter } from "../../../text/StringUtilities";

interface Props {
    accountType: AccountType;
}

const AccountsDashboard: React.FC<Props> = ({accountType}: Props) => {
    const fetch = useAccountsOfType.useAccountsOfType(accountType);
    const headerText = useMemo(() => `${capitaliseFirstLetter(accountType)}s`, [accountType]);

    return (
        <div>
            <Card>
                <h1 data-testid="account-type-header">{headerText}</h1>
                <FetchGuard fetch={fetch} component={AccountList} additionalData={{accountType}}/>
            </Card>
        </div>
    );
};

export default AccountsDashboard;
