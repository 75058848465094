import { useLocation } from "react-router-dom";
import * as React from "react";
import IsSidebarRequired from "../../IsSidebarRequired";

export interface IfShowSidebarProps {
    children: React.ReactNode;
}

const IfShowSidebar: React.FC<IfShowSidebarProps> = (props) => {
    const location = useLocation();

    if (IsSidebarRequired.forLocation(location.pathname)) {
        return (
            <>
                {props.children}
            </>
        );
    }

    return null;
};

export default IfShowSidebar;
