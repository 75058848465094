import WindowProvider from "./WindowProvider";

class WindowResizeObserverFactory {
    public createListener(onResize: () => void): () => void {
        WindowProvider.getWindow().addEventListener("resize", onResize);

        return () => {
            WindowProvider.getWindow().removeEventListener("resize", onResize);
        };
    }
}

export default new WindowResizeObserverFactory();
