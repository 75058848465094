import Action from "../../../../layout/action/Action";
import { IBet } from "../../../../bets/service/types";
import { IMarket } from "../../../../markets/type";
import SettleBet from "../../../../settle/SettleBet";
import { Fetch } from "../../../../fetch";

export interface SettleActionProps {
    betsFetch: Fetch<IBet[]>;
    markets: IMarket[];
    sportId: string;
}

const SettleAction = ({
    betsFetch,
    markets,
    sportId,
}: SettleActionProps) => {

    return (
        <Action
            betsFetch={betsFetch}
            caption="Settle event"
            component={SettleBet}
            markets={markets}
            sportId={sportId}
        />
    );
};

export default SettleAction;
