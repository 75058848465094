import { useMemo } from "react";
import * as React from "react";
import { IActionComponentProps } from "../layout/action/ActionComponent";
import Card from "../card/Card";
import BetSlipForm from "./internal/BetSlipForm";
import { IAccount } from "../accounts/types";
import { IBet } from "../bets/service/types";
import { IEvent } from "../events/types";
import { IMarket } from "../markets/type";
import isNotEmpty from "../array/isNotEmpty";

interface Props {
    editingBet?: IBet;
    event: IEvent;
    markets: IMarket[];
    accounts: IAccount[];
}

const BetSlip: React.FC<IActionComponentProps<Props>> = ({
    accounts,
    editingBet,
    event,
    markets,
    onActionDone,
}) => {
    const filteredAccounts = useMemo(() => accounts.filter(({type}) => type !== "bank"), [accounts]);

    return isNotEmpty(filteredAccounts) ? (
        <BetSlipForm
            accounts={filteredAccounts}
            editingBet={editingBet}
            event={event}
            markets={markets}
            onActionDone={onActionDone}
        />
    ) : (
        <Card data-testid="no-accounts-card">
            <h2>Add a bet</h2>
            <p>You have no bookmaker or exchange accounts to add a new bet to. Add a new account to place bets.</p>
        </Card>
    );
};

export default BetSlip;
