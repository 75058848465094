import {AxiosError, AxiosPromise, AxiosResponse} from "axios";
import { HttpResponse } from "./HttpResponse";
import ResponseAdapter from "./ResponseAdapter";

export class RequestExecutorImpl {
    public wrapAxios<DataType>(request: AxiosPromise<DataType>): Promise<HttpResponse<DataType>> {
        return new Promise<HttpResponse<DataType>>((resolve) => {
            request
                .then((response: AxiosResponse) => {
                    resolve(ResponseAdapter.adaptResponse(response));
                })
                .catch((error: AxiosError) => {
                    resolve(ResponseAdapter.adaptError(error));
                });
        });
    }
}

export default new RequestExecutorImpl();
