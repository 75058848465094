import WithInput from "../../../input/WithInput";
import OddsService from "../../../odds/OddsService";
import { IOdds } from "../../../odds/types";

function useOddsInput(initial?: IOdds) {
    return WithInput.useInput(initial ? OddsService.asString(initial) : undefined);
}

const exports = {
    useOddsInput,
};

export default exports;
