import * as React from "react";
import LinkList from "../../../routing/components/linklist/LinkList";
import Routes from "../../../routing/Routes";
import styles from "./Links.module.scss";
import classnames from "classnames";

interface Props {
    className?: string;
}

const Links: React.FC<Props> = (props) => {
    return (
        <nav className={classnames(styles.links, props.className)}>
            <ul>
                <LinkList
                    activerouteClassName={styles.activeroute}
                    routes={Routes.getSidebarMobileRoutes()}
                />
            </ul>
        </nav>
    );
};

export default Links;
