import { IValueSubscription, IValueSubscriptionMap } from "./types";
import ValueSubscriptionFactory from "./ValueSubscriptionFactory";

export default class ValueSubscriptionMap<KeyType, ValueType> implements
    IValueSubscriptionMap<KeyType, ValueType> {

    private readonly valueSubscriptions: Map<KeyType, IValueSubscription<ValueType>> = new Map();

    constructor(private readonly getCalculateValue: (key: KeyType) => () => ValueType) {
    }

    private getValueStore(key: KeyType): IValueSubscription<ValueType> {
        let store = this.valueSubscriptions.get(key);

        if (store === undefined) {
            store = ValueSubscriptionFactory.create(this.getCalculateValue(key));
            this.valueSubscriptions.set(key, store);
        }

        return store;
    }

    public subscribe(key: KeyType, onChange: (data: ValueType) => void): () => void {
        return this.getValueStore(key).subscribe(onChange);
    }

    public getCurrentValue(key: KeyType): ValueType {
        return this.getValueStore(key).getCurrentValue();
    }

    public recalculate(key: KeyType): void {
        this.getValueStore(key).recalculate();
    }
}
