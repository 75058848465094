import { useEffect, useState } from "react";
import IHasId from "../../../id/IHasId";

function useResultInput(results: IHasId[], initialId?: string): [string, React.Dispatch<React.SetStateAction<string>>] {
    const output = useState<string>(initialId || (results[0] && results[0].id) || "");
    const [resultId, setResultId] = output;

    useEffect(() => {
        if (results.length !== 0) {
            const thePresentlySelectedResultIdIsStillInTheNewMarketsResults: boolean = results.some((availableResult) => {
                return availableResult.id === resultId;
            });

            if (!thePresentlySelectedResultIdIsStillInTheNewMarketsResults) {
                setResultId(results[0].id);
            }
        }
    }, [results, resultId, setResultId]);

    return output;
}

const exports = {
    useResultInput,
};

export default exports;
