import { Fragment } from "react";
import EmptyMessage from "../feedback/message/empty-message/EmptyMessage";
import Throw from "../logger/Throw";
import useNewKeys from "./hooks/useNewKeys";
import { IHeaderData, IRowLikeConfiguration } from "./types";

export interface AnimatedListProps<
    DataType extends object,
    HeaderDataType extends IHeaderData,
    AdditionalData extends object,
> {
    rowConfiguration: IRowLikeConfiguration<DataType, AdditionalData>;
    headerConfiguration?: IRowLikeConfiguration<HeaderDataType, AdditionalData>;
    data: DataType[];
    additional?: AdditionalData;

    getKey: (item: DataType) => string | number;
    getHeaderData?: (item: DataType) => HeaderDataType;
    emptyMessage: string;
}

function AnimatedList<DataType extends object, HeaderDataType extends IHeaderData, AdditionalData extends object = {}>(
    props: AnimatedListProps<DataType, HeaderDataType, AdditionalData>,
) {
    assertHeaderProps(props.headerConfiguration?.rowComponent, props.getHeaderData);

    return (props.data.length === 0) ?
        <EmptyMessage text={props.emptyMessage} /> :
        <AnimatedListContents {...props} />;
}

function getHeaderClassName<HeaderDataType extends IHeaderData, AdditionalData extends object = {}>(
    headerConfiguration: IRowLikeConfiguration<HeaderDataType, AdditionalData>,
    headerData: HeaderDataType,
) {
    return typeof headerConfiguration.className === "function" ?
        headerConfiguration.className(headerData) :
        headerConfiguration.className;
}

function AnimatedListContents<DataType extends object, HeaderDataType extends IHeaderData, AdditionalData extends object = {}>(
    props: AnimatedListProps<DataType, HeaderDataType, AdditionalData>,
) {
    const getHeaderData = props.getHeaderData;
    const isNewKey = useNewKeys(props.data, props.getKey);

    let previousHeaderKey: undefined | string;
    let isAlternate = true;

    return <>{
        props.data.map(item => {
            const dataKey = props.getKey(item);
            const headerData = getHeaderData ? getHeaderData(item) : undefined;
            const newHeaderKey = headerData?.key;
            const needsHeader = newHeaderKey !== previousHeaderKey;
            previousHeaderKey = newHeaderKey;

            isAlternate = needsHeader ? false : !isAlternate;

            const rowClassName = typeof props.rowConfiguration.className === "function" ?
                props.rowConfiguration.className(item) :
                props.rowConfiguration.className;

            return (
                <Fragment key={dataKey}>
                    {
                        (needsHeader && props.headerConfiguration && headerData) ? (
                            <props.headerConfiguration.wrapperComponent
                                containedComponent={props.headerConfiguration.rowComponent}
                                className={getHeaderClassName(props.headerConfiguration, headerData)}
                                data={headerData}
                                additionalData={props.additional as AdditionalData}
                                isNew={false}
                                key={newHeaderKey}
                            />
                        ) : null
                    }
                    <props.rowConfiguration.wrapperComponent
                        alternative={isAlternate}
                        containedComponent={props.rowConfiguration.rowComponent}
                        className={rowClassName}
                        data={item}
                        additionalData={props.additional as AdditionalData}
                        isNew={isNewKey(dataKey)}
                        key={dataKey}
                        onClick={props.rowConfiguration.onClick}
                    />
                </Fragment>
            );
        })
    }</>;
}

function assertHeaderProps<DataType, HeaderDataType>(
    headerComponent?: any,
    getHeaderData?: (item: DataType) => HeaderDataType,
) {
    if (headerComponent) {
        if (!getHeaderData) {
            Throw.message("missing header get key function");
        }
    } else if (getHeaderData) {
        Throw.message("missing header component");
    }
}

export default AnimatedList;
