import { IBet } from "../../../../service/types";
import { SelectedBetForEditingState } from "../../../../hooks/WithEditingBet";
import RowActions from "../../actions/RowActions";
import UseBetLifecycle from "../../../../hooks/UseBetLifecycle";
import QuickActions from "../../../../../quick-actions/components/QuickActions";

interface ActionsInteractionProps {
    bet: IBet;
    eventId: string;
    mouseEnteredRow: boolean;
    selectedBetForEditingState: SelectedBetForEditingState;
}

const ActionsInteraction = ({
    bet,
    eventId,
    mouseEnteredRow,
    selectedBetForEditingState,
}: ActionsInteractionProps) => {
    const setEditingBetId = selectedBetForEditingState[1];
    const actions = RowActions.getActions(UseBetLifecycle.useBetIsCancelling(bet.id), UseBetLifecycle.useBetIsDeleting(bet.id), bet.state);
    const onAction = RowActions.getOnAction(eventId, bet.id, setEditingBetId);

    return <QuickActions configurations={actions} onConfirm={onAction} inlineEngaged={mouseEnteredRow} />;
};

export default ActionsInteraction;
