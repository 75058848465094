import * as React from "react";
import styles from "./Spinner.module.scss";
import animationStyles from "./SpinnerAnimation.module.scss";
import classnames from "classnames";

interface Props {
    dark?: boolean;
}

const Spinner: React.FC<Props> = (props) => {
    return (
        <div
            className={classnames(
                styles.spinner,
                animationStyles.waitAndAppear,
                props.dark && styles.dark,
            )}
        />
    );
};

export default Spinner;
