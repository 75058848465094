import { ICondition, IRequiredVariable, isRequiredVaraible, IVariable } from "../types";

class Condition implements ICondition {
    readonly requiredVariables: IRequiredVariable[];

    constructor(private readonly leftVariable: IVariable, private readonly rightVariable: IVariable) {
        this.requiredVariables = [leftVariable, rightVariable].filter(isRequiredVaraible);
    }
}

export default Condition;
