import * as React from "react";
import { IAccount } from "../../../types";

interface AccountOptionProps {
    account: IAccount;
}

const AccountOption: React.FC<AccountOptionProps> = ({account}) => (
    <option value={`${account.type}-${account.name}`}>
        {account.name}
    </option>
);

export default AccountOption;
