import * as React from "react";
import styles from "./ClosePaneButton.module.scss";
import classnames from "classnames";
import Cross from "../../icons/cross-dark.svg";

interface Props {
    className?: string;
    description: string;
    onClick: (event: React.MouseEvent<HTMLImageElement>) => void;
}

const ClosePaneButton: React.FC<Props> = ({className, description, onClick, ...props}) => {
    return (
        <img
            alt={description}
            title={description}
            className={classnames(styles.close, className)}
            onClick={onClick}
            role="button"
            src={Cross}
            {...props}
        />
    );
};

export default ClosePaneButton;
