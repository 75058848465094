import { useEffect, useState } from "react";
import * as React from "react";
import { CSSTransition } from "react-transition-group";
import styles from "./ErrorMessage.module.scss";
import Multiline from "../../../components/multiline/Multiline";

interface Props {
    message?: string;
}

const ErrorMessage: React.FC<Props> = (props) => {
    const { message, ...transferredProps } = props;
    const [showIt, setShowIt] = useState(false);
    const [realMessage, setRealMessage] = useState<string>();

    useEffect(() => {
        const willShow = !!(message && (message.length > 0));

        setShowIt(willShow);
        if (willShow) {
            setRealMessage(message);
        }
    }, [message]);

    return (
        <CSSTransition
            {...transferredProps}
            data-test-real-message={message}
            in={showIt}
            timeout={300}
            classNames={{
                enter: styles.messageAnimEnter,
                enterActive: styles.messageAnimEnterActive,
                exit: styles.messageAnimExit,
                exitActive: styles.messageAnimExitActive,
                exitDone: styles.messageAnimExitDone,
                }}
        >
            <div className={styles.messageText}>
                <Multiline content={realMessage} />
            </div>
        </CSSTransition>
    );
};

export default ErrorMessage;
