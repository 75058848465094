import { ApiResponse } from "../../api/ApiResponse";
import ISubscriptionListMap from "./ISubscriptionListMap";
import SubscriptionListMap from "./SubscriptionListMap";

class SubscriptionListMapFactory {
    public create<T, ID>(
        loadData: (key: string) => () => Promise<ApiResponse<T[]>>,
        comparator?: (a: T, b: T) => number,
        getId?: (item: T) => ID,
    ): ISubscriptionListMap<T, ID> {
        return new SubscriptionListMap(loadData, comparator, getId);
    }
}

export default new SubscriptionListMapFactory();
