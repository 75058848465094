import API from "../api";
import AuthenticationService from "../authentication/AuthenticationService";

class LogoutService {
    public async logout() {
        const response = await API.post("logout")
            .catch((e) => {
                throw new Error(`Unexpected error: "${e.message}"`);
            });

        if (response.successful === false) {
            throw new Error("Could not complete at this time, please try later");
        }

        AuthenticationService.clearToken();
    }
}

export default new LogoutService();
