import HistoryProvider from "../history/HistoryProvider";

class Navigate {
    public to(path: string) {
        HistoryProvider
            .getHistory()
            .push(path);
    }
}

export default new Navigate();
