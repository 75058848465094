import * as React from "react";
import isNotEmpty from "../array/isNotEmpty";
import WithBets from "../bets/hooks/WithBets";
import { IBet } from "../bets/service/types";
import { Fetch, isDone } from "../fetch";
import { IActionComponentProps } from "../layout/action/ActionComponent";
import { IMarket } from "../markets/type";
import FetchingCard from "./internal/fallbacks/FetchingCard";
import NoBetsCard from "./internal/fallbacks/NoBetsCard";
import SettleBetForm from "./internal/SettleBetForm";

interface InnerProps {
    bets: IBet[];
    markets: IMarket[];
    sportId: string;
}

const Inner = ({bets, markets, sportId}: InnerProps) => {
    const openBets = WithBets.useOpen(bets);

    return (isNotEmpty(bets) && isNotEmpty(openBets)) ? (
        <SettleBetForm
            openBets={openBets}
            markets={markets}
            sportId={sportId}
        />) : <NoBetsCard />;
};

interface Props {
    betsFetch: Fetch<IBet[]>;
    markets: IMarket[];
    sportId: string;
}

const SettleBet: React.FC<IActionComponentProps<Props>> = ({
    betsFetch,
    ...innerProps
}) => (isDone(betsFetch)) ? <Inner bets={betsFetch.payload} {...innerProps} /> : <FetchingCard betsFetch={betsFetch} />;

export default SettleBet;
