import ISubscriptionList from "../../subscription/list/ISubscriptionList";
import { AccountLike, AccountType } from "../types";
import SubscriptionListFactory from "../../subscription/list/SubscriptionListFactory";
import LoadAccountProvider from "./load/LoadAccountProvider";
import getIdFromName from "../canon/getIdFromName";
import AccountNameComparator from "../comparators/AccountNameComparator";

class AccountsListStoreFactory {
    public create<T extends AccountLike>(accountType: AccountType): ISubscriptionList<T, string> {
        return SubscriptionListFactory.create<T, string>(
            LoadAccountProvider.getForType<T>(accountType),
            AccountNameComparator,
            getIdFromName,
        );
    }
}

export default new AccountsListStoreFactory();
