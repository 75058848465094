import * as React from "react";
import styles from "./SuccessPane.module.scss";
import BasePane from "./BasePane";
import Icon from "./icons/success.svg";
import PaneProps from "./PaneProps";

const SuccessPane: React.FC<PaneProps> = ({message}) => (
    <BasePane
        className={styles.successPane}
        data-testid="success-pane"
        icon={Icon}
        iconAltText="Success"
        message={message}
    />
);

export default SuccessPane;
