import { ApiResponse, fromUnsuccessfulResponse } from "../api/ApiResponse";
import AccountService from "../accounts/AccountService";
import CanonicalNameService from "../accounts/canon/CanonicalNameService";
import { ITransaction, SinkType, ISink } from "./types";
import { createAccountKey } from "./internal/key";
import ISubscriptionListMap from "../subscription/list/ISubscriptionListMap";
import TransactionsListCacheProvider from "./internal/TransactionsListCacheProvider";
import TransactionFactory from "./internal/TransactionFactory";
import TransferRequest from "./internal/TransferRequest";

export class TransactionServiceImpl {
    private get transactionsCache(): ISubscriptionListMap<ITransaction, unknown> {
        return TransactionsListCacheProvider.get();
    }

    public getTransactions(type: SinkType, canonicalAccountName: string): Promise<ApiResponse<ITransaction[]>> | ITransaction[] {
        return this.transactionsCache.get(createAccountKey(type, canonicalAccountName));
    }

    public subscribe(type: SinkType, canonicalAccountName: string, callback: (transactions: ITransaction[]) => void): () => void {
        return this.transactionsCache.subscribe(createAccountKey(type, canonicalAccountName), callback);
    }

    public async transferMoney(amount: number, destination: ISink, source?: ISink) {

        const response = await TransferRequest.makeTransferRequest(amount, destination, source);

        if (!response.successful) {
            return Promise.reject(fromUnsuccessfulResponse(response, "Failed to add a new transaction"));
        }

        const destinationName = destination.name;
        const destinationType = destination.type;

        if (source) {
            const sourceName = source.name;
            const sourceType = source.type;
            const normalisedSourceName = CanonicalNameService.getCanonicalName(sourceName);

            const initiatingTransaction: ITransaction = TransactionFactory.fromNewTransaction(-amount, destination, source);
            this.transactionsCache.insert(createAccountKey(sourceType, normalisedSourceName), initiatingTransaction);

            AccountService.localBalanceAdjustment({
                name: sourceName,
                type: sourceType,
            }, -amount);
        }

        const receivingTransaction: ITransaction = TransactionFactory.fromNewTransaction(amount, destination, source);
        const normalisedDestinationName = CanonicalNameService.getCanonicalName(destinationName);
        this.transactionsCache.attemptInsert(createAccountKey(destinationType, normalisedDestinationName), receivingTransaction);

        AccountService.localBalanceAdjustment({
            name: destinationName,
            type: destinationType,
        }, amount);
    }

    public adjustBalance(amount: number, destination: ISink) {
        return this.transferMoney(amount, destination);
    }
}

export default new TransactionServiceImpl();
