import * as React from "react";
import { IRouteSpec } from "../../../internal/RouteDefinitions";
import LoggedIn from "../../conditional/LoggedIn";
import NotLoggedIn from "../../conditional/NotLoggedIn";

interface Props {
    children: React.ReactNode;
    route: IRouteSpec;
}

const ConditionalWrap: React.FC<Props> = ({children, route}) => {
    const Wrapper = route.protected ? LoggedIn : NotLoggedIn;

    return (
        <Wrapper>
            {children}
        </Wrapper>
    );
};

export default ConditionalWrap;
