import { IApiOdds, IOdds } from "./types";

class OddsFactory {
    public createOdds(fromText: string): IOdds {
        return fromText.includes("/") ? {
            decimal: null,
            fractional: fromText,
        } : {
            decimal: Number(fromText),
            fractional: null,
        };
    }

    public fromApi(source: IApiOdds): IOdds {
        return {
            decimal: source.decimal === undefined ? null : source.decimal,
            fractional: source.fractional === undefined ? null : source.fractional,
        };
    }
}

export default new OddsFactory();
