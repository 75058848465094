import { useCallback } from "react";
import { IQuickActionConfiguration, QuickActionIcon, QuickActionState } from "../../types";
import Cross from "../../../icons/cross-dark.svg";
import Pencil from "../../../icons/pencil.svg";
import Bin from "../../../icons/bin.svg";
import classnames from "classnames";

import styles from "./QuickAction.module.scss";
import cardStyles from "../../../card/Card.module.scss";
import Phone from "../../../screen/Phone";

interface Props<IdType extends string> {
    configuration: IQuickActionConfiguration<IdType>;
    onClick: (id: IdType) => void;
}

function QuickAction<IdType extends string>({configuration, onClick}: Props<IdType>) {
    const onButtonClick = useCallback(() => onClick(configuration.id), [onClick, configuration.id]);
    const clickable = configuration.state === QuickActionState.ACTIVE;
    const inactive = configuration.state === QuickActionState.INACTIVE;
    const loading = configuration.state === QuickActionState.LOADING;

    return (
        <div
            className={classnames(styles.action, clickable && styles.clickable, inactive && styles.inactive, cardStyles.full,
                loading && styles.loading)}
            data-testid={`quick-action-button-${configuration.id}`}
            onClick={clickable ? onButtonClick : undefined}
            title={configuration.caption}
        >
            {loading && <div className={styles.tiny_spinner} />}
            {!loading && <img alt={configuration.caption} src={lookupIcon(configuration.icon)}/>}
            <Phone>
                {configuration.caption}
            </Phone>
        </div>
    );
}

function lookupIcon(icon: QuickActionIcon) {
    switch (icon) {
        case "cross":
            return Cross;
        case "pencil":
            return Pencil;
        default:
            return Bin;
    }
}

export default QuickAction;
