import { ApiResponse, createSuccess, fromUnsuccessfulResponse } from "../../api/ApiResponse";
import { IEvent, IApiEvent } from "../types";
import api from "../../api";
import EventAdaptor from "./EventAdaptor";

class LoadEvents {
    public async loadEvents(): Promise<ApiResponse<IEvent[]>> {
        const response = await api.get<IApiEvent[]>("/event");

        if (response.successful) {
            return createSuccess(response.data.map(apiEvent => EventAdaptor.fromApi(apiEvent)));
        }

        return fromUnsuccessfulResponse(response, "Failed to get events");
    }
}

export default new LoadEvents();
