import API from "../api";
import Logger from "../logger/Logger";
import AuthenticationService from "../authentication/AuthenticationService";

class CompleteLoginService {
    public async completeLogin(token: string): Promise<boolean> {
        try {
            const response = await API.postText<string>("verify", token);

            if (response.successful) {
                AuthenticationService.applyNewToken(response.data);
                return true;
            }
        } catch (e) {
            Logger.error(`Unexpected verify error "${e.message}"`);
        }

        return false;
    }
}

export default new CompleteLoginService();
