import RouteDefinitions, { IRouteSpec } from "./internal/RouteDefinitions";
import once from "../cache/once";

class Routes {
    public getRoutes(): IRouteSpec[] {
        return RouteDefinitions.getRoutes();
    }

    /* MASTHEAD FILTERS */
    private getUnauthenticatedMastheadRoutesForDesktop(): IRouteSpec[] {
        return RouteDefinitions.getRoutes()
            .filter(isOpen)
            .filter(isMastheadDesktop);
    }

    private getUnauthenticatedMastheadRoutesForMobile(): IRouteSpec[] {
        return RouteDefinitions.getRoutes()
            .filter(isOpen)
            .filter(isMastheadMobile);
    }

    private getProtectedMastheadRoutesForDesktop(): IRouteSpec[] {
        return RouteDefinitions.getRoutes()
            .filter(isProtected)
            .filter(isMastheadDesktop);
    }

    private getProtectedMastheadRoutesForMobile(): IRouteSpec[] {
        return RouteDefinitions.getRoutes()
            .filter(isProtected)
            .filter(isMastheadMobile);
    }

    public getMastheadDesktopRoutes = once(() => [
        ...this.getProtectedMastheadRoutesForDesktop(),
        ...this.getUnauthenticatedMastheadRoutesForDesktop(),
    ]);

    public getMastheadMobileRoutes = once(() => [
        ...this.getProtectedMastheadRoutesForMobile(),
        ...this.getUnauthenticatedMastheadRoutesForMobile(),
    ]);

    /* SIDEBAR FILTERS */
    private getUnauthenticatedSidebarRoutesForDesktop(): IRouteSpec[] {
        return RouteDefinitions.getRoutes()
            .filter(isOpen)
            .filter(isSidebarDesktop);
    }

    private getUnauthenticatedSidebarRoutesForMobile(): IRouteSpec[] {
        return RouteDefinitions.getRoutes()
            .filter(isOpen)
            .filter(isSidebarMobile);
    }

    private getProtectedSidebarRoutesForDesktop(): IRouteSpec[] {
        return RouteDefinitions.getRoutes()
            .filter(isProtected)
            .filter(isSidebarDesktop);
    }

    private getProtectedSidebarRoutesForMobile(): IRouteSpec[] {
        return RouteDefinitions.getRoutes()
            .filter(isProtected)
            .filter(isSidebarMobile);
    }

    public getSidebarMobileRoutes = once(() => [
        ...this.getProtectedSidebarRoutesForMobile(),
        ...this.getUnauthenticatedSidebarRoutesForMobile(),
    ]);

    public getSidebarDesktopRoutes = once(() => [
        ...this.getProtectedSidebarRoutesForDesktop(),
        ...this.getUnauthenticatedSidebarRoutesForDesktop(),
    ]);
}

function isOpen(route: IRouteSpec) {
    return !route.protected;
}

function isProtected(route: IRouteSpec) {
    return route.protected;
}

function isMastheadDesktop({linkLocation}: IRouteSpec) {
    return linkLocation.desktop === "masthead";
}

function isMastheadMobile({linkLocation}: IRouteSpec) {
    return linkLocation.mobile === "masthead";
}

function isSidebarDesktop({linkLocation}: IRouteSpec) {
    return linkLocation.desktop === "sidebar";
}

function isSidebarMobile({linkLocation}: IRouteSpec) {
    return linkLocation.mobile === "sidebar";
}

export default new Routes();
