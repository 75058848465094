import { AccountType, AccountLikeService } from "../types";
import BankService from "../../bank/BankService";
import BookmakerService from "../../bookmakers/BookmakerService";
import ExchangeService from "../../exchanges/ExchangeService";

class ServiceLookup {
    public get(type: AccountType): AccountLikeService<any> {
        switch(type) {
            case "bank":
                return BankService;
            case "bookmaker":
                return BookmakerService;
            case "exchange":
                return ExchangeService;
            default:
                throw new Error(`cannot get account service of type, ${type} is an unknown account type`);
        }
    }
}

export default new ServiceLookup();
