import API from "../../api";
import { IApiTransaction } from "./ApiTypes";
import { ApiResponse, fromUnsuccessfulResponse, createSuccess } from "../../api/ApiResponse";
import TransactionAdaptor from "./TransactionAdaptor";
import { ITransaction, ITransactionableAccount } from "../types";
import { keyToDetails } from "./key";

const loadTransactions = async (key: string): Promise<ApiResponse<ITransaction[]>> => {
    const account: ITransactionableAccount = keyToDetails(key);
    const { canonicalAccountName, type } = account;
    const response = await API.get<IApiTransaction[]>(`/${type}/${canonicalAccountName}/transactions`);

    if (response.successful) {
        return createSuccess(response.data
            .map((apiTransaction) => TransactionAdaptor.apiToTransaction(account, apiTransaction)),
        );
    }

    return fromUnsuccessfulResponse(response, "Failed to get transactions");
};

export default loadTransactions;
