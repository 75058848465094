import * as React from "react";
import Spinner from "./Spinner";
import animationStyles from "./SpinnerAnimation.module.scss";

interface SpinnerGuardProps {
    loading: boolean;
    loadingClassName?: string;
    message?: string;
}

const Loading: React.FC<{
    className?: string,
    message?: string,
}> = ({
    className,
    message,
}) => {
    if (message) {
        return (
            <div className={className}>
                <span className={animationStyles.waitAndAppear} data-testid="loading-message">{message}</span>
                <Spinner />
            </div>
        );
    }

    return <Spinner />;
};

const SpinnerGuard: React.FC<SpinnerGuardProps> = (props) => (
    <>
        {
            props.loading ?
                <Loading className={props.loadingClassName} message={props.message} /> :
                props.children || null
        }
    </>
);

export default SpinnerGuard;
