import * as React from "react";

import AccountScreen from "../../account/AccountScreen";
import BanksScreen from "../../bank/BanksScreen";
import CompleteLogin from "../../complete-login/CompleteLogin";
import CheckMail from "../../login/CheckMail";
import Dashboard from "../../dashboard/Dashboard";
import LoginScreen from "../../login/LoginScreen";
import SignupForm from "../../signup/SignupForm";
import BankAccountRoute from "../../bank/account/BankAccountRoute";
import BookmakersScreen from "../../bookmakers/BookmakersScreen";
import ExchangesScreen from "../../exchanges/ExchangesScreen";
import BookmakerAccountRoute from "../../bookmakers/account/BookmakerAccountRoute";
import ExchangeAccountRoute from "../../exchanges/account/ExchangeAccountRoute";
import EventsRoute from "../../events/components/EventsRoute";
import EventRoute from "../../events/components/EventRoute";

export type LinkLocationPreference = "masthead" | "nowhere" | "sidebar";
export type LinkLocationConfiguration = {
    readonly desktop: LinkLocationPreference;
    readonly mobile: LinkLocationPreference;
};

export interface IRouteSpec {
    readonly component: React.ComponentType<any>;
    readonly exact: boolean;
    readonly linkLocation: LinkLocationConfiguration;
    readonly path: string;
    readonly protected: boolean;
    readonly title?: string;
}

const NeverAnywhere = createLinkConfiguration("nowhere");
const MastheadOnDesktop = createLinkConfiguration("masthead", "sidebar");
const AlwaysSide = createLinkConfiguration("sidebar");

class RouteDefinitions {
    public getRoutes(): IRouteSpec[] {
        return [
            // Never showing a link
            createRouteSpec("/", Dashboard, NeverAnywhere, "Home", true, true),
            createRouteSpec("/check-mail", CheckMail, NeverAnywhere, "Check your email"),
            createRouteSpec("/complete-login", CompleteLogin, NeverAnywhere, "Please wait"),

            createRouteSpec("/banks/:id", BankAccountRoute, NeverAnywhere, undefined, true),
            createRouteSpec("/bookmakers/:id", BookmakerAccountRoute, NeverAnywhere, undefined, true),
            createRouteSpec("/exchanges/:id", ExchangeAccountRoute, NeverAnywhere, undefined, true),
            createRouteSpec("/events/:id", EventRoute, NeverAnywhere, undefined, true),

            // Always on side
            createRouteSpec("/events", EventsRoute, AlwaysSide, "Events", true),
            createRouteSpec("/bookmakers", BookmakersScreen, AlwaysSide, "Bookmakers", true),
            createRouteSpec("/exchanges", ExchangesScreen, AlwaysSide, "Exchanges", true),
            createRouteSpec("/banks", BanksScreen, AlwaysSide, "Banks", true),

            // Top on desktop, side on mobile
            createRouteSpec("/signup", SignupForm, MastheadOnDesktop, "Signup"),
            createRouteSpec("/login", LoginScreen, MastheadOnDesktop, "Login"),
            createRouteSpec("/account", AccountScreen, MastheadOnDesktop, "Account", true),
        ];
    }
}

function createRouteSpec(path: string, component: React.ComponentType<any>,
    linkLocation: LinkLocationConfiguration, title?: string, isProtected = false, exact = false): IRouteSpec {

    return {
        component,
        exact,
        path,
        protected: isProtected,
        linkLocation,
        title,
    };
}

function createLinkConfiguration(desktop: LinkLocationPreference, mobile: LinkLocationPreference = desktop): LinkLocationConfiguration {
    return Object.freeze({
        desktop,
        mobile,
    });
}

export default new RouteDefinitions();
