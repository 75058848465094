import ISubscriptionList from "../subscription/list/ISubscriptionList";
import { AccountLikeService, AccountType, AccountLike } from "../accounts/types";
import AccountLikeServiceBase from "../accounts/generic/AccountLikeServiceBase";
import AccountsListStoreFactory from "../accounts/generic/AccountsListStoreFactory";

export interface IBookmaker extends AccountLike {
}

export class BookmakerServiceImpl extends AccountLikeServiceBase<IBookmaker> implements AccountLikeService<IBookmaker> {
    protected readonly accountType: AccountType = "bookmaker";
    protected readonly store: ISubscriptionList<IBookmaker, string> = AccountsListStoreFactory.create("bookmaker");
}

export default new BookmakerServiceImpl();
