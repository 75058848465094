import { useState, useCallback } from "react";
import * as React from "react";
import GotoCheckMail from "../routing/components/redirects/GotoCheckMail";
import LoginService from "./LoginService";
import useInput from "../input/useInput";
import Page from "../layout/page/Page";
import Primary from "../layout/page/Primary";
import FormCard from "../form/FormCard";
import { SubmitFunction } from "../form/types";

const LoginForm: React.FC = () => {
    const [inputEmail, onInputChange] = useInput();
    const [isDone, setIsDone] = useState(false);

    const onSubmitSuccess = useCallback(() => {
        setIsDone(true);
    }, [setIsDone]);

    if (isDone) {
        return <GotoCheckMail />;
    }

    const onSubmitClick: SubmitFunction = async () => {
        await LoginService.loginUser(inputEmail);
        return "Login complete";
    };

    return (
        <Page mode="dead-center">
            <Primary>
                <FormCard
                    onSubmit={onSubmitClick}
                    legend="Login"
                    submitCaption="Login"
                    onSubmitSuccess={onSubmitSuccess}
                >
                    <label>
                        Enter your email address
                        <input
                            data-testid="email-input"
                            onChange={onInputChange}
                            placeholder="example@example.com"
                            required={true}
                            autoFocus={true}
                            type="email"
                            value={inputEmail}
                        />
                    </label>
                </FormCard>
            </Primary>
        </Page>
    );
};

export default LoginForm;
