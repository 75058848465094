class PromiseCache<T> {
    constructor(private readonly makeRequest: () => Promise<T>) {
    }

    private onGoingRequest: null | Promise<T> = null;

    private async triggerRequest(): Promise<T> {
        try {
            return await this.makeRequest();
        } finally {
            this.onGoingRequest = null;
        }
    }

    public getPromise(): Promise<T> {
        if (!this.onGoingRequest) {
            this.onGoingRequest = this.triggerRequest();
        }

        return this.onGoingRequest;
    }
}

export default PromiseCache;
