import * as React from "react";
import DumbFieldset, { Props as DumbFieldsetProps } from "./DumbFieldset";
import LoadingButton from "../../button/loading/LoadingButton";

interface Props extends DumbFieldsetProps {
    loading: boolean;
    onSubmit: (e: React.FormEvent) => void;
    submitCaption: string;
}

const DumbForm: React.FC<Props> = ({children, loading, onSubmit, submitCaption, ...dumbProps}) => {
    return (
        <form data-testid="form" onSubmit={onSubmit}>
            <DumbFieldset {...dumbProps}>
                {children}
                <LoadingButton data-testid="loading-button" disabled={dumbProps.disabled} loading={loading} type="submit">
                    {submitCaption}
                </LoadingButton>
            </DumbFieldset>
        </form>
    );
};

export default DumbForm;
