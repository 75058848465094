import { IValueSubscription } from "./types";
import ValueSubscription from "./ValueSubscription";

class ValueSubscriptionFactory {
    public create<ValueType>(calculator: () => ValueType): IValueSubscription<ValueType> {
        return new ValueSubscription(calculator);
    }
}

export default new ValueSubscriptionFactory();
