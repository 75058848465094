import { Selection } from "../../bets/service/types";
import UseFetchAndSubscribe from "../../fetch/hooks/UseFetchAndSubscribe";
import NeverChange from "../../lifecycle/NeverChange";
import MarketService from "../MarketService";
import { IMarket, ResultType } from "../type";

function useMarketsForSport(sportId: string) {
    NeverChange.useNeverChange(sportId);

    return UseFetchAndSubscribe.useFetchAndSubscribe(
        () => MarketService.getMarkets(sportId),
        (cb) => MarketService.subscribe(sportId, cb),
    );
}

const useResultsForSelections = (markets: IMarket[], selections: Selection[]): ResultType[] => {
    const addedIdentifiers = new Set<string>();

    return selections.reduce<ResultType[]>((accumulator, {marketId, resultId}) => {
        const key = `${marketId}⚷${resultId}`;
        if (!addedIdentifiers.has(key)) {
            const market = markets.find(({id}) => id === marketId);
            if (market) {
                const result = market.results.find(({id}) => id === resultId);
                if (result) {
                    addedIdentifiers.add(key);
                    return [...accumulator, result];
                }
            }
        }

        return accumulator;
    }, []);
};

const WithMarkets = {
    useMarketsForSport,
    useResultsForSelections,
};

export default WithMarkets;
