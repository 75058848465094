
const exclusions = new Set<string>([
    "/signup",
    "/login",
    "/complete-login",
    "/check-mail",
]);

class IsSidebarRequired {
    public forLocation(location: string) {
        return !exclusions.has(location.toLowerCase());
    }
}

export default new IsSidebarRequired();
