import isNumber from "../../../numbers/isNumber";
import { ApiConditionVariablePosition, isApiPartPointsObject } from "../api-types";
import { IVariable } from "../types";
import NumericVariable from "./NumericVariable";
import PartPointsVariable from "./PartPointsVariable";

const EMPTY_OBJECT = Object.freeze({});

class VariableFactory {
    public fromApi(source: ApiConditionVariablePosition): IVariable {
        if (isNumber(source)) {
            return new NumericVariable(source);
        }

        if (isApiPartPointsObject(source)) {
            return new PartPointsVariable(source.reference ? source.reference : EMPTY_OBJECT);
        }

        throw new Error(`Unknown variable type '${(source as any).variable}'`);
    }
}

export default new VariableFactory();
