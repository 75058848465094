import { HttpResponse } from "./HttpResponse";

export type ApiSuccessResponse<T> = {
    data: T;
};

export type ApiFailureResponse = {
    message: string;
};

export type ApiResponse<T> = ApiFailureResponse | ApiSuccessResponse<T>;

export function isSuccess<T>(response: ApiResponse<T>): response is ApiSuccessResponse<T> {
    return (response as ApiSuccessResponse<T>).data !== undefined;
}

export function fromUnsuccessfulResponse(response: HttpResponse, description: string): ApiFailureResponse {
    if (response.successful) {
        throw new Error("Expected to be called with a failed response");
    }

    const statusCode = response.status === undefined ? "" : `[${response.status}] `;
    const statusText = response.statusText || "Unknown error";
    const message = `${statusCode}${statusText} - ${description}`;
    const detail: string = response.data && response.data.message;

    return {
        message: detail ? message + "\n" + detail : message,
    };
}

export function createSuccess<T>(data: T): ApiSuccessResponse<T> {
    return {
        data,
    };
}
