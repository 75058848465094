import ReactHooks from "../../../react/ReactHooks";
import ICallbacks from "../ICallbacks";

function useEmitChanges<T>(callbacks: ICallbacks<T>) {
    return ReactHooks.useCallback((data: T) => callbacks.fireCallbacks(data), [callbacks]);
}

function useSubscribeToChanges<T>(callbacks: ICallbacks<T>) {
    return ReactHooks.useCallback((onChange: (data: T) => void) => callbacks.subscribe(onChange), [callbacks]);
}

const exports = {
    useEmitChanges,
    useSubscribeToChanges,
};

export default exports;
