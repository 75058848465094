import { ApiResponse } from "../../api/ApiResponse";
import SubscriptionStore from "./SubscriptionStore";
import IStore from "./IStore";

class SubscriptionStoreFactory {
    public create<T>(loadData: () => Promise<ApiResponse<T>>): IStore<T> {
        return new SubscriptionStore(loadData);
    }
}

export default new SubscriptionStoreFactory();
