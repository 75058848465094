import * as React from "react";
import Card from "../../../card/Card";
import Money from "../../../money/Money";
import styles from "./Summary.module.scss";
import { AccountLike } from "../../types";
import H1OverflowEllipsis from "../../../text/components/overflow/H1OverflowEllipsis";

interface Props {
    data: AccountLike;
}

const Summary: React.FC<Props> = (props) => {
    const account = props.data;

    return (
        <Card>
            <H1OverflowEllipsis>{account.name}</H1OverflowEllipsis>
            <div className={styles.balanceRow}>
                <span className={styles.staticText}>
                    Current balance <Money amount={account.balance} currency={account.currencyCode} />
                </span>
            </div>
        </Card>
    );
};

export default Summary;
