import { RefObject } from 'react';
import { useRef } from "react";
import Once from '../Once';
import ReactHooks from '../../react/ReactHooks';

const useIsMounted = (): RefObject<boolean> => {
    const isMountedRef = ReactHooks.useRef(false);

    Once.useEffectOnce(() => {
        isMountedRef.current = true;

        return () => {
            isMountedRef.current = false;
        };
    });

    return isMountedRef;
};

const useOnUnmount = (callback: () => void) => {
    const latestCallbackRef = useRef(callback);

    latestCallbackRef.current = callback;

    Once.useEffectOnce(() => () => latestCallbackRef.current());
};

const exports = {
    useIsMounted,
    useOnUnmount,
};

export default exports;
