import { useState } from "react";
import useTtlSet from "../../collections/useTtlSet";
import without from "../../array/without";

type Key = number | string;

function useNewKeys<T>(data: T[], getKey: (data: T) => Key) {
    const allCurrentKeys = data.map(getKey);
    const [allKnownKeys, setAllKnownKeys] = useState(allCurrentKeys);
    const enteringKeys = useTtlSet<Key>(480);

    const newKeys = without(allCurrentKeys, allKnownKeys);
    if (newKeys.length) {
        setAllKnownKeys(allCurrentKeys);
        newKeys.map(newKey => enteringKeys.add(newKey));
    }

    return enteringKeys.has;
}

export default useNewKeys;
