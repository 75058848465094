
export const EVENT_DATE_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
};

export const EVENT_TIME_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
};

export const EVENT_DATE_TIME_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = {
    ...EVENT_DATE_FORMAT_OPTIONS,
    ...EVENT_TIME_FORMAT_OPTIONS,
};
