import * as React from "react";
import AccountRow from "./AccountRow";
import ExpandingRowList from "../../../list/ExpandingRowList";
import styles from "./AccountListRow.module.scss";
import { AccountType, AccountLike, HasAccountName } from "../../types";
import UseOnAccountRowClick from "./UseOnAccountRowClick";

interface Props {
    data: AccountLike[];
    accountType: AccountType;
}

const getId = ({ name }: HasAccountName) => name;

const AccountList: React.FC<Props> = ({accountType, data}) => {
    const onRowClick = UseOnAccountRowClick.useOnAccountRowClick(accountType);

    return (
        <ExpandingRowList
            component={AccountRow}
            data={data}
            getKey={getId}
            emptyMessage={`You have no ${accountType} accounts to display.`}
            onRowClick={onRowClick}
            rowClassName={styles.row}
        />
    );
};

export default AccountList;
