import * as React from "react";
import Header from "../../../list/templates/Header";
import { IAnimatedRowPlusAdditionalProps } from "../../../list/types";
import { BetHeaderData } from "./types";
import { IAdditionalBetRowProps } from "./types";

import styles from "./BetRow.module.scss";

const HeaderComponent: React.FC<IAnimatedRowPlusAdditionalProps<BetHeaderData, IAdditionalBetRowProps>> = ({data}) => {
    return (
        <Header className={styles.fill_available}>
            <span>{data.marketName}</span>
        </Header>
    );
};

export default HeaderComponent;
