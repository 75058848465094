import Card from "../../../card/Card";

const NoBetsCard = () => (
    <Card>
        <h2>Settle Event</h2>
        <p>There are no bets for this event that can be settled.</p>
    </Card>
);

export default NoBetsCard;
