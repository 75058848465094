import { IAccount } from "../types";

type GroupedByType = {
    banks: IAccount[];
    bookmakers: IAccount[];
    exchanges: IAccount[];
};

const sortByName = (a: IAccount, b: IAccount): number => {
    return a.name.localeCompare(b.name);
};

const groupByType = (accounts: IAccount[]): GroupedByType => {
    const banks: IAccount[] = [];
    const bookmakers: IAccount[] = [];
    const exchanges: IAccount[] = [];

    for (const account of accounts) {
        switch(account.type) {
            case "bank":
                banks.push(account);
                break;
            case "bookmaker":
                bookmakers.push(account);
                break;
            case "exchange":
                exchanges.push(account);
                break;
        }
    }

    return {
        banks: banks.sort(sortByName),
        bookmakers: bookmakers.sort(sortByName),
        exchanges: exchanges.sort(sortByName),
    };
};

export default groupByType;
