import * as React from "react";

import { Route, Switch } from "react-router-dom";
import ProtectedRoute from "./conditional/ProtectedRoute";
import Routes from "../Routes";

const RouteSwitcher: React.FC = () => {
    const routes = Routes.getRoutes();

    return (
        <Switch>
            {routes.map((route, n) => (
                route.protected ? (
                    <ProtectedRoute
                        key={n}
                        exact={route.exact}
                        path={route.path}
                        component={route.component}
                    />
                ) : (
                    <Route
                        key={n}
                        exact={route.exact}
                        path={route.path}
                        component={route.component}
                    />
                )
            ))}
        </Switch>
    );
};

export default RouteSwitcher;
