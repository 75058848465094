import API from "../api";
import RegisterNewUserError from "./RegisterNewUserErrors";

interface SignupBody {
    email: string;
}

const createBody = (email: string): SignupBody => ({ email });

class SignupService {
    public async registerNewUser(emailAddress: string): Promise<void> {
        const response = await this.makeRegisterRequest(emailAddress);
        if (response.successful === false) {
            RegisterNewUserError.throwHumanReadableError(response.status);
        }
    }
    private makeRegisterRequest(emailAddress: string) {
        return API.putJson("user", createBody(emailAddress))
            .catch((e) => {
                throw new Error(`Unexpected error: "${e.message}"`);
            });
    }
}

export default new SignupService();
