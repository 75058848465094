import AccountService from "../../../accounts/AccountService";
import CanonicalNameService from "../../../accounts/canon/CanonicalNameService";
import DateFactory from "../../../date/DateFactory";
import OddsFactory from "../../../odds/OddsFactory";
import { EditBetApi, IBet, IApiBet } from "../types";
import ApiMapper from "./ApiMapper";

class BetFactory {
    public fromApi(source: IApiBet): IBet {

        return {
            // Required
            id: source.id,
            placedAt: DateFactory.createDate(source.placedAt),
            odds: OddsFactory.fromApi(source.odds),
            selection: source.selection,
            state: ApiMapper.fromApiBetState(source.state),
            type: source.type,

            // Maybe present
            account: source.account ? {
                canonicalAccountName: source.account.canonicalName,
                type: AccountService.fromApiAccountOrdinal(source.account.accountType),
            } : undefined,
            label: source.label,
            settleType: source.settleType ? ApiMapper.fromApiSettleType(source.settleType) : undefined,
            stake: source.stake,
        };
    }

    public fromEdit({id, placedAt, settleType, state}: IBet, {accountName, accountType, ...edit}: EditBetApi): IBet {
        return {
            // Properties that are never allowed to be edited
            id,
            placedAt,
            settleType,
            state,

            // The directly transferable edited props
            ...edit,

            // The requires adaption edited props
            // Maybe present
            account: (accountName && accountType) ? {
                canonicalAccountName: CanonicalNameService.getCanonicalName(accountName),
                type: AccountService.fromApiAccountOrdinal(accountType),
            } : undefined,

        };
    }
}

export default new BetFactory();
