import * as React from "react";
import Money from "../../../money/Money";
import styles from "./AccountListRow.module.scss";
import SpanOverflowEllipsis from "../../../text/components/overflow/SpanOverflowEllipsis";
import { AccountLike } from "../../types";
import { IAnimatedRowProps } from "../../../list/types";

const AccountRow: React.FC<IAnimatedRowProps<AccountLike>> = ({data}) => {
    return (
        <>
            <SpanOverflowEllipsis className={styles.name}>{data.name}</SpanOverflowEllipsis>
            <div className={styles.balance}><Money amount={data.balance} currency={data.currencyCode} /></div>
        </>
    );
};

export default AccountRow;
