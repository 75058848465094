import PersistenceService from "../persistence/PersistenceService";

const SESSION_TOKEN_KEY = "session-token";

class AuthenticationService {
    private authenticationCallbacks: Set<() => void> = new Set();

    public applyNewToken(token: string): void {
        PersistenceService.setItem(SESSION_TOKEN_KEY, token);
        this.triggerCallbacks();
    }

    public isLoggedIn(): boolean {
        return this.getToken() !== null;
    }

    public clearToken() {
        PersistenceService.removeItem(SESSION_TOKEN_KEY);
        this.triggerCallbacks();
    }

    public getToken(): string | null {
        return PersistenceService.getItem(SESSION_TOKEN_KEY);
    }

    public registerAuthenticationListener(onAuthenticationChanged: () => void): () => void {
        this.authenticationCallbacks.add(onAuthenticationChanged);

        return () => {
            this.authenticationCallbacks.delete(onAuthenticationChanged);
        };
    }

    private triggerCallbacks() {
        this.authenticationCallbacks.forEach((callback) => {
            callback();
        });
    }
}

export default new AuthenticationService();
