import * as React from "react";
import FetchGuard from "../../fetch/FetchGuard";
import EventsPage from "./EventsPage";
import { ISport } from "../../sports/Sport";

import contentStyles from "../../layout/styles/content.module.scss";
import UseEventsAndSports from "./internal/UseEventsAndSports";
import { IEvent } from "../types";

interface HasDataProps {
    data: [IEvent[], ISport[]];
}

const MapData: React.FC<HasDataProps> = ({data}) => <EventsPage events={data[0]} sports={data[1]} />;

const EventsRoute: React.FC = () => {
    const eventsAndSportsFetch = UseEventsAndSports.useEventsAndSports();

    return (
        <FetchGuard
            component={MapData}
            fetch={eventsAndSportsFetch}
            loadingMessage="Loading events, please wait…"
            loadingClassName={contentStyles.standardPadding}
        />
    );
};

export default EventsRoute;
