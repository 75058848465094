import UseFetchRequest from "./UseFetchRequest";
import { ApiResponse } from "../../api/ApiResponse";
import UseSubscription from "./UseSubscription";

function useFetchAndSubscribe<T>(
    makeFetchRequest: () => Promise<ApiResponse<T>> | T,
    subscribe: (cb: (accounts: T) => void) => () => void,
) {
    const [fetch, setFetch] = UseFetchRequest.useFetchRequest(makeFetchRequest);

    UseSubscription.useSubscription(subscribe, setFetch);

    return fetch;
}

const exports = {
    useFetchAndSubscribe,
};

export default exports;
