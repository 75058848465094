import { useState } from "react";
import Once from "../../lifecycle/Once";
import { SubmitFunction } from "../types";
import ErrorAdaptor from "./ErrorAdaptor";

type SubmitEventFunction = (e: React.FormEvent | null) => void;
type InProgress = boolean;
type MaybeErrorMessage = undefined | string;
type MaybeSuccessMessage = undefined | string;
type ClearMessages = () => void;

type SubmitHookOutput = [
    SubmitEventFunction,
    InProgress,
    MaybeSuccessMessage,
    MaybeErrorMessage,
    ClearMessages,
];

function useSubmit(submitFunction: SubmitFunction, onSubmitSuccess?: () => void): SubmitHookOutput {
    const [inProgress, setInProgress] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [successMessage, setSuccessMessage] = useState<string>();

    const clearMessages = Once.useCallbackOnce(() => {
        setErrorMessage(undefined);
        setSuccessMessage(undefined);
    });

    const onSubmit: SubmitEventFunction = async (e: React.FormEvent | null) => {
        if (e) {
            e.preventDefault();
        }

        setInProgress(true);
        setSuccessMessage(undefined);
        setErrorMessage(undefined);

        try {
            setSuccessMessage(await submitFunction());
            if (onSubmitSuccess) {
                onSubmitSuccess();
            }
        } catch (error) {
            setErrorMessage(ErrorAdaptor.adaptError(error));
        }

        setInProgress(false);
    };

    return [
        onSubmit,
        inProgress,
        successMessage,
        errorMessage,
        clearMessages,
    ];
}

const exports = {
    useSubmit,
};

export default exports;
