import { IUsesOdds } from "./types";

interface Props extends IUsesOdds {
    className?: string;
}

const OddsEntry = ({className, odds}: Props) => (
    <label className={className}>
        Odds
        <input
            type="text"
            data-testid="betslip-odds-input"
            onChange={odds[1]}
            required={true}
            value={odds[0]}
        />
    </label>
);

export default OddsEntry;
