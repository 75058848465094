import Navigate from "../../../routing/Navigate";
import CanonicalNameService from "../../canon/CanonicalNameService";
import { AccountType, HasAccountName } from "../../types";

function useOnAccountRowClick(type: AccountType): (account: HasAccountName) => void {
    return ({name}: HasAccountName) => {
        Navigate.to(`/${type}s/${CanonicalNameService.getCanonicalName(name)}`);
    };
}

const exports = {
    useOnAccountRowClick,
};

export default exports;
