import Actions from "../../../../layout/action/Actions";
import Secondary from "../../../../layout/page/Secondary";
import BetSlipAction, { BetSlipActionProps } from "./BetSlipAction";
import SettleAction, { SettleActionProps } from "./SettleAction";

export interface SecondaryPageProps extends BetSlipActionProps, Omit<SettleActionProps, "sportId"> {
}

const SecondaryPage = ({
    accounts,
    betsFetch,
    clearEditingBetSelection,
    editingBet,
    event,
    markets,
}: SecondaryPageProps) => (
    <Secondary>
        <Actions>
            <BetSlipAction
                accounts={accounts}
                clearEditingBetSelection={clearEditingBetSelection}
                editingBet={editingBet}
                event={event}
                markets={markets}
            />
            <SettleAction
                sportId={event.sportId}
                betsFetch={betsFetch}
                markets={markets}
            />
        </Actions>
    </Secondary>
);

export default SecondaryPage;
