export interface IVariable {
}

export type VariableName = "part-points";
export type SubVariableName = "part-number" | "team-id";

export interface IRequiredVariable extends IVariable {
    readonly variableName: VariableName;
    readonly references: Map<SubVariableName, number>;
}

export interface IPartPointsRequiredVariable extends IRequiredVariable {
    readonly variableName: "part-points";
}

export function isPartPointsRequiredVariable(requiredVariable: IRequiredVariable): requiredVariable is IPartPointsRequiredVariable {
    return requiredVariable.variableName === "part-points";
}

export interface ICondition {
    requiredVariables: IRequiredVariable[];
}

export type Criterion = [ICondition, ...ICondition[]];

export type Criteria = [Criterion, ...Criterion[]];

export function isRequiredVaraible(variable: IVariable): variable is IRequiredVariable {
    const asRequired = variable as IRequiredVariable;

    return !!(asRequired.variableName && asRequired.references);
}
