import MoneyInput from "../../../../money/form/MoneyInput";
import { IUsesStake } from "./types";

interface Props extends IUsesStake {
    className?: string;
}

const StakeEntry = ({className, stake}: Props) => (
    <label className={className}>
        Stake
        <MoneyInput
            data-testid="betslip-stake-input"
            onChange={stake[1]}
            required={true}
            value={stake[0]}
        />
    </label>
);

export default StakeEntry;
