import Once from "../../../lifecycle/Once";
import WindowProvider from "../../WindowProvider";

function useRemoveAllRangesOnMount() {
    Once.useEffectOnce(() => {
        WindowProvider.getWindow().getSelection()?.removeAllRanges();
    });
}

const exports = {
    useRemoveAllRangesOnMount,
};

export default exports;
