import * as React from "react";
import Header from "../../../list/templates/Header";
import { IAnimatedRowProps } from "../../../list/types";
import { EventHeaderRowData } from "./types";

const HeaderRow: React.FC<IAnimatedRowProps<EventHeaderRowData>> = ({data}) => {
    return <Header><span>{data.formattedDate}</span></Header>;
};

export default HeaderRow;
