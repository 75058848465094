
class RegisterNewUserErrors {
    public throwHumanReadableError(status?: number) {
        switch (status) {
            case 400:
                throw new Error("Not a valid email address");
            case 409:
                throw new Error("This email address is already registered");
            case 500:
                throw new Error("Server error, try again shortly");
            default:
                throw new Error("Unknown error, try again shortly");
        }
    }
}

export default new RegisterNewUserErrors();
