import * as React from "react";
import MessageBox from "../message-box/MessageBox";
import { IConfirmPrompt } from "./types";

interface Props {
    showConfig?: IConfirmPrompt;
    onNo: () => void;
    onYes: () => void;
}

const ConfirmBox: React.FC<Props> = ({showConfig, onNo, onYes}) => {
    if (showConfig === undefined) {
        return null;
    }

    return (
        <MessageBox
            isOpen={true}
            title={showConfig.title}
            description={showConfig.body}
            noCaption={showConfig.cancelCaption}
            yesCaption={showConfig.confirmCaption}
            onNo={onNo}
            onYes={onYes}
        />
    );
};

export default ConfirmBox;
