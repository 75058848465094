import { ApiResponse, createSuccess, ApiSuccessResponse } from "../api/ApiResponse";
import BankService from "../bank/BankService";
import BookmakerService from "../bookmakers/BookmakerService";
import ExchangeService from "../exchanges/ExchangeService";
import PromiseCache from "../cache/promise/PromiseCache";
import all from "../fetch/all";
import { isReady, ReadyOrNot} from "../fetch";
import { AccountType, AccountLike, IAccount, IdentifiableAccount, AddAccount, ApiAccountOrdinal } from "./types";
import ServiceLookup from "./internal/ServiceLookup";
import { WagerableAccount } from "../bets/service/types";
import findOrThrow from "../array/findOrThrow";
import CanonicalNameService from "./canon/CanonicalNameService";

const ACCOUNT_TYPES = new Set<AccountType>(["bank", "exchange", "bookmaker"]);

export function isAccountType(maybeAccountType?: string): maybeAccountType is AccountType {
    return ACCOUNT_TYPES.has(maybeAccountType as AccountType);
}

export class AccountServiceImpl {
    private static buildOutputArray(accounts: AccountLike[][]): IAccount[] {
        const banks = accounts[0]
            .map(bank => createAccount("bank", bank));
        const bookmakers = accounts[1]
            .map(bookmaker => createAccount("bookmaker", bookmaker));
        const exchanges = accounts[2]
            .map(exchange => createAccount("exchange", exchange));

        return [
            ...banks,
            ...bookmakers,
            ...exchanges,
        ];
    }

    private gatherAccountsRequest: PromiseCache<ApiResponse<IAccount[]>> | null = null;

    public getAccounts(): ReadyOrNot<IAccount[]> {
        const readyOrNot = all([
            BankService.getAccounts(),
            BookmakerService.getAccounts(),
            ExchangeService.getAccounts(),
        ]) as AccountLike[][] | Promise<ApiSuccessResponse<AccountLike[][]>>;

        if (isReady(readyOrNot)) {
            return AccountServiceImpl.buildOutputArray(readyOrNot);
        }

        if (!this.gatherAccountsRequest) {
            this.gatherAccountsRequest = new PromiseCache<ApiResponse<IAccount[]>>(async () => {
                try {
                    return createSuccess(AccountServiceImpl.buildOutputArray((await readyOrNot).data));
                } catch (e) {
                    return {
                        message: "Failed to load accounts. " + e.message,
                    };
                }
            });
        }

        return this.gatherAccountsRequest.getPromise();
    }

    public createKey(account: IdentifiableAccount): string {
        return `${account.type}-${account.name}`;
    }

    public fromKey(key: string): IdentifiableAccount {
        const parts: string[] = key.split("-");

        if (parts.length < 2) {
            throw new Error("Invalid key - missing separator");
        }

        const type = parts.shift();
        if (isAccountType(type)) {
            return {
                type,
                name: parts.join("-"),
            };
        }

        throw new Error(`Invalid key - unknown type: ${type}`);
    }

    public localBalanceAdjustment(account: IdentifiableAccount, offsetAmount: number): void {
        ServiceLookup.get(account.type).localBalanceAdjustment(account.name, offsetAmount);
    }

    public excludeFromList(accounts: IAccount[], exclude: IdentifiableAccount): IAccount[] {
        return accounts.filter((account: IAccount) => !(exclude.name === account.name && exclude.type === account.type));
    }

    public getAccountsOfType(type: AccountType): Promise<ApiResponse<AccountLike[]>> | AccountLike[] {
        return ServiceLookup.get(type).getAccounts();
    }

    public subscribeToType(type: AccountType, onChange: (accounts: AccountLike[]) => void): () => void {
        return ServiceLookup.get(type).subscribe(onChange);
    }

    public addAccount(type: AccountType, addAccount: AddAccount) {
        return ServiceLookup.get(type).addAccount(addAccount);
    }

    public toApiAccountOrdinal(type: AccountType): ApiAccountOrdinal {
        if (type === "exchange") {
            return 2;
        }

        return type === "bookmaker" ? 1 : 0;
    }

    public fromApiAccountOrdinal(ordinal: ApiAccountOrdinal): AccountType {
        if (ordinal === 1)
            return "bookmaker";

        return (ordinal === 2) ? "exchange" : "bank";
    }

    public getAccountForWagerableAccount(accounts: IAccount[], toFind: WagerableAccount | undefined): IAccount {
        if (toFind === undefined) {
            throw new Error("Can't find account, find criteria is missing");
        }

        return findOrThrow(accounts, (account) => {
            return account.type === toFind.type && toFind.canonicalAccountName === CanonicalNameService.getCanonicalName(account.name);
        }, `no matching account of type ${toFind.type} and id ${toFind.canonicalAccountName}`);
    }
}

function createAccount(type: AccountType, account: AccountLike): IAccount {
    return {
        type,
        ...account,
    };
}

export default new AccountServiceImpl();
