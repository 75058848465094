import { useCallback } from "react";
import * as React from "react";
import { IActionComponentProps } from "./ActionComponent";
import ActionLayout from "./internal/ActionLayout";
import UseOverlayControl from "../../overlay/hooks/UseOverlayControl";
import UseObserver, { Subscriber } from "../../subscription/observer/UseObserver";

type PassthroughProps<T> = T & {
    caption: string;
    component: React.ComponentType<IActionComponentProps<T>>;
};

type InternalProps = {
    onFinished?: () => void;
    forceOpenEvents?: Subscriber<void>;
};

type Props<T> = PassthroughProps<T> & InternalProps;

function Action<T>({forceOpenEvents, onFinished, ...props}: Props<T>) {
    const [isActive, becomeActive, becomeInactive] = UseOverlayControl.hooks.useAutoCloseWhenNotPhoneOverlayControl();

    UseObserver.useOnObserverChange(forceOpenEvents, becomeActive);

    const onClose = useCallback(() => {
        becomeInactive();
        if (onFinished) {
            onFinished();
        }
    }, [onFinished, becomeInactive]);

    return (
        <ActionLayout
            isOpen={isActive}
            onClose={onClose}
            onOpen={becomeActive}
            {...props as PassthroughProps<T>}
        />
   );
}

export default Action;
