import { IBet } from "../../../bets/service/types";
import Card from "../../../card/Card";
import { Fetch } from "../../../fetch";
import FetchGuard from "../../../fetch/FetchGuard";

interface Props {
    betsFetch: Fetch<IBet[]>;
}

const FetchingCard = ({
    betsFetch,
}: Props) => {
    return (
        <Card>
            <FetchGuard fetch={betsFetch} loadingMessage="Loading bets…" />
        </Card>
    );
};

export default FetchingCard;
