import UseScreenSize from "../../../screen/hooks/UseScreenSize";

function useRequiresOverlay(): boolean {
    return UseScreenSize.useScreenSize() === "phone";
}

const exports = {
    useRequiresOverlay,
};

export default exports;
