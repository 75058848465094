import * as React from "react";
import styles from "./SideBar.module.scss";
import classnames from "classnames";
import LinkList from "../routing/components/linklist/LinkList";
import Routes from "../routing/Routes";

interface Props {
    className?: string;
}

const SideBar: React.FC<Props> = (props) => {
    return (
        <nav className={classnames(styles.sidebar, props.className)}>
            <ul>
                <LinkList routes={Routes.getSidebarDesktopRoutes()} activerouteClassName={styles.activeroute} />
            </ul>
        </nav>
    );
};

export default SideBar;
