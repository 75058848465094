export type ApiVariableType = "part-points";

export type ApiConditionVariable_PartPoints = {
    variable: ApiVariableType,
    reference: null | {
        "part-number"?: number,
        "team-id"?: number,
    },
};

export type ApiConditionVariableObject = ApiConditionVariable_PartPoints;
export type ApiConditionVariablePosition = number | ApiConditionVariableObject;
export type ApiConditionOperationPosition = ">" | "=" | "<";

export type ApiCondition = [ApiConditionVariablePosition, ApiConditionOperationPosition, ApiConditionVariablePosition];

export type ApiCriterion = [ApiCondition, ...ApiCondition[]];
export type ApiCriteria = ApiCriterion[];

export function isApiPartPointsObject(maybe: ApiConditionVariableObject): maybe is ApiConditionVariable_PartPoints {
    return maybe.variable === "part-points";
}
