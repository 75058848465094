import { isSuccess, ApiResponse } from "../api/ApiResponse";

type FetchDone<T> = {
    state: "done";
    payload: T;
};

export type FetchError = {
    state: "error";
    message: string;
};

type FetchLoading = {
    state: "loading";
};

export type Fetch<T> = FetchDone<T> | FetchError | FetchLoading;

export function isLoading<T>(fetch: Fetch<T>): fetch is FetchLoading {
    return fetch.state === "loading";
}

export function isError<T>(fetch: Fetch<T>): fetch is FetchError {
    return fetch.state === "error";
}

export function isDone<T>(fetch: Fetch<T>): fetch is FetchDone<T> {
    return fetch.state === "done";
}

export function assertIsDone<T>(maybeDone: Fetch<T>): asserts maybeDone is FetchDone<T> {
    if (!isDone(maybeDone)) {
        throw new Error("Condition failed: Asserting fetch has 'done' status");
    }
}

const PermenantLoading: Fetch<unknown> = Object.freeze({
    state: "loading",
});

export function createLoadingFetch<T>(): Fetch<T> {
    return PermenantLoading as Fetch<T>;
}

export function createDoneFetch<T>(payload: T): Fetch<T> {
    const ret: Fetch<T> = {
        state: "done",
        payload,
    };

    return ret;
}

export function createErrorFetch<T>(message: string): Fetch<T> {
    const ret: Fetch<T> = {
        state: "error",
        message,
    };

    return ret;
}

export function fromResponse<T>(response: ApiResponse<T>) {
    return isSuccess(response) ? createDoneFetch(response.data) : createErrorFetch<T>(response.message);
}

export type ReadyOrNot<T> = T | Promise<ApiResponse<T>>;

export function isReady<T>(readyOrNot: ReadyOrNot<T>): readyOrNot is T {
    return !(readyOrNot instanceof Promise);
}