import * as React from "react";
import classnames from "classnames";
import styles from "./MoneyInput.module.scss";

type TypelessInputProps = Omit<React.ComponentPropsWithoutRef<'input'>, "type">;

interface MoneyInputProps extends TypelessInputProps {

}

const MoneyInput: React.FC<MoneyInputProps> = ({className, ...props}) => {

    return (
        <input
            {...props}
            className={classnames(
                className,
                styles.moneyInput,
            )}
            type="number"
        />
    );
};

export default MoneyInput;
