import { useState } from "react";
import WindowResizeObserverFactory from "../../window/WindowResizeObserverFactory";
import WindowProvider from "../../window/WindowProvider";
import Once from "../../lifecycle/Once";

const getWindowWidth = () => WindowProvider.getWindow().innerWidth;

function useWidth() {
    const [windowWidth, setWindowWidth] = useState(getWindowWidth());

    Once.useEffectOnce(() => {
        return WindowResizeObserverFactory.createListener(() => {
            setWindowWidth(getWindowWidth);
        });
    });

    return windowWidth;
}

const exports = {
    useWidth,
};

export default exports;
