import * as React from "react";
import Logout from "../logout/Logout";
import Card from "../card/Card";
import Page from "../layout/page/Page";
import Primary from "../layout/page/Primary";
import styles from "./AccountScreen.module.scss";

const AccountScreen: React.FC = () => {
    return (
        <Page>
            <Primary>
                <Card>
                    <h1>Account</h1>
                    <div className={styles.logout}>
                        <Logout />
                    </div>
                </Card>
            </Primary>
        </Page>
    );
};

export default AccountScreen;
