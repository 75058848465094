import { IAddEvent, IEvent } from "../types";
import CanonicalNameService from "../../accounts/canon/CanonicalNameService";

class EventFactory {
    public fromAddEvent({name, sportId, when}: IAddEvent): IEvent {
        const dateComponent = when.toISOString()
            .slice(0, 10);
        const nameComponent = CanonicalNameService.getCanonicalName(name);

        const id = `${dateComponent}-${sportId}-${nameComponent}`;

        return {
            id,
            name,
            sportId,
            when,
        };
    }
}

export default new EventFactory();
