import * as React from "react";
import DateFormatter from "../../../date/DateFormatter";
import { IAnimatedRowPlusAdditionalProps } from "../../../list/types";
import { IEvent } from "../../types";
import { EVENT_TIME_FORMAT_OPTIONS } from "../constants";

import TimeIcon from "../../../icons/time.svg";
import TimeIconLight from "../../../icons/time-light.svg";

import styles from "./EventRow.module.scss";
import { IEventListAdditionalData } from "./types";
import SportsService from "../../../sports/SportsService";

const EventRow: React.FC<IAnimatedRowPlusAdditionalProps<IEvent, IEventListAdditionalData>> = ({
    additional,
    data,
    mouseEntered,
}) => {
    const whenFormatted = DateFormatter.toLocaleString(data.when, EVENT_TIME_FORMAT_OPTIONS);
    const sportName = SportsService.getDisplayableName(data.sportId, additional.sports);

    return (
        <>
            <div data-testid="row-event-name" className={styles.name}>
                {data.name}<em data-testid="row-sport-name">({sportName})</em>
            </div>
            <div data-testid="row-event-when" className={styles.time}>
                <img
                    alt=""
                    className={styles.icon}
                    src={mouseEntered ? TimeIconLight : TimeIcon}
                />
                {whenFormatted}
            </div>
        </>
    );
};

export default EventRow;
