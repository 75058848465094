import { AxiosRequestConfig} from "axios";

export default function createConfig(params?: object, headers: object = {}): AxiosRequestConfig {
    const config: AxiosRequestConfig = {
        headers,
    };

    if (params) {
        config.params = params;
    }

    return config;
}

export const BLANK_CONFIG = Object.freeze(createConfig());
