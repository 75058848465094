import { IApiEvent, IEvent } from "../types";
import DateFactory from "../../date/DateFactory";

class EventAdaptor {
    public fromApi(apiEvent: IApiEvent): IEvent {
        return {
            id: apiEvent.id,
            name: apiEvent.name,
            sportId: `${apiEvent.sport}`,
            when: DateFactory.createDate(apiEvent.when),
        };
    }
}

export default new EventAdaptor();
