import * as React from "react";
import ErrorPane from "../../feedback/pane/ErrorPane";

interface ErrorProps {
    component?: React.ComponentType<{message: string}>;
    message: string;
}

const ErrorDisplay: React.FC<ErrorProps> = (props: ErrorProps) => {
    return props.component ?
        <props.component message={props.message} /> :
        <ErrorPane message={props.message} />;
};

export default ErrorDisplay;
