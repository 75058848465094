import ISubscriptionList from "../../subscription/list/ISubscriptionList";
import { IEvent } from "../types";
import SubscriptionListFactory from "../../subscription/list/SubscriptionListFactory";
import eventComparator from "./eventComparator";
import getEventId from "./getEventId";
import LoadEvents from "./LoadEvents";

class EventListStoreFactory {
    public create(): ISubscriptionList<IEvent, string> {
        return SubscriptionListFactory.create<IEvent, string>(
            () => LoadEvents.loadEvents(),
            eventComparator,
            getEventId,
        );
    }
}

export default new EventListStoreFactory();
