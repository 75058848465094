import { BetSlipData } from "../BetSlipStore";
import { IMarket } from "../../../markets/type";
import { IAccount } from "../../../accounts/types";

import AccountEntry from "./labelled-inputs/AccountEntry";
import BetTypeEntry from "./labelled-inputs/BetTypeEntry";
import MarketEntry from "./labelled-inputs/MarketEntry";
import ResultEntry from "./labelled-inputs/ResultEntry";
import StakeEntry from "./labelled-inputs/StakeEntry";
import OddsEntry from "./labelled-inputs/OddsEntry";

import styles from "./FormWidgets.module.scss";

interface Props {
    markets: IMarket[];
    accounts: [IAccount, ...IAccount[]];
    betSlipData: BetSlipData;
}

const FormWidgets = ({
    accounts,
    betSlipData,
    markets,
}: Props) => {
    const {
        account,
        betType,
        currentMarket,
        marketId,
        odds,
        result,
        stake,
    } = betSlipData;

    return (
        <>
            <AccountEntry account={account} accounts={accounts} />
            <BetTypeEntry betType={betType} />
            <MarketEntry marketId={marketId} markets={markets} />
            <ResultEntry currentMarket={currentMarket} result={result} />

            <div className={styles.fiftyFiftyRow}>
                <StakeEntry className={styles.left} stake={stake} />
                <OddsEntry className={styles.right} odds={odds} />
            </div>
        </>
    );
};

export default FormWidgets;
