import * as React from "react";
import FetchGuard from "../../../fetch/FetchGuard";
import AccountScreen, { AccountScreenAdditionalData } from "../detail/AccountScreen";
import { Fetch } from "../../../fetch";
import { ITransaction } from "../../../transactions/types";
import UseTransactions from "../../../transactions/hooks/useTransactions";
import useAccounts from "../../hooks/useAccounts";
import RouteParams from "../../../routing/hooks/RouteParams";
import { AccountType } from "../../types";
import UseAccountOfType from "../../hooks/useAccountOfType";

import contentStyles from "../../../layout/styles/content.module.scss";

interface Props {
    accountType: AccountType;
    errorComponent?: React.ComponentType<{message: string}>;
}

const AccountRoute: React.FC<Props> = ({accountType, errorComponent}) => {
    const id = RouteParams.useSafeParam("id");
    const accountsFetch = useAccounts();
    const accountFetch = UseAccountOfType.useAccountOfType(accountType, id);
    const transactionsFetch: Fetch<ITransaction[]> = UseTransactions.useTransactions(accountType, id);

    const additionalData: AccountScreenAdditionalData = {
        accountsFetch,
        accountType,
        transactionsFetch,
    };

    return (
        <FetchGuard
            additionalData={additionalData}
            component={AccountScreen}
            errorComponent={errorComponent}
            fetch={accountFetch}
            loadingMessage={`Loading ${accountType} account, please wait…`}
            loadingClassName={contentStyles.standardPadding}
        />
    );
};

export default AccountRoute;
