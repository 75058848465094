import ISubscriptionListMap from "../../../subscription/list/ISubscriptionListMap";
import SubscriptionListMapFactory from "../../../subscription/list/SubscriptionListMapFactory";
import loadBets from "./loadBets";
import { IBet } from "../types";

export class BetsListCacheProviderImpl {
    private cached?: ISubscriptionListMap<IBet, string>;

    public get(): ISubscriptionListMap<IBet, string> {
        if (!this.cached) {
            this.cached = SubscriptionListMapFactory.create(
                (key: string) => () => loadBets(key),
                undefined,
                (bet: IBet) => bet.id,
            );
        }

        return this.cached;
    }
}

export default new BetsListCacheProviderImpl();
