import * as React from "react";
import PaneProps from "./PaneProps";
import ErrorPane from "./ErrorPane";
import SuccessPane from "./SuccessPane";
import Logger from "../../logger/Logger";

type MessageType = "error" | "success";

interface MessagePaneProps extends PaneProps {
    type: MessageType;
}

const MessagePane: React.FC<MessagePaneProps> = ({type, message}) => {
    switch (type) {
        case "error":
            return <ErrorPane message={message} />;
        case "success":
            return <SuccessPane message={message} />;
        default: {
            Logger.error(`Pane was called with unknown type '${type}'`);
            return null;
        }
    }
};

export default MessagePane;
