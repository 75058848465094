import UseScreenSize from "./UseScreenSize";

function usePhone(): boolean {
    return UseScreenSize.useScreenSize() === "phone";
}

const exports = {
    usePhone,
};

export default exports;
