import * as React from "react";
import Card from "../card/Card";

const Dashboard: React.FC = () => (
    <div>
        <Card>
            <h1>Hello, World!</h1>
        </Card>
    </div>
);

export default Dashboard;
