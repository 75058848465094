import { IPartPointsRequiredVariable, SubVariableName, VariableName } from "../types";

interface IConstructorArgs {
    "part-number"?: number;
    "team-id"?: number;
}

class PartPointsVariable implements IPartPointsRequiredVariable {
    readonly variableName: VariableName = "part-points";
    readonly references: Map<SubVariableName, number> = new Map();

    constructor(subVariables: IConstructorArgs = {}) {
        if (subVariables["part-number"] !== undefined) {
            this.references.set("part-number", subVariables["part-number"]);
        }

        if (subVariables["team-id"] !== undefined) {
            this.references.set("team-id", subVariables["team-id"]);
        }
    }
}

export default PartPointsVariable;
