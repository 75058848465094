import { ISettleSpec } from "../../types";
import { SettleBetData } from "../store/types";
import PartPointsWidgets from "./part-points/PartPointsWidgets";

interface Props {
    settleSpec: ISettleSpec;
    store: SettleBetData;
    sportId: string;
}

const SettleBetWidgets = ({ settleSpec, store, sportId }: Props) => {
    const { parts } = settleSpec;

    return parts ?
        <PartPointsWidgets parts={parts} store={store} sportId={sportId} /> :
        null;
};

export default SettleBetWidgets;
