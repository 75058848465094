import { BetType } from "../../../bets/service/types";
import WithInput from "../../../input/WithInput";

function useBetTypeInput(initial: BetType = "Back") {
    return WithInput.useSelect<BetType>(initial);
}

const exports = {
    useBetTypeInput,
};

export default exports;
