import * as React from "react";
import Money from "../../money/Money";
import { IOdds } from "../types";
import Odds from "./Odds";

interface Props {
    currencyCode: string;
    odds: IOdds;
    stake: number;
}

const StakeAtOdds: React.FC<Props> = ({currencyCode, odds, stake}) => {

    return (
        <span>
            <Money amount={stake} currency={currencyCode} />
            <span>&nbsp;@&nbsp;</span>
            <Odds odds={odds}/>
        </span>
    );
};

export default StakeAtOdds;
