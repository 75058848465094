import LRUCache from "./LRUCache";
import { ICache } from "./ICache";

class LRUCacheFactory {
    create<T>(maximumCapacity: number): ICache<T> {
        return new LRUCache<T>(maximumCapacity);
    }
}

export default new LRUCacheFactory();
