import { useState } from "react";
import * as React from "react";
import classnames from "classnames";
import PaneProps from "./PaneProps";
import paneStyles from "./Pane.module.scss";
import Once from "../../lifecycle/Once";
import ClosePaneButton from "../../button/close-pane/ClosePaneButton";

interface BasePaneProps extends PaneProps {
    className?: string;
    icon: string;
    iconAltText: string;
}

const BasePane: React.FC<BasePaneProps> = ({
    className,
    icon,
    iconAltText,
    message,
    ...props
}) => {
    const [isDismissed, setIsDismissed] = useState(false);

    const onDismissClick = Once.useCallbackOnce(() => {
        setIsDismissed(true);
    });

    if (isDismissed) {
        return null;
    }

    return (
        <div className={classnames(paneStyles.pane, className)} {...props}>

            <div className={paneStyles.content}>
                <img className={paneStyles.iconImage} data-testid="pane-icon" src={icon} alt={iconAltText} />
                <div className={paneStyles.message} data-testid="pane-message">{message}</div>
            </div>

            <ClosePaneButton
                className={paneStyles.dismiss}
                data-testid="pane-dismiss"
                description="Dismiss"
                onClick={onDismissClick}
            />
        </div>
    );
};

export default BasePane;
