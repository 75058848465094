import { useMemo, useCallback } from "react";
import * as React from "react";
import FormCard from "../../../form/FormCard";
import { IActionComponentProps } from "../../../layout/action/ActionComponent";
import UseScreenIs from "../../../screen/hooks/UseScreenIs";
import { ISport } from "../../../sports/Sport";
import SelectSport from "../../../sports/components/select/SelectSport";
import SportsService from "../../../sports/SportsService";
import useSelect from "../../../input/useSelect";
import useInput from "../../../input/useInput";
import EventDefaults from "./internal/EventDefaults";
import SubmitCreateEvent from "./internal/SubmitCreateEvent";

import styles from "./CreateEvent.module.scss";

interface Props {
    sports: ISport[];
}

const getSuggestedTimestamp = () => EventDefaults.getSuggetedTimestamp();
const EMPTY_ARRAY: any[] = [];

const CreateEvent: React.FC<IActionComponentProps<Props>> = ({onActionDone, sports}) => {
    const defaultSuggestion = useMemo(getSuggestedTimestamp, EMPTY_ARRAY);

    const [nameValue, onNameValueChange, setNameValue] = useInput();
    const [sportValue, onSportValueChange] = useSelect(SportsService.getDefaultSelection());
    const [dateValue, onDateValueChange] = useInput(defaultSuggestion.date);
    const [timeValue, onTimeChange] = useInput(defaultSuggestion.time);

    const onSubmit = SubmitCreateEvent.useSubmit(nameValue, sportValue, dateValue, timeValue);

    const onSuccess = useCallback(() => {
        onActionDone();
        setNameValue("");
    }, [onActionDone, setNameValue]);

    return (
        <FormCard
            legend="New event"
            onSubmit={onSubmit}
            onSubmitSuccess={onSuccess}
            submitCaption="Create event"
        >
            <label>
                Name
                <input
                    autoFocus={UseScreenIs.usePhone()}
                    data-testid="create-event-name-input"
                    onChange={onNameValueChange}
                    required={true}
                    value={nameValue}
                />
            </label>

            <label>
                Sport
                <SelectSport
                    data-testid="create-event-sport-select"
                    sports={sports}
                    value={sportValue}
                    onChange={onSportValueChange}
                />
            </label>

            <div className={styles.dateTimeRow}>
                <label className={styles.date}>
                    Date
                    <input
                        data-testid="create-event-date-input"
                        onChange={onDateValueChange}
                        placeholder="YYYY-MM-DD"
                        required={true}
                        type="date"
                        value={dateValue}
                    />
                </label>

                <label className={styles.time}>
                    Time
                    <input
                        data-testid="create-event-time-input"
                        onChange={onTimeChange}
                        placeholder="15:30"
                        required={true}
                        type="time"
                        value={timeValue}
                    />
                </label>
            </div>
        </FormCard>
    );
};

export default CreateEvent;
