import CriteriaFactory from "../criteria/CriteriaFactory";
import { ApiResultType, ResultType } from "../type";

class ResultFactory {
    public fromApi(apiResult: ApiResultType): ResultType {
        return {
            criteria: CriteriaFactory.fromApi(apiResult.criteria),
            id: apiResult.id,
            name: apiResult.name,
        };
    }
}

export default new ResultFactory();
