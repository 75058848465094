import LRUCacheFactory from "../../cache/LRUCacheFactory";
import { ICache } from "../../cache/ICache";
import canonicalAccountName from "./canonicalAccountName";

export class CanonicalNameServiceImpl {
    private cache: ICache<string>  = LRUCacheFactory.create(100);

    public getCanonicalName(accountName: string): string {
        const cached = this.cache.get(accountName);
        if (cached) {
            return cached;
        }

        const canonicalName = canonicalAccountName(accountName);
        this.cache.set(accountName, canonicalName);

        return canonicalName;
    }
}

export default new CanonicalNameServiceImpl();
