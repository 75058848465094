import styles from "../BetRow.module.scss";
import { IMarket } from "../../../../markets/type";
import { IBet } from "../../../service/types";
import WithMarkets from "./WithMarkets";
import CancelledPill from "./CancelledPill";

interface MetaProps {
    bet: IBet;
    markets: IMarket[];
}

const Meta = ({
    bet,
    markets,
}: MetaProps) => {
    const resultName = WithMarkets.useSelectedResultName(markets, bet.selection);

    return (
        <div className={styles.row} data-testid="bet-meta">
            <strong data-testid="bet-meta-result">{resultName}</strong>
            <em data-testid="bet-meta-type">{bet.type}</em>
            {bet.state === "cancelled" && <CancelledPill />}
        </div>
    );
};

export default Meta;
