import CallbacksFactory from "../subscription/callback/CallbacksFactory";

export class OverlayServiceImpl {
    private readonly callbacks = CallbacksFactory.create<void>();
    private readonly activeIds = new Set<string>();

    public notifyOpen(id: string): void {
        const wasEmpty = this.activeIds.size === 0;
        this.activeIds.add(id);
        if (wasEmpty) {
            this.callbacks.fireCallbacks();
        }
    }

    public notifyClosed(id: string): void {
        const oldSize = this.activeIds.size;
        this.activeIds.delete(id);
        if (this.activeIds.size === 0 && oldSize > 0) {
            this.callbacks.fireCallbacks();
        }
    }

    public getIsOpen(): boolean {
        return this.activeIds.size > 0;
    }

    public subscribe(callback: () => void): () => void {
        return this.callbacks.subscribe(callback);
    }
}

export default new OverlayServiceImpl();
