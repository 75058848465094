import * as React from "react";
import Card from "../card/Card";
import RequestForm, { RequestFormProps } from "./RequestForm";

interface FormCardProps extends RequestFormProps {
}

const FormCard: React.FC<FormCardProps> = (props) => (
    <Card>
        <RequestForm {...props} />
    </Card>
);

export default FormCard;
