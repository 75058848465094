import API from "../../api";
import { ApiResponse, fromUnsuccessfulResponse } from "../../api/ApiResponse";
import ISubscriptionList from "../../subscription/list/ISubscriptionList";
import getIdFromName from "../canon/getIdFromName";
import { AccountLikeService, AddAccount, AccountLike, AccountType } from "../types";

interface AddAccountBody {
    name: string;
    currencyCode: "GBP";
    initialBalance: number;
}

export default abstract class AccountLikeServiceBase<T extends AccountLike> implements AccountLikeService<T> {
    protected abstract readonly store: ISubscriptionList<T, string>;
    protected abstract readonly accountType: AccountType;

    public getAccounts(): Promise<ApiResponse<T[]>> | T[] {
        return this.store.get();
    }

    public async addAccount(addAccount: AddAccount): Promise<undefined> {
        const response = await API.putJson(this.accountType, createAddAccountBody(addAccount));
        if (!response.successful) {
            return Promise.reject(fromUnsuccessfulResponse(response, `Failed to add ${this.accountType} account`));
        }

        this.store.insert(this.createAccountFromAddAccount(addAccount));
    }

    public subscribe(onChange: (banks: T[]) => void): () => void {
        return this.store.subscribe(onChange);
    }

    private findWithName(displayName: string): T | undefined {
        return this.store.find((item: T) => item.name === displayName);
    }

    public localBalanceAdjustment(displayName: string, offsetAmount: number): void {
        const original = this.findWithName(displayName);
        if (original) {
            const adjusted: T = {
                ...original,
                balance: original.balance + offsetAmount,
            };

            this.store.updateOne(getIdFromName(adjusted), adjusted);
        }
    }

    protected createAccountFromAddAccount(addAccount: AddAccount): T {
        return {
            ...addAccount,
            currencyCode: "GBP",
        } as T;
    }
}

function createAddAccountBody(addAccount: AddAccount): AddAccountBody {
    return {
        currencyCode: "GBP",
        initialBalance: addAccount.balance,
        name: addAccount.name,
    };
}
