import ResultInput from "../ResultInput";
import { IUsesCurrentMarket, IUsesResultInput } from "./types";

interface Props extends IUsesCurrentMarket, IUsesResultInput {

}

const ResultEntry = ({
    currentMarket,
    result,
}: Props) => (
    <label>
        Result
        <ResultInput
            onChange={result[1]}
            market={currentMarket}
            value={result[0]}
        />
    </label>
);

export default ResultEntry;
