import { useCallback } from "react";
import * as React from "react";
import classnames from "classnames";
import styles from "./AccountSelect.module.scss";
import AccountService from "../../AccountService";
import groupByType from "../../grouping/groupByType";
import AccountOptions from "./internal/AccountOptions";
import Once from "../../../lifecycle/Once";
import { IAccount } from "../../types";

interface AccountSelectProps extends Omit<React.ComponentPropsWithoutRef<'select'>, 'onChange'> {
    accounts: IAccount[];
    onChange: (accountKey: string) => void;
}

const AccountSelect: React.FC<AccountSelectProps> = ({accounts, className, onChange, value, ...props}) => {
    const accountsInOrder = groupByType(accounts);

    const onAccountChange: (event: React.ChangeEvent<HTMLSelectElement>) => void = useCallback(({target}) => {
        onChange(target.value);
    }, [onChange]);

    Once.useEffectOnce(() => {
        if (!value) {
            const firstAccount = accountsInOrder.banks[0] || accountsInOrder.bookmakers[0] || accountsInOrder.exchanges[0];
            if (firstAccount) {
                onChange(AccountService.createKey(firstAccount));
            }
        }
    });

    return (
        <select
            {...props}
            onChange={onAccountChange}
            className={classnames(
                className,
                styles.accountSelect,
            )}
            value={value}
        >
            <optgroup label="Banks">
                <AccountOptions accounts={accountsInOrder.banks} />
            </optgroup>

            <optgroup label="Bookmakers">
                <AccountOptions accounts={accountsInOrder.bookmakers} />
            </optgroup>

            <optgroup label="Exchanges">
                <AccountOptions accounts={accountsInOrder.exchanges} />
            </optgroup>
        </select>
    );
};

export default AccountSelect;
