import { AccountType } from "../types";
import NeverChange from "../../lifecycle/NeverChange";
import AccountService from "../AccountService";
import UseFetchAndSubscribe from "../../fetch/hooks/UseFetchAndSubscribe";

function useAccountsOfType(type: AccountType) {
    NeverChange.useNeverChange(type);

    return UseFetchAndSubscribe.useFetchAndSubscribe(
        () => AccountService.getAccountsOfType(type),
        (cb) => AccountService.subscribeToType(type, cb),
    );
}

const exports = {
    useAccountsOfType,
};

export default exports;
