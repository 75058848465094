import { useState, useMemo, useCallback } from "react";
import * as React from "react";
import AccountService from "../../../accounts/AccountService";
import { IAccount, IdentifiableAccount } from "../../../accounts/types";
import MoneyInput from "../../../money/form/MoneyInput";
import AccountSelect from "../../../accounts/components/select/AccountSelect";
import { SubmitFunction } from "../../../form/types";
import TransactionService from "../../TransactionService";
import useInput from "../../../input/useInput";
import EmptyMessage from "../../../feedback/message/empty-message/EmptyMessage";
import FormCard from "../../../form/FormCard";
import { Fetch, isDone } from "../../../fetch";
import FetchConditional from "../../../fetch/FetchConditional";
import If from "../../../routing/components/conditional/If";
import { IActionComponentProps } from "../../../layout/action/ActionComponent";
import UseScreenIs from "../../../screen/hooks/UseScreenIs";

interface TransferFundsProps {
    accountsFetch: Fetch<IAccount[]>;
    initiator: IdentifiableAccount;
}

const TransferFunds: React.FC<IActionComponentProps<TransferFundsProps>> = ({accountsFetch, initiator, onActionDone}) => {
    const [destinationValue, setDestinationValue] = useState("");
    const [inputAmount, onInputAmountChange, setInputAmount] = useInput();

    const filteredAccounts = useMemo(() => {
        return isDone(accountsFetch) ?
            AccountService.excludeFromList(accountsFetch.payload, initiator) :
            [];
    }, [initiator, accountsFetch]);

    const onSubmitClick: SubmitFunction = useCallback(async () => {
        const decoded = AccountService.fromKey(destinationValue);

        await TransactionService
            .transferMoney(Number(inputAmount), decoded, initiator);
        setInputAmount("");
        return "Transfer complete";
    }, [destinationValue, inputAmount, initiator, setInputAmount]);

    const noOtherAccounts = filteredAccounts.length === 0;

    return (
        <FormCard
            disabled={noOtherAccounts}
            legend="Transfer Money"
            onSubmit={onSubmitClick}
            onSubmitSuccess={onActionDone}
            submitCaption={"Transfer"}
        >
            <FetchConditional
                fetch={accountsFetch}
                loadingMessage="Loading accounts…"
            >
                <If condition={noOtherAccounts}>
                    <EmptyMessage text="Presently no other accounts to transfer money into." />
                </If>

                <If condition={!noOtherAccounts}>
                    <label>
                        Transfer amount
                        <MoneyInput
                            autoFocus={UseScreenIs.usePhone()}
                            onChange={onInputAmountChange}
                            value={inputAmount}
                            placeholder="Amount to transfer"
                            required={true}
                        />
                    </label>

                    <label>
                        Into account
                        <AccountSelect
                            data-testid="input-transfer-destination"
                            accounts={filteredAccounts}
                            onChange={setDestinationValue}
                            value={destinationValue}
                        />
                    </label>
                </If>
            </FetchConditional>
        </FormCard>
    );
};

export default TransferFunds;
