import * as React from "react";
import { ResultType } from "../../type";

interface Props extends React.ComponentPropsWithoutRef<'select'> {
    options: ResultType[];
}

const SelectResult: React.FC<Props> = ({options, ...props}) => {
    return (
        <select {...props}>
            {
                options.map((option) => (
                    <option value={option.id} key={option.id}>
                        {option.name}
                    </option>
                ))
            }
        </select>
    );
};

export default SelectResult;
