import { useRef, useState, useMemo } from "react";
import Once from "../lifecycle/Once";
import ISet from "./ISet";

function useSet<T>(): ISet<T> {
    const setRef = useRef<Set<T>>(new Set<T>());
    const [values, setValues] = useState([] as T[]);

    const updateValues = () => {
        setValues(Array.from(setRef.current.values()));
    };

    const add = Once.useCallbackOnce((value: T) => {
        setRef.current.add(value);
        updateValues();
    });

    const remove = Once.useCallbackOnce((value: T) => {
        setRef.current.delete(value);
        updateValues();
    });

    return useMemo(() => ({
        add,
        has: (value: T) => setRef.current.has(value),
        remove,
        values: () => values,
    }), [add, remove, values]);
}

export default useSet;
