import WindowResizeObserverFactory from "../window/WindowResizeObserverFactory";
import ScreenSizes, { ScreenType } from "./ScreenSizes";
import CallbacksFactory from "../subscription/callback/CallbacksFactory";
import ICallbacks from "../subscription/callback/ICallbacks";

export class ScreenSizeChangedObserverFactoryImpl {
    private readonly callbacks: ICallbacks<ScreenType> = CallbacksFactory.create();

    constructor() {
        let lastType: ScreenType = ScreenSizes.getScreenType();

        WindowResizeObserverFactory.createListener(() => {
            const currentType: ScreenType = ScreenSizes.getScreenType();
            if (currentType !== lastType) {
                this.callbacks.fireCallbacks(lastType);
                lastType = currentType;
            }
        });
    }

    public createListener(onResize: (oldScreenType: ScreenType) => void): () => void {
        return this.callbacks.subscribe(onResize);
    }
}

export default new ScreenSizeChangedObserverFactoryImpl();
