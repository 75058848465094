import { ApiCondition } from "../api-types";
import { ICondition } from "../types";
import VariableFactory from "../variables/VariableFactory";
import Condition from "./Condition";

class ConditionFactory {
    fromApi(apiCondition: ApiCondition): ICondition {
        return new Condition(VariableFactory.fromApi(apiCondition[0]), VariableFactory.fromApi(apiCondition[2]));
    }
}

export default new ConditionFactory();
