import * as React from "react";
import styles from "./Overflow.module.scss";
import classnames from "classnames";

const SpanOverflowEllipsis: React.FC<React.HTMLAttributes<HTMLSpanElement>> = ({className, ...rest}) => {
    return (
        <span
            className={
                classnames(
                    className,
                    styles["…"],
                )
            }
            {...rest}
        />
    );
};

export default SpanOverflowEllipsis;
