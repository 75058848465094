import * as React from "react";
import Spinner from "../../spinner/Spinner";

import Styles from "./LoadingButton.module.scss";
import Button from "../Button";

interface Props {
    disabled?: boolean;
    loading: boolean;
}

type CombinedProps = Props & React.ButtonHTMLAttributes<HTMLButtonElement>;

const LoadingButton: React.FC<CombinedProps> = ({children, disabled, loading, ...restProps}) => (
    <Button data-testid="loading-button" className={Styles.loadingButton} disabled={loading || disabled} {...restProps}>
        <div className={Styles.indicator}/>
        {children}
        <div className={Styles.indicator}>
            {loading ? <Spinner /> : null}
        </div>
    </Button>
);

export default LoadingButton;
