import isNotEmpty from "../../array/isNotEmpty";
import { IBet } from "../../bets/service/types";
import { isPartPointsRequiredVariable } from "../../markets/criteria/types";
import { IMarket } from "../../markets/type";
import numberComparator from "../../numbers/numberComparator";
import { ISettleSpec } from "../types";
import WithOpenBets from "./internal/WithOpenBets";

const useSettleSpec = (markets: IMarket[], openBets: IBet[]): ISettleSpec => {
    const requiredVariables = WithOpenBets.useRequiredVariables(markets, openBets);

    const allPartsNeeded = requiredVariables
        .reduce<number[]>((allParts, variable) => {
            if (isPartPointsRequiredVariable(variable)) {
                const partNumberMaybe = variable.references.get("part-number");
                const partNumber = partNumberMaybe === undefined ? -1 : partNumberMaybe;
                if (!allParts.includes(partNumber)) {
                    return [...allParts, partNumber];
                }
            }

            return allParts;
        }, [])
        .sort(numberComparator);

    return {
        parts: isNotEmpty(allPartsNeeded) ? allPartsNeeded : undefined,
    };
};

const SettleSpecCompiler = {
    useSettleSpec,
};

export default SettleSpecCompiler;
