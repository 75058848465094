import AccountSelect from "../../../../accounts/components/select/AccountSelect";
import { IAccount } from "../../../../accounts/types";
import { IUsesAccount } from "./types";

interface Props extends IUsesAccount {
    accounts: IAccount[];
}

const AccountEntry = ({account, accounts}: Props) => (
    <label>
        Account
        <AccountSelect
            data-testid="betslip-account-select"
            accounts={accounts}
            onChange={account[1]}
            value={account[0]}
        />
    </label>
);

export default AccountEntry;
