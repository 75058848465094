import * as React from "react";
import styles from "./ErrorPane.module.scss";
import BasePane from "./BasePane";
import ErrorIcon from "./icons/error.svg";
import PaneProps from "./PaneProps";

const ErrorPane: React.FC<PaneProps> = ({message}) => (
    <BasePane
        className={styles.errorPane}
        data-testid="error-pane"
        icon={ErrorIcon}
        iconAltText="Error"
        message={message}
    />
);

export default ErrorPane;
