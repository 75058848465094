import { useEffect } from "react";
import { IAccount } from "../../../../accounts/types";
import BetSlip from "../../../../bet-slip/BetSlip";
import { IBet } from "../../../../bets/service/types";
import Action from "../../../../layout/action/Action";
import { IMarket } from "../../../../markets/type";
import UseObserver from "../../../../subscription/observer/UseObserver";
import { IEvent } from "../../../types";

export interface BetSlipActionProps {
    accounts: IAccount[];
    event: IEvent;
    markets: IMarket[];
    clearEditingBetSelection: () => void;
    editingBet?: IBet;
}

const BetSlipAction = ({
    accounts,
    clearEditingBetSelection,
    editingBet,
    event,
    markets,
}: BetSlipActionProps) => {
    const [emitForceOpen, forceOpenEvents] = UseObserver.useObserver<void>();

    useEffect(() => {
        if (editingBet) {
            emitForceOpen();
        }
    }, [editingBet, emitForceOpen]);

    return (
        <Action
            caption="New bet"
            component={BetSlip}
            editingBet={editingBet}
            markets={markets}
            event={event}
            accounts={accounts}
            forceOpenEvents={forceOpenEvents}
            onFinished={clearEditingBetSelection}
        />
    );
};

export default BetSlipAction;
