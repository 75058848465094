import * as React from "react";
import styles from "./ActionContents.module.scss";
import classnames from "classnames";
import UseScreenSize from "../../../../screen/hooks/UseScreenSize";
import { ScreenType } from "../../../../screen/ScreenSizes";
import { IActionComponentProps } from "../../ActionComponent";

type Props<T> = T & {
    component: React.ComponentType<IActionComponentProps<T>>;
    isOpen: boolean;
    onClose: () => void;
};

const isAlwaysOpen = (screenSize: ScreenType) => screenSize !== "phone";

function ActionContents<T>({component, isOpen, onClose, ...rest}: Props<T>) {
    const Component = component;
    const screenSize = UseScreenSize.useScreenSize();
    const ignoreContents = !isOpen && !isAlwaysOpen(screenSize);

    return ignoreContents ? null : (
        <div
            className={classnames({
                [`${styles.phone}`]: screenSize === "phone",
            })}
            data-testid="action-contents"
        >
            <Component onActionDone={onClose} {...(rest as any as T)} />
        </div>
    );
}

export default ActionContents;
