import ConfirmPromptFactory from "../confirm/ConfirmPromptFactory";
import { IQuickActionConfiguration, QuickActionIcon, QuickActionState } from "./types";

class QuickActionConfigurationFactory {
    createConfiguration<IdType extends string>(
        id: IdType,
        state: QuickActionState,
        caption: string,
        icon: QuickActionIcon,
        confirmBody?: string,
        confirmCaption?: string,
        cancelCaption?: string,
    ): IQuickActionConfiguration<IdType> {
        return {
            icon,
            id,
            caption,
            confirmation: confirmBody === undefined ?
                undefined :
                ConfirmPromptFactory.createPrompt(caption, confirmBody, confirmCaption, cancelCaption),
            state,
        };
    }
}

export default new QuickActionConfigurationFactory();
