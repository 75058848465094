import * as React from "react";
import { ITransaction } from "../../types";
import ExpandingRowList from "../../../list/ExpandingRowList";
import TransactionRow from "./TransactionRow";
import transactionKey from "./transactionKey";
import styles from "./TransactionRow.module.scss";

interface TransactionsListProps {
    currencyCode: string;
    data: ITransaction[];
}

const TransactionsList: React.FC<TransactionsListProps> = ({currencyCode, data}) => (
    <ExpandingRowList
        additional={{
            currencyCode,
        }}
        component={TransactionRow}
        rowClassName={styles.transactionRow}
        data={data}
        getKey={transactionKey}
        emptyMessage="There are no transactions to display for this account."
    />
);

export default TransactionsList;
