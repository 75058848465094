import api from "../../api";
import { ApiResponse, createSuccess, fromUnsuccessfulResponse } from "../../api/ApiResponse";
import { ISport, createSport } from "../Sport";
import { IApiSport } from "./api";

class LoadSports {
    public async loadSports(): Promise<ApiResponse<ISport[]>> {
        const response = await api.get<IApiSport[]>("/sports");

        return (response.successful) ?
            createSuccess(response.data.map(apiSport => createSport(`${apiSport.id}`, apiSport.name))) :
            fromUnsuccessfulResponse(response, "Failed to get sports");
    }
}

export default new LoadSports();
