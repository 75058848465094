import { useMemo } from "react";
import * as React from "react";
import ExpandingRowList from "../../../list/ExpandingRowList";
import { ISport } from "../../../sports/Sport";
import { IEvent } from "../../types";
import EventRow from "./EventRow";
import HeaderDataProviderFactory from "./HeaderDataProviderFactory";
import HeaderRow from "./HeaderRow";
import { IEventListAdditionalData } from "./types";
import UseOnEventRowClick from "./UseOnEventRowClick";

interface Props {
    events: IEvent[];
    sports: ISport[];
}

const getEventKey = ({id}: IEvent) => id;

const EventList: React.FC<Props> = ({events, sports}) => {
    const onRowClick = UseOnEventRowClick.useOnEventRowClick();
    const headerDataProvider = HeaderDataProviderFactory.getProvider();

    const additional: IEventListAdditionalData = useMemo(() => ({
        sports,
    }), [sports]);

    return (
        <ExpandingRowList
            additional={additional}
            component={EventRow}
            data={events}
            emptyMessage="There are no events to display."
            getKey={getEventKey}
            headerComponent={HeaderRow}
            getHeaderData={headerDataProvider}
            onRowClick={onRowClick}
        />
    );
};

export default EventList;
