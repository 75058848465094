import * as React from "react";
import BurgerManager from "../../BurgerManager";
import Once from "../../../lifecycle/Once";

import CloseButton from "../../../overlay/CloseButton";

interface Props {
    className?: string;
}

const CloseBurgerButton: React.FC<Props> = (props) => {
    const closeBurger = Once.useCallbackOnce(() => BurgerManager.closeMenu());

    return (
        <CloseButton
            description="Close Menu"
            className={props.className}
            onClick={closeBurger}
        />
    );
};

export default CloseBurgerButton;
