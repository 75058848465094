import { ICache } from "./ICache";

class Node<T> {
    public next: Node<T> | null = null;

    constructor (
        public readonly key: string,
        public value: T,
        public prev: Node<T> | null = null,
    ) {

    }
}

class LRUCache<T> implements ICache<T> {
    protected head: Node<T> | null = null;
    protected tail: Node<T> | null = null;
    protected readonly cache: Map<string, Node<T>> = new Map();

    constructor(private readonly capacity: number) {
        if (capacity === 0) {
            throw new Error("Invalid cache size: 0");
        }
        if (capacity < 0) {
            throw new Error(`Invalid negative cache size: ${capacity}`);
        }
    }

    set(key: string, value: T): void {
        const existingNode = this.cache.get(key);
        if (existingNode !== undefined) {
            existingNode.value = value;
            this.wasUsed(existingNode);

            return;
        }

        if (this.head === null) {
            this.head = this.tail = new Node(key, value);
            this.cache.set(key, this.head);
        } else {
            if (this.cache.size === this.capacity) {
                const evicting = this.head;
                this.head = evicting.next;
                evicting.next = null;
                this.cache.delete(evicting.key);
            }

            const oldTail = this.tail;
            const newNode: Node<T> = new Node(key, value, oldTail);
            this.tail = newNode;

            // oldTail cannot be null as this entire block won't be entered if head is null
            // and if head is null then tail must be null also by definition
            (oldTail as Node<T>).next = newNode;

            this.cache.set(key, newNode);
        }
    }

    get(key: string): T | undefined {
        const node = this.cache.get(key);
        if (node !== undefined) {
            this.wasUsed(node);
            return node.value;
        }

        return undefined;
    }

    remove(key: string) {
        const node = this.cache.get(key);
        if (node === undefined) {
            return;
        }

        const oldPrev = node.prev;
        const oldNext = node.next;

        if (oldPrev) {
            oldPrev.next = oldNext;
        }

        if (oldNext) {
            oldNext.prev = oldPrev;
        }

        if (node === this.head) {
            this.head = oldNext;
        }

        if (node === this.tail) {
            this.tail = oldPrev;
        }

        this.cache.delete(key);
    }

    private wasUsed(node: Node<T>) {
        // Assuming class can't elevate a value if it is empty
        // since if is not emptt, head and tail have to exist
        const oldTail = this.tail as Node<T>;

        if (node === this.tail) {
            return;
        }

        if (node === this.head) {
            node.prev = this.tail;
            oldTail.next = node;
            this.head = node.next;
            this.tail = node;
            node.next = null;
            return;
        }

        // By not returning above it is certain that this node it not at either end
        // therefore it's pointers cannot be null
        const oldNext = node.next as Node<T>;
        const oldPrev = node.prev as Node<T>;

        oldNext.prev = oldPrev;
        oldPrev.next = oldNext;

        node.prev = this.tail;
        oldTail.next = node;
        this.tail = node;
    }
}

export default LRUCache;
