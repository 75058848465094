import classnames from "classnames";
import styles from "./Pill.module.scss";

interface Props {
    caption: string;
    kind: "primary" | "secondary" | "warning";
    "data-testid"?: string;
}

const Pill = ({caption, kind, ...props}: Props) => (
    <span className={classnames(
        styles.pill,
        kind === "primary" && styles.primary,
        kind === "secondary" && styles.secondary,
        kind === "warning" && styles.warning,
    )}
    data-testid={props["data-testid"]}>
        {caption}
    </span>
);

export default Pill;
