import IBank from "./IBank";
import ISubscriptionList from "../subscription/list/ISubscriptionList";
import { AccountLikeService, AccountType } from "../accounts/types";
import AccountLikeServiceBase from "../accounts/generic/AccountLikeServiceBase";
import AccountsListStoreFactory from "../accounts/generic/AccountsListStoreFactory";

export class BankServiceImpl extends AccountLikeServiceBase<IBank> implements AccountLikeService<IBank> {
    protected readonly accountType: AccountType = "bank";
    protected readonly store: ISubscriptionList<IBank, string> = AccountsListStoreFactory.create("bank");
}

export default new BankServiceImpl();
