import DateFormatter from "../../../date/DateFormatter";
import { IEvent } from "../../types";
import { EVENT_DATE_FORMAT_OPTIONS } from "../constants";
import { EventHeaderRowData } from "./types";

class HeaderDataProviderFactory {
    private readonly singleInstance = ({when}: IEvent): EventHeaderRowData => {
        const formattedDate = DateFormatter.toLocaleString(when, EVENT_DATE_FORMAT_OPTIONS);

        return {
            key: formattedDate,
            formattedDate,
        };
    }

    public getProvider() {
        return this.singleInstance;
    }
}

export default new HeaderDataProviderFactory();
