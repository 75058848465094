import LocaleService from "../locale/LocaleService";
import NumberFormatter from "../numbers/format/NumberFormatter";

class MoneyFormatter {
    public format(amount: number, currency: string): string {
        const options: Intl.NumberFormatOptions = {
            currency,
            style: 'currency',
        };

        return NumberFormatter.toLocaleString(amount, LocaleService.getLocale(), options);
    }
}

export default new MoneyFormatter();
