import { useState } from "react";
import { Fetch, isDone } from "../../fetch";
import Once from "../../lifecycle/Once";
import { IBet } from "../service/types";

export type SelectBetForEditing = (betId: string) => void;
export type SelectedBetForEditingState = [string | undefined, SelectBetForEditing, () => void];

function useSelectedForEditBet(): SelectedBetForEditingState {
    const [selectedBetId, setSelectedBetId] = useState<string>();
    const clearId = Once.useCallbackOnce(() => setSelectedBetId(undefined));

    return [selectedBetId, setSelectedBetId, clearId];
}

function useActualBet(betsFetch: Fetch<IBet[]>, selectedForEditingBetId?: string): IBet | undefined {
    return isDone(betsFetch) ? betsFetch.payload.find(({id}: IBet) => id === selectedForEditingBetId) : undefined;
}


const exports = {
    useSelectedForEditBet,
    useActualBet,
};

export default exports;
