import API from "../../api";
import { ApiResponse, fromUnsuccessfulResponse } from "../../api/ApiResponse";
import ISubscriptionListMap from "../../subscription/list/ISubscriptionListMap";
import ApiMapper from "./internal/ApiMapper";
import BetCancellationValueCache from "./internal/BetCancellationValueCache";
import BetDeleteValueCache from "./internal/BetDeleteValueCache";
import BetFactory from "./internal/BetFactory";
import BetsListCacheProvider from "./internal/BetsListCacheProvider";
import { AddBet, EditBet, IBet, IApiBet } from "./types";

export class BetServiceImpl {
    private readonly cancellingBetIds = new Set<string>();
    private readonly deletingBetIds = new Set<string>();
    private readonly betCancellationValueCache = BetCancellationValueCache.get();
    private readonly betDeleteValue = BetDeleteValueCache.get();

    private get betsCache(): ISubscriptionListMap<IBet, string> {
        return BetsListCacheProvider.get();
    }

    public async placeBet(addBet: AddBet) {
        const response = await API.postJson<IApiBet>("/bet", ApiMapper.toAddBetApi(addBet));

        if (!response.successful) {
            return Promise.reject(fromUnsuccessfulResponse(response, "Failed to add a new bet"));
        }

        this.betsCache.attemptInsert(addBet.eventId, BetFactory.fromApi(response.data));
    }

    public async editBet(existingBet: IBet, editBet: EditBet) {
        const editBetApi = ApiMapper.toEditBetApi(editBet);
        const response = await API.patchJson(`/bet/${existingBet.id}`, editBetApi);

        if (!response.successful) {
            return Promise.reject(fromUnsuccessfulResponse(response, "Failed to edit the bet"));
        }

        const newBet = BetFactory.fromEdit(existingBet, editBetApi);
        this.betsCache.updateWithId(editBet.eventId, existingBet.id, newBet);
    }

    public getBetsForEvent(eventId: string): Promise<ApiResponse<IBet[]>> | IBet[] {
        return this.betsCache.get(eventId);
    }

    public subscribe(eventId: string, callback: (data: IBet[]) => void): () => void {
        return this.betsCache.subscribe(eventId, callback);
    }

    public cancelBet(eventId: string, betId: string): void {
        this.cancellingBetIds.add(betId);
        this.betCancellationValueCache.recalculate(betId);

        API.post(`/bet/${betId}/cancel`).then(() => {
            const existingBet = this.betsCache.findWithId(eventId, betId);
            if (existingBet) {
                const newBet: IBet = {
                    ...existingBet,
                    state: "cancelled",
                };

                this.betsCache.updateWithId(eventId, betId, newBet);
            }
        }).finally(() => {
            this.cancellingBetIds.delete(betId);
            this.betCancellationValueCache.recalculate(betId);
        });
    }

    public queryBetCancellation(betId: string): boolean {
        return this.cancellingBetIds.has(betId);
    }

    public deleteBet(eventId: string, betId: string): void {
        this.deletingBetIds.add(betId);
        this.betDeleteValue.recalculate(betId);

        API.delete(`/bet/${betId}`).then(() => {
            this.betsCache.deleteOne(eventId, betId);
        }).finally(() => {
            this.deletingBetIds.delete(betId);
            this.betDeleteValue.recalculate(betId);
        });
    }

    public queryBetDeletion(betId: string): boolean {
        return this.deletingBetIds.has(betId);
    }

    public subscribeToBetCancellation(betId: string, onChange: (isCancelling: boolean) => void) {
        return this.betCancellationValueCache.subscribe(betId, onChange);
    }

    public subscribeToBetDeletion(betId: string, onChange: (isCancelling: boolean) => void) {
        return this.betDeleteValue.subscribe(betId, onChange);
    }
}

export default new BetServiceImpl();
