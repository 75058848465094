import SportsService from "../../../../sports/SportsService";
import { ILogPart } from "../../store/types";
import PartPointsEntry from "./internal/PartPointsEntry";

interface Props {
    parts: [number, ...number[]];
    sportId: string;
    store: ILogPart;
}

const PartPointsWidgets = ({ parts, sportId, store }: Props) => {
    const lastPartForSport = SportsService.getTotalParts(sportId) - 1;

    return (
        <>
            {
                parts
                    .filter(part => part >= 0)
                    .map(part => <PartPointsEntry key={part} part={part} sportId={sportId} store={store} />)
            }
            {
                parts.includes(-1) && !parts.includes(lastPartForSport) ?
                    <PartPointsEntry part={lastPartForSport} sportId={sportId} store={store} />
                    : null
            }
        </>
    );
};

export default PartPointsWidgets;
