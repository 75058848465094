import { ITransaction, ISink } from "../types";
import DateProvider from "../../date/DateProvider";

class TransactionFactory {
    public fromNewTransaction(amount: number, destination: ISink, source?: ISink): ITransaction {

        return {
            amount,
            time: DateProvider.now(),
            source: (source) ? {
                ...source,
            } : null,
            destination: {
                ...destination,
            },
        };
    }
}

export default new TransactionFactory();
