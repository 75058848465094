import * as React from "react";
import Page from "../../layout/page/Page";
import Primary from "../../layout/page/Primary";
import Secondary from "../../layout/page/Secondary";
import Action from "../../layout/action/Action";
import Actions from "../../layout/action/Actions";
import CreateEvent from "./create/CreateEvent";
import { IEvent } from "../types";
import { ISport } from "../../sports/Sport";
import EventsDashboard from "./dashboard/EventsDashboard";

interface Props {
    events: IEvent[];
    sports: ISport[];
}

const EventsPage: React.FC<Props> = ({events, sports}) => (
    <Page>
        <Primary>
            <EventsDashboard events={events} sports={sports} />
        </Primary>
        <Secondary>
            <Actions>
                <Action
                    component={CreateEvent}
                    caption="Create event"
                    sports={sports}
                />
            </Actions>
        </Secondary>
    </Page>
);

export default EventsPage;
