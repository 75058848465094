import API from "../../api";
import { ApiResponse, createSuccess, fromUnsuccessfulResponse } from "../../api/ApiResponse";
import { ApiMarket, IMarket } from "../type";
import MarketFactory from "./MarketFactory";

const loadMarkets = async (sportId: string): Promise<ApiResponse<IMarket[]>> => {
    const response = await API.get<ApiMarket[]>(`/sports/${sportId}/markets`);

    if (response.successful) {
        return createSuccess(
            response.data.map((apiMarket) => MarketFactory.fromApi(apiMarket)),
        );
    }

    return fromUnsuccessfulResponse(response, `Failed to get markets for sport with id ${sportId}`);
};

export default loadMarkets;
