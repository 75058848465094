import API from "../../../api";
import { ApiResponse, createSuccess, fromUnsuccessfulResponse } from "../../../api/ApiResponse";
import { IBet, IApiBet } from "../types";
import BetFactory from "./BetFactory";

const loadBets = async (eventId: string): Promise<ApiResponse<IBet[]>> => {
    const response = await API.get<IApiBet[]>(`/event/${eventId}/bets`);

    if (response.successful) {
        return createSuccess(
            response.data.map((apiBet) => BetFactory.fromApi(apiBet)),
        );
    }

    return fromUnsuccessfulResponse(response, "Failed to get bets");
};

export default loadBets;
