import { useEffect, useState } from "react";
import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import GoHome from "../routing/components/redirects/GoHome";
import GotoLogin from "../routing/components/redirects/GotoLogin";
import Spinner from "../spinner/Spinner";
import CompleteLoginService from "./CompleteLoginService";

export type CompleteLoginProps = RouteComponentProps<{}>;

const CompleteLogin: React.FC<CompleteLoginProps> = (props) => {
    const [completeResult, setCompleteResult] = useState<boolean>();

    useEffect(() => {
        if (props.location.search) {
            const promise = CompleteLoginService.completeLogin(props.location.search.substring(1));
            promise.then(setCompleteResult);
        }
    }, [props.location.search]);

    if (completeResult === true || !props.location.search) {
        return <GoHome />;
    }

    if (completeResult === false) {
        return <GotoLogin redirectNotificationType="verify" />;
    }

    return (
        <div>
            <span>Completing login, please wait…</span>
            <Spinner dark={true} />
        </div>
    );
};

export default CompleteLogin;
