import * as React from "react";
import Overlay from "../../overlay/Overlay";
import useBurgerMenuOpen from "../hooks/useBurgerMenuOpen";
import BurgerMenu from "./internal/BurgerMenu";
import BurgerManager from "../BurgerManager";
import styles from "./BurgerMenuContainer.module.scss";
import { CSSTransition } from "react-transition-group";

const closeMenu = () => BurgerManager.closeMenu();

const BurgerMenuContainer: React.FC = () => {
    const isOpen = useBurgerMenuOpen();

    return (
        <>
            <CSSTransition
                in={isOpen}
                unmountOnExit={true}
                mountOnEnter={true}
                appear={isOpen}
                timeout={90}
                classNames={{
                    enter: styles.animEnter,
                    enterActive: styles.animEnterActive,
                    exit: styles.animExit,
                    exitActive: styles.animExitActive,
                    }}
            >
                <Overlay open={isOpen} onClick={closeMenu}>
                    <BurgerMenu />
                </Overlay>
            </CSSTransition>
        </>
    );
};

export default BurgerMenuContainer;
