import { Dispatch, SetStateAction } from "react";
import ReactHooks from "../react/ReactHooks";
import Once from "../lifecycle/Once";

type OnInputChangeEvent = (e: React.ChangeEvent<HTMLSelectElement>) => void;

export type UseSelectResult<DataType> = [DataType, OnInputChangeEvent, Dispatch<SetStateAction<DataType>>];

const useSelect = <DataType extends string>(initialState: DataType): UseSelectResult<DataType> => {
    const [value, setter] = ReactHooks.useState<DataType>(initialState);

    const onChange: OnInputChangeEvent = Once.useCallbackOnce(({target}) => {
        setter(target.value as DataType);
    });

    return [value, onChange, setter];
};

export default useSelect;
