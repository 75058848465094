import DateProvider from "../../../../date/DateProvider";
import DateFactory from "../../../../date/DateFactory";
import ensure2digits from "../../../../numbers/format/ensure2digits";

export interface IEventStartTimeSuggestion {
    date: string;
    time: string;
}

class EventDefaults {

    private static getDateComponent(date: Date): string {
        const hour = date.getHours();
        if (hour > 20 || (hour === 20 && date.getMinutes() > 0)) {
            const tomorrow = DateFactory.createDate(date);
            tomorrow.setDate(date.getDate() + 1);
            date = tomorrow;
        }

        return `${date.getFullYear()}-${ensure2digits(date.getMonth() + 1)}-${ensure2digits(date.getDate())}`;
    }

    private static getTimeComponent(date: Date): string {
        const hour = date.getHours();

        if (hour > 11 && hour < 20) {
            const minutes = date.getMinutes();

            return (minutes < 30) ?
                `${hour}:30` :
                `${hour + 1}:00`;
        }

        return (hour === 20 && date.getMinutes() === 0) ? "20:00" : "12:00";
    }

    public getSuggetedTimestamp(): IEventStartTimeSuggestion {
        const now = DateProvider.now();

        return {
            date: EventDefaults.getDateComponent(now),
            time: EventDefaults.getTimeComponent(now),
        };
    }
}

export default new EventDefaults();
