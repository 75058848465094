import { IMarket } from "../type";
import ISubscriptionListMap from "../../subscription/list/ISubscriptionListMap";
import SubscriptionListMapFactory from "../../subscription/list/SubscriptionListMapFactory";
import loadMarkets from "./loadMarkets";

export class MarketListCacheProviderImpl {
    private cached?: ISubscriptionListMap<IMarket, unknown>;

    public get(): ISubscriptionListMap<IMarket, unknown> {
        if (!this.cached) {
            this.cached = SubscriptionListMapFactory.create(
                (sportId: string) => () => loadMarkets(sportId),
            );
        }

        return this.cached;
    }
}

export default new MarketListCacheProviderImpl();
