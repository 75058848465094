import * as React from "react";
import styles from "./BurgerMenu.module.scss";
import CloseBurgerButton from "../close/CloseBurgerButton";
import Links from "./Links";

const eatClicks = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
};

const BurgerMenu: React.FC = () => {
    return (
        <div className={styles.burger} role="dialog" onClick={eatClicks}>
            <CloseBurgerButton className={styles.top} />
            <Links className={styles.links} />
        </div>
    );
};

export default BurgerMenu;
