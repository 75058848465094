import * as React from "react";
import styles from "./Card.module.scss";
import classnames from "classnames";

interface CardProps {
    children: React.ReactNode;
    className?: string;
    "data-testid"?: string;
}

const Card: React.FC<CardProps>= (props) => (
    <div
        data-testid={props["data-testid"]}
        className={classnames(
            styles.card,
            props.className,
        )}
    >
        {props.children}
    </div>
);

export default Card;
