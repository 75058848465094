import { ApiResponse } from "../../api/ApiResponse";
import ISubscriptionList from "./ISubscriptionList";
import SubscriptionList from "./SubscriptionList";

class SubscriptionListFactory {
    create<T, ID>(loadData: () => Promise<ApiResponse<T[]>>,
        comparator?: (a: T, b: T) => number,
        getId?: (item: T) => ID): ISubscriptionList<T, ID> {

        return new SubscriptionList(loadData, comparator, getId);
    }
}

export default new SubscriptionListFactory();
