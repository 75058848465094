import NumberFormatter from "../../../numbers/format/NumberFormatter";

class DecimalFormatter {
    private decimalFormatterOptions: Intl.NumberFormatOptions = Object.freeze({
        minimumFractionDigits: 1,
    });

    public format(value: number): string {
        return NumberFormatter.toLocaleString(value, undefined, this.decimalFormatterOptions);
    }
}

export default new DecimalFormatter();
