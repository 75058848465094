import { useEffect } from "react";
import ScreenSizeChangedObserverFactory from "../ScreenSizeChangedObserverFactory";

function useOnNolongerPhone(callback: () => void) {

    useEffect(() => {
        return ScreenSizeChangedObserverFactory.createListener((oldScreenType) => {
            if (oldScreenType === "phone") {
                callback();
            }
        });
    }, [callback]);
}

const exports = {
    useOnNolongerPhone,
};

export default exports;
