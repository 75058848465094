import { ApiResponse, isSuccess, createSuccess } from "../../api/ApiResponse";
import SubscriptionStoreFactory from "../store/SubscriptionStoreFactory";
import IStore from "../store/IStore";
import { isReady } from "../../fetch";
import ISubscriptionList from "./ISubscriptionList";
import withoutIndex from "../../array/withoutIndex";

type IdGetter<T, ID> = (item: T) => ID;

class SubscriptionList<T, ID> implements ISubscriptionList<T, ID> {
    private readonly store: IStore<T[]>;

    constructor(
        loadData: () => Promise<ApiResponse<T[]>>,
        private readonly comparator?: (a: T, b: T) => number,
        private readonly getId?: IdGetter<T, ID>,
    ) {
        this.store = SubscriptionStoreFactory.create(async () => {
            const response = await loadData();
            if (isSuccess(response)) {
                return createSuccess(comparator ? response.data.sort(comparator) : response.data);
            }

            return response;
        });
    }

    updateOne(id: ID, newValue: T): void {
        if (this.getId) {
            const existing = this.store.get();
            if (isReady(existing)) {
                const index = existing.findIndex((item: T) => (this.getId as IdGetter<T, ID>)(item) === id);
                if (index >= 0) {
                    const newArray = [...existing];
                    newArray[index] = newValue;
                    this.update(newArray);
                }
            }
        } else {
            throw new Error("updateOne called for list without identity function");
        }
    }

    public subscribe(callback: (data: T[]) => void): () => void {
        return this.store.subscribe(callback);
    }

    public update(data: T[]) {
        const unsortedNewData = [...data];
        this.store.update(this.comparator ? unsortedNewData.sort(this.comparator) : unsortedNewData);
    }

    public get()  {
        return this.store.get();
    }

    public insert(newItem: T) {
        const existing = this.store.get();
        if (isReady(existing)) {
            this.update([
                newItem,
                ...existing,
            ]);
        } else {
            throw new Error("insert called before data loaded");
        }
    }

    public find(predicate: (value: T, index: number, obj: T[]) => unknown): T | undefined {
        const existing = this.store.get();
        return isReady(existing) ?
            existing.find(predicate) :
            undefined;
    }

    public findWithId(id: ID): T | undefined {
        const getId = this.getId;
        if (getId !== undefined) {
            return this.find(value => getId(value) === id);
        }

        throw new Error("findWithId called on a list with no get id parameter");
    }

    public deleteOne(id: ID): void {
        if (this.getId) {
            const existing = this.store.get();
            if (isReady(existing)) {
                const index = existing.findIndex((item: T) => (this.getId as IdGetter<T, ID>)(item) === id);
                if (index >= 0) {
                    this.update(withoutIndex(existing, index));
                }
            }
        } else {
            throw new Error("deleteOne called for list without identity function");
        }
    }
}

export default SubscriptionList;
