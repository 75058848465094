import * as React from "react";
import CloseButton from "../CloseButton";
import Once from "../../lifecycle/Once";
import Overlay from "../Overlay";

import styles from "./ClosableOverlay.module.scss";

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

const ClosableOverlay: React.FC<Props> = ({children, isOpen, onClose}) => {
    const onClick = Once.useCallbackOnce(() => onClose());

    return (
        <Overlay open={isOpen} className={styles.closableOverlay}>
            <div className={styles.close}>
            {
                isOpen && <CloseButton
                    description="Close"
                    onClick={onClick} />
            }
            </div>
            <div className={styles.contents}>
            {
                children
            }
            </div>
        </Overlay>
    );
};

export default ClosableOverlay;
