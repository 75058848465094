import { useCallback } from "react";
import * as React from "react";
import MoneyInput from "../../../money/form/MoneyInput";
import FormCard from "../../../form/FormCard";
import { IAccount } from "../../../accounts/types";
import DescribeAdjustment from "./DescribeAdjustment";
import { IActionComponentProps } from "../../../layout/action/ActionComponent";
import UseScreenIs from "../../../screen/hooks/UseScreenIs";
import useInput from "../../../input/useInput";
import SubmitAdjustBalance from "./internal/SubmitAdjustBalance";

interface Props {
    account: IAccount;
}

const AdjustBalance: React.FC<IActionComponentProps<Props>> = ({account, onActionDone}) => {
    const [inputAmount, onInputAmountChange, setInputAmount] = useInput();
    const onSubmit = SubmitAdjustBalance.useSubmit(account, inputAmount);

    const onSuccess = useCallback(() => {
        onActionDone();
        setInputAmount("");
    }, [onActionDone, setInputAmount]);

    return (
        <FormCard
            legend="Adjust Balance"
            onSubmit={onSubmit}
            onSubmitSuccess={onSuccess}
            submitCaption="Adjust"
        >
            <label>
                Adjust by
                <MoneyInput
                    autoFocus={UseScreenIs.usePhone()}
                    data-testid="amount-input"
                    id="amount-input"
                    onChange={onInputAmountChange}
                    value={inputAmount}
                    required={true}
                />
            </label>

            <DescribeAdjustment
                adjustment={inputAmount}
                currentBalance={account.balance}
                currencyCode={account.currencyCode}
            />
        </FormCard>
    );
};

export default AdjustBalance;
