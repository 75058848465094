import * as React from "react";
import { Route, RouteProps } from "react-router-dom";
import AuthenticationService from "../../../authentication/AuthenticationService";
import GotoLogin from "../redirects/GotoLogin";

export interface ProtectedRouteProps extends RouteProps {
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = (props) => {
    if (AuthenticationService.isLoggedIn()) {
        return <Route {...props} />;
    }
    return (
        <GotoLogin redirectNotificationType="unauthenticated" />
    );
};

export default ProtectedRoute;
