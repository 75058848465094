import * as React from "react";
import { IOdds } from "../types";
import DecimalFormatter from "./internal/DecimalFormatter";

interface Props {
    odds: IOdds;
}

const Odds: React.FC<Props> = ({odds}) => {
    return (
        <span data-testid="odds">
            {odds.decimal !== null ? DecimalFormatter.format(odds.decimal): null}
            {odds.fractional !== null ? odds.fractional: null}
        </span>
    );
};

export default Odds;
