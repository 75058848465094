import { useCallback, useState } from "react";
import { IQuickActionConfiguration } from "../types";
import QuickAction from "./internal/QuickAction";
import ConfirmBox from "../../confirm/ConfirmBox";
import Once from "../../lifecycle/Once";
import VerticalDots from "./internal/VerticalDots";
import UseObserver from "../../subscription/observer/UseObserver";
import InlineOrCardOverlay from "../../overlay/dynamic/InlineOrCardOverlay";

import styles from "./QuickActions.module.scss";

interface Props<IdType extends string> {
    configurations: IQuickActionConfiguration<IdType>[];
    onConfirm(id: IdType): void;
    inlineEngaged: boolean;
}

function QuickActions<IdType extends string>({configurations, inlineEngaged, onConfirm}: Props<IdType>) {
    const [emitCloseOverlay, subscribeToCloseOverlay] = UseObserver.useObserver<void>();
    const [confirmingId, setConfirmingId] = useState<IdType>();
    const onDismissClicked = Once.useCallbackOnce(() => setConfirmingId(undefined));

    const onConfirmClicked = useCallback(() => {
        onConfirm(confirmingId as IdType); // Type can be undefined, but not if it reaches this callback
        setConfirmingId(undefined);
    }, [onConfirm, confirmingId]);

    const onSelectAction = useCallback((id: IdType) => {
        if (findConfirmation(configurations, id)) {
            setConfirmingId(id);
        } else {
            onConfirm(id);
        }

        emitCloseOverlay();
    }, [configurations, emitCloseOverlay, onConfirm]);

    const appliedConfirmation = findConfirmation(configurations, confirmingId);

    return (
        <div className={styles.actions}>
            <InlineOrCardOverlay
                inlineEngaged={inlineEngaged}
                subscribeToCloseEvents={subscribeToCloseOverlay}
                phoneTriggerComponent={VerticalDots}
            >
                {
                    configurations.map((configuration) => (
                        <QuickAction key={configuration.id} configuration={configuration} onClick={onSelectAction} />
                    ))
                }
            </InlineOrCardOverlay>
            <ConfirmBox showConfig={appliedConfirmation} onNo={onDismissClicked} onYes={onConfirmClicked} />
        </div>
    );
}

function findConfirmation<IdType extends string>(configurations: IQuickActionConfiguration<IdType>[], withId?: IdType) {
    return withId ?
        configurations.find(({id}) => id === withId)?.confirmation :
        undefined;
}

export default QuickActions;
