import * as React from "react";
import Button from "../../button/Button";
import Card from "../../card/Card";

import styles from "./InternalMessageBox.module.scss";

export interface InternalMessageBoxProps {
    title: string;
    description: string;
    yesCaption: string;
    noCaption: string;
    onYes: () => void;
    onNo: () => void;
}

const InternalMessageBox: React.FC<InternalMessageBoxProps> = ({
    title,
    description,
    yesCaption,
    onYes,
    noCaption,
    onNo,
}) => {
    return (
        <Card className={styles.message_box} data-testid="message-box-card">
            <h2 data-testid="message-box-title">{title}</h2>
            <p data-testid="message-box-contents">
                {description}
            </p>
            <div className={styles.buttons}>
                <Button kind="secondary" data-testid="message-box-no" onClick={onNo}>
                    {noCaption}
                </Button>
                <Button data-testid="message-box-yes" onClick={onYes}>
                    {yesCaption}
                </Button>
            </div>
        </Card>
    );
};

export default InternalMessageBox;
