import Primary from "../../../../layout/page/Primary";
import EventSummaryCard, { EventSummaryCardProps } from "./EventSummaryCard";
import EventContentCard, { EventContentCardProps } from "./EventContentCard";

interface PrimaryPageProps extends EventContentCardProps, EventSummaryCardProps {
}

const PrimaryPage = ({
    accounts,
    betsFetch,
    event,
    markets,
    selectedBetForEditingState,
}: PrimaryPageProps) => (
    <Primary>
        <EventSummaryCard event={event} />
        <EventContentCard
            accounts={accounts}
            betsFetch={betsFetch}
            event={event}
            markets={markets}
            selectedBetForEditingState={selectedBetForEditingState}
        />
    </Primary>
);

export default PrimaryPage;
