import { Fetch } from "../../fetch";
import TransactionService from "../TransactionService";
import NeverChange from "../../lifecycle/NeverChange";
import { ITransaction, SinkType } from "../types";
import UseFetchAndSubscribe from "../../fetch/hooks/UseFetchAndSubscribe";

function useTransactions(type: SinkType, canonicalId: string): Fetch<ITransaction[]> {
    NeverChange.useNeverChange(canonicalId);
    NeverChange.useNeverChange(type);

    return UseFetchAndSubscribe.useFetchAndSubscribe(
        () => TransactionService.getTransactions(type, canonicalId),
        (cb) => TransactionService.subscribe(type, canonicalId, cb),
    );
}

const exports = {
    useTransactions,
};

export default exports;
