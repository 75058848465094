import WindowResizeObserverFactory from "../window/WindowResizeObserverFactory";
import WindowProvider from "../window/WindowProvider";
import ScreenSizes from "../screen/ScreenSizes";
import HistoryProvider from "../history/HistoryProvider";
import OverlayService from "../overlay/OverlayService";

type Subscription = (isOpen: boolean) => void;

export class BurgerManagerImpl {
    private openState = false;
    private readonly subscriptions = new Set<Subscription>();

    constructor() {
        WindowResizeObserverFactory.createListener(() => {
            if (WindowProvider.getWindow().innerWidth >= ScreenSizes.getDesktopMinimumWidth()) {
                this.closeMenu();
            }
        });

        HistoryProvider.getHistory()
            .listen(() => {
                this.closeMenu();
            });
    }

    public isOpen(): boolean {
        return this.openState;
    }

    public openMenu() {
        this.performChange(true);
    }

    public closeMenu() {
        this.performChange(false);
    }

    public subscribe(callback: Subscription) {
        this.subscriptions.add(callback);

        return () => {
            this.subscriptions.delete(callback);
        };
    }

    private performChange(isOpen: boolean) {
        if (isOpen !== this.openState) {
            this.openState = isOpen;

            if (isOpen) {
                OverlayService.notifyOpen("☰");
            } else {
                OverlayService.notifyClosed("☰");
            }

            this.fireSubscriptions();
        }
    }

    private fireSubscriptions() {
        const isOpen = this.openState;
        this.subscriptions.forEach((subscription) => {
            subscription(isOpen);
        });
    }
}

export default new BurgerManagerImpl();
