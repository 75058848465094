import * as React from "react";
import DateFormatter from "../DateFormatter";

interface Props {
    date: Date;
    options?: Intl.DateTimeFormatOptions;
}

type CombinedProps = Props & React.HTMLAttributes<HTMLSpanElement>;

const LocaleDateString: React.FC<CombinedProps> = ({date, options, ...rest}) => {
    const formatted = DateFormatter.toLocaleString(date, options);
    return <span {...rest}>{formatted}</span>;
};

export default LocaleDateString;
