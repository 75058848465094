import { IBet } from "../service/types";
import { Selection } from "../service/types";

const isOpen = ({state}: IBet) => state === "pending";

const useOpen = (bets: IBet[]): IBet[] => bets.filter(isOpen);

const useSelections = (bets: IBet[]): Selection[] => {
    const output = new Map();

    bets.forEach(({selection}) => output.set(`${selection.marketId}⛓${selection.resultId}`, selection));

    return Array.from(output.values());
};

const WithBets = {
    useOpen,
    useSelections,
};

export default WithBets;
