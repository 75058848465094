import { IAccount } from "../../../../accounts/types";

interface AccountNameProps {
    account?: IAccount;
    className?: string;
}

const AccountName = ({
    account,
    ...props
}: AccountNameProps) => (
    <span {...props} data-testid="staked-at-account">
        {account ? account.name : "Unknown account"}
    </span>
);

export default AccountName;
