import { IQuickActionConfiguration, QuickActionState } from "../../../../quick-actions/types";
import QuickActionConfigurationFactory from "../../../../quick-actions/QuickActionConfigurationFactory";
import { BetRowAction } from "../types";
import BetService from "../../../service/BetService";
import { BetState } from "../../../service/types";
import { SelectBetForEditing } from "../../../hooks/WithEditingBet";

const Base: IQuickActionConfiguration<BetRowAction>[] = [
    QuickActionConfigurationFactory.createConfiguration(
        "delete", QuickActionState.ACTIVE, "Delete bet", "bin", "Are you sure you want to delete this bet?"),
    QuickActionConfigurationFactory.createConfiguration(
        "cancel", QuickActionState.ACTIVE, "Cancel bet", "cross", "Are you sure you want to cancel this bet?"),
    QuickActionConfigurationFactory.createConfiguration(
        "edit", QuickActionState.ACTIVE, "Edit bet", "pencil"),
];

type OnAction = (id: BetRowAction) => void;

class RowActions {
    public getActions(isCancelling: boolean, isDeleting: boolean, betState: BetState): IQuickActionConfiguration<BetRowAction>[] {
        const isBusy = isCancelling || isDeleting;

        return isBusy ? [{
                ...Base[0], // Delete
                state: isDeleting ? QuickActionState.LOADING : QuickActionState.INACTIVE,
            },{
                ...Base[1], // Cancel
                state: isCancelling ? QuickActionState.LOADING : QuickActionState.INACTIVE,
            },{
                ...Base[2], // Edit
                state: QuickActionState.INACTIVE,
            },
        ] : betState === "pending" ? Base : [{
                ...Base[0], // Delete
                state: betState === "cancelled" ? QuickActionState.ACTIVE : QuickActionState.INACTIVE,
            },{
                ...Base[1], // Cancel
                state: QuickActionState.INACTIVE,
            },{
                ...Base[2], // Edit
                state: QuickActionState.INACTIVE,
            },
        ];
    }

    public getOnAction(eventId: string, betId: string, selectBetForEditing: SelectBetForEditing): OnAction {
        return (id) => {
            switch (id) {
                case "cancel":
                    BetService.cancelBet(eventId, betId);
                    break;
                case "delete":
                    BetService.deleteBet(eventId, betId);
                    break;
                case "edit":
                    selectBetForEditing(betId);
                    break;
                default:
                    throw new Error("Unknown bet row action: " + id);
            }
        };
    }
}

export default new RowActions();
