import { IOdds } from "./types";

class OddsService {
    public asString(odds: IOdds): string {
        if (odds.decimal !== null) {
            return `${odds.decimal}`;
        }

        if (odds.fractional !== null) {
            return odds.fractional;
        }

        throw new Error("Cannot stringify, neither decimal or fractional values are present");
    }
}

export default new OddsService();
