import { IConfirmPrompt } from "./types";

class ConfirmPromptFactory {
    public createPrompt(
        title: string,
        body: string,
        confirmCaption: string = "Yes",
        cancelCaption: string = "No",
    ): IConfirmPrompt {

        return {
            body,
            cancelCaption,
            confirmCaption,
            title,
        };
    }
}

export default new ConfirmPromptFactory();
