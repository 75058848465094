import { useParams } from "react-router-dom";

function useSafeParam(paramKey: string): string {
    const params: {[key: string]: string | undefined} = useParams();
    const value = params[paramKey];

    return (value === undefined) ? "" : value;
}


const exports = {
    useSafeParam,
};

export default exports;
