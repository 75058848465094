import { IAccount } from "../../../../accounts/types";
import BetList from "../../../../bets/components/list/BetList";
import { SelectedBetForEditingState } from "../../../../bets/hooks/WithEditingBet";
import { IBet } from "../../../../bets/service/types";
import Card from "../../../../card/Card";
import { IEvent } from "../../../types";
import { Fetch } from "../../../../fetch";
import FetchGuard from "../../../../fetch/FetchGuard";
import { IMarket } from "../../../../markets/type";

export interface EventContentCardProps {
    accounts: IAccount[];
    betsFetch: Fetch<IBet[]>;
    event: IEvent;
    markets: IMarket[];
    selectedBetForEditingState: SelectedBetForEditingState;
}

const EventContentCard = ({
    accounts,
    betsFetch,
    event,
    markets,
    selectedBetForEditingState,
}: EventContentCardProps) => {

    return (
        <Card>
            <h2>Bets</h2>
            <FetchGuard
                additionalData={{
                    accounts,
                    markets,
                    eventId: event.id,
                    selectedBetForEditingState,
                }}
                fetch={betsFetch}
                component={BetList}
                loadingMessage="Retrieving bets…"
            />
        </Card>
    );
};

export default EventContentCard;
