import { ITransaction } from "../../types";
import CanonicalNameService from "../../../accounts/canon/CanonicalNameService";

const transactionKey = (transaction: ITransaction): string => {
    const canonicalDestination = CanonicalNameService.getCanonicalName(transaction.destination.name);

    return `${transaction.time.getTime()}-${transaction.amount}-${canonicalDestination}`;
};

export default transactionKey;
