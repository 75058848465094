import { useCallback } from "react";
import * as React from "react";
import FormCard from "../../../form/FormCard";
import useInput from "../../../input/useInput";
import MoneyInput from "../../../money/form/MoneyInput";
import { IActionComponentProps } from "../../../layout/action/ActionComponent";
import UseScreenIs from "../../../screen/hooks/UseScreenIs";
import { AccountType } from "../../types";
import { capitaliseFirstLetter } from "../../../text/StringUtilities";
import SubmitAddAccount from "../../hooks/SubmitAddAccount";

interface Props {
    accountType: AccountType;
}

const AddAccount: React.FC<IActionComponentProps<Props>> = ({accountType, onActionDone}) => {
    const [inputName, onInputNameChange, setInputName] = useInput();
    const [inputBalance, onInputBalanceChange, setInputBalance] = useInput();
    const onSubmit = SubmitAddAccount.useSubmit(accountType, inputName, inputBalance);

    const onSuccess = useCallback(() => {
        onActionDone();
        setInputName("");
        setInputBalance("");
    }, [onActionDone, setInputName, setInputBalance]);

    return (
        <FormCard
            onSubmit={onSubmit}
            onSubmitSuccess={onSuccess}
            legend={`Add a new ${accountType} account`}
            submitCaption={`Add ${capitaliseFirstLetter(accountType)}`}
        >
            <label>Name<input
                data-testid="add-account-name-input"
                autoFocus={UseScreenIs.usePhone()}
                onChange={onInputNameChange}
                placeholder="Account name"
                required={true}
                type="text"
                value={inputName}
            /></label>

            <label>Balance<MoneyInput
                onChange={onInputBalanceChange}
                placeholder="Current balance"
                required={true}
                value={inputBalance}
            /></label>
        </FormCard>
    );
};

export default AddAccount;
