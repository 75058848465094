import * as React from "react";
import { ITransaction } from "../../../transactions/types";
import { Fetch } from "../../../fetch";
import FetchGuard from "../../../fetch/FetchGuard";
import Card from "../../../card/Card";
import Action from "../../../layout/action/Action";
import Actions from "../../../layout/action/Actions";
import AdjustBalance from "../../../transactions/components/adjust/AdjustBalance";
import Page from "../../../layout/page/Page";
import Primary from "../../../layout/page/Primary";
import Secondary from "../../../layout/page/Secondary";
import Summary from "./Summary";
import TransactionsList from "../../../transactions/components/list/TransactionsList";
import TransferFunds from "../../../transactions/components/transfer/TransferFunds";
import styles from "./AccountScreen.module.scss";
import { AccountType, IAccount, AccountLike } from "../../types";

export interface AccountScreenAdditionalData {
    accountType: AccountType;
    accountsFetch: Fetch<IAccount[]>;
    transactionsFetch: Fetch<ITransaction[]>;
}

interface Props extends AccountScreenAdditionalData {
    data: AccountLike;
}

const AccountScreen: React.FC<Props> = (props) => {
    const account: IAccount = {
        ...props.data,
        type: props.accountType,
    };

    return (
        <Page>
            <Primary>
                <Summary data={account} />
                <Card className={styles.transactionsCard}>
                    <h2>Transactions</h2>
                    <FetchGuard
                        fetch={props.transactionsFetch}
                        component={TransactionsList}
                        loadingMessage="Retrieving transactions…"
                        additionalData={account}
                    />
                </Card>
            </Primary>
            <Secondary>
                <Actions>
                    <Action
                        caption="Transfer Money"
                        component={TransferFunds}
                        accountsFetch={props.accountsFetch}
                        initiator={account}
                    />
                    <Action
                        caption="Adjust Balance"
                        component={AdjustBalance}
                        account={account}
                    />
                </Actions>
            </Secondary>
        </Page>
    );
};

export default AccountScreen;
