import * as React from "react";
import { ISport } from "../../Sport";

interface Props extends React.SelectHTMLAttributes<HTMLSelectElement> {
    sports: ISport[];
}

const SelectSport: React.FC<Props> = ({sports, ...props}) => {

    return (
        <select {...props}>
            {sports.map(sport => (
                <option
                    key={sport.id}
                    value={sport.id}
                >
                    {sport.name}
                </option>
            ))}
        </select>
    );
};


export default SelectSport;
