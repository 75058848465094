import * as React from "react";
import AccountOption from "./AccountOption";
import AccountService from "../../../AccountService";
import { IAccount } from "../../../types";

const AccountOptions: React.FC<{accounts: IAccount[]}> = ({accounts}) => {
    return (
        <>
            {
                accounts.map((account => <AccountOption key={AccountService.createKey(account)} account={account} />))
            }
        </>
    );
};

export default AccountOptions;
