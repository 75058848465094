import * as React from "react";
import BurgerMenuContainer from "./burger/menu/BurgerMenuContainer";
import Desktop from "./screen/Desktop";
import HistoryProvider from "./history/HistoryProvider";
import IfShowSidebar from "./routing/components/conditional/IfShowSidebar";
import Masthead from "./masthead/Masthead";
import RouteSwitcher from "./routing/components/RouteSwitcher";
import SideBar from "./sidebar/SideBar";
import styles from "./App.module.scss";
import { Router } from "react-router-dom";
import UseOverlayIsOpen from "./overlay/hooks/UseOverlayIsOpen";
import classnames from "classnames";

const App: React.FC = () => {
    const overlayShowing = UseOverlayIsOpen.useOverlayIsOpen();

    return (
        <div className={classnames(styles.root, {
            [`${styles.overlayOpen}`]: overlayShowing,
        })}>
            <Router history={HistoryProvider.getHistory()}>
                <>
                    <header>
                        <Masthead />
                    </header>
                    <div className={styles.app}>
                        <Desktop>
                            <IfShowSidebar>
                                <SideBar className={styles.sidebar}/>
                            </IfShowSidebar>
                        </Desktop>
                        <div className={styles.content}>
                            <RouteSwitcher />
                        </div>
                    </div>
                    <BurgerMenuContainer />
                </>
            </Router>
        </div>
    );
};

export default App;
