import * as React from "react";
import classnames from "classnames";
import styles from "./Page.module.scss";

type PageLayoutMode = "master-detail" | "dead-center";

interface Props {
    children: React.ReactNode;
    className?: string;
    mode?: PageLayoutMode;
}

const modeToClass = (mode?: PageLayoutMode) => {
    switch (mode) {
        case "dead-center":
            return styles.dead_center;
    }
    return styles.master_detail;
};

const Page: React.FC<Props> = ({children, className, mode}) => (
    <div className={classnames(styles.page, modeToClass(mode), className)}>
        {children}
    </div>
);

export default Page;
