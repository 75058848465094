import { createDoneFetch, createErrorFetch, createLoadingFetch, Fetch, isDone, isError } from "..";

function useAll<T1, T2>(source1: Fetch<T1>, source2: Fetch<T2>): Fetch<[T1, T2]> {
    const inputs = [source1, source2];
    const outputPayload = [];

    for (const input of inputs) {
        if (isDone<unknown>(input)) {
            outputPayload.push(input.payload);
        } else if (isError<unknown>(input)) {
            return createErrorFetch(input.message);
        }
    }

    if (outputPayload.length !== inputs.length) {
        return createLoadingFetch();
    }

    return createDoneFetch<[T1, T2]>(outputPayload as any[] as [T1, T2]);
}

const exports = {
    useAll,
};

export default exports;
