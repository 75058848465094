import * as React from "react";
import FetchGuard from "../../fetch/FetchGuard";
import GotoEvents from "../../routing/components/redirects/GotoEvents";
import RouteParams from "../../routing/hooks/RouteParams";
import { ISport } from "../../sports/Sport";
import { IEvent } from "../types";
import EventPage from "./EventPage";
import UseEventsAndSports from "./internal/UseEventsAndSports";

import contentStyles from "../../layout/styles/content.module.scss";
import { IMarket } from "../../markets/type";
import UseMarkets from "../../markets/hooks/WithMarkets";
import useAccounts from "../../accounts/hooks/useAccounts";
import { Fetch } from "../../fetch";
import { IAccount } from "../../accounts/types";
import UseAll from "../../fetch/hooks/UseAll";

const PageFetchProps = Object.freeze({
    errorComponent: GotoEvents,
    loadingMessage: "Loading event, please wait…",
    loadingClassName: contentStyles.standardPadding,
});

interface CombineAllProps {
    event: IEvent;
    sports: ISport[];
    data: [IMarket[], IAccount[]];
}

const CombineAll: React.FC<CombineAllProps> = ({data, event, sports}) => (
    <EventPage
        event={event}
        sports={sports}
        markets={data[0]}
        accounts={data[1]}
    />
);

interface LoadMarketsProps {
    data: [IEvent, ISport[]];
    accountsFetch: Fetch<IAccount[]>;
}

const LoadMarkets: React.FC<LoadMarketsProps> = ({data, accountsFetch}) => {
    const event: IEvent = data[0];
    const sports: ISport[] = data[1];
    const fetch = UseAll.useAll(UseMarkets.useMarketsForSport(event.sportId), accountsFetch);

    return (
        <FetchGuard
            additionalData={{
                event,
                sports,
            }}
            component={CombineAll}
            fetch={fetch}
            {...PageFetchProps}
        />
    );
};

const EventRoute: React.FC = () => {
    const id = RouteParams.useSafeParam("id");
    const fetch = UseEventsAndSports.useEventAndSports(id);
    const accountsFetch = useAccounts();

    return (
        <FetchGuard
            additionalData={{
                accountsFetch,
            }}
            component={LoadMarkets}
            fetch={fetch}
            {...PageFetchProps}
        />
    );
};

export default EventRoute;
