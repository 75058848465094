import AccountService from "../../../accounts/AccountService";
import OddsFactory from "../../../odds/OddsFactory";
import { AddBet, AddBetApi, BetCreationMeta, BetState, BetStateApi, EditBet, EditBetApi, SettleType, SettleTypeApi } from "../types";

class ApiMapper {
    private static getCommonMeta(source: BetCreationMeta) {
        const identifiableAccount = AccountService.fromKey(source.accountValue);

        return {
            stake: Number(source.stake),
            type: source.betType,
            accountName: identifiableAccount.name,
            accountType: AccountService.toApiAccountOrdinal(identifiableAccount.type),
            odds: OddsFactory.createOdds(source.odds),
            selection: {
                marketId: source.marketId,
                resultId: source.result,
            },
        };
    }

    public toAddBetApi(addBet: AddBet): AddBetApi {
        return {
            ...ApiMapper.getCommonMeta(addBet),
            eventId: addBet.eventId,
        };
    }

    public toEditBetApi(editBet: EditBet): EditBetApi {
        return ApiMapper.getCommonMeta(editBet);
    }

    public fromApiBetState(source: BetStateApi): BetState {
        if (source === "P")
            return "pending";
        return source === "S" ? "settled" : "cancelled";
    }

    public fromApiSettleType(source: SettleTypeApi): SettleType {
        return source === "w" ? "win" : "lose";
    }
}

export default new ApiMapper();
