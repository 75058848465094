import AccountService from "../../accounts/AccountService";
import { IAccount } from "../../accounts/types";
import { IBet } from "../../bets/service/types";
import { IEvent } from "../../events/types";
import MarketService from "../../markets/MarketService";
import { IMarket } from "../../markets/type";
import OddsService from "../../odds/OddsService";
import ReactHooks from "../../react/ReactHooks";
import { IUsesAccount, IUsesBetType, IUsesCurrentMarket, IUsesResultInput, IUsesMarketId, IUsesOdds, IUsesStake } from "./components/labelled-inputs/types";
import AccountInputHook from "./store/AccountInputHook";
import BetTypeInputHook from "./store/BetTypeInputHook";
import CurrentMarket from "./store/CurrentMarket";
import MarketIdInputHook from "./store/MarketIdInputHook";
import OddsInputHook from "./store/OddsInputHook";
import ResultInputHook from "./store/ResultInputHook";
import StakeInputHook from "./store/StakeInputHook";
import WithSubmitBetSlip from "./WithSubmitBetSlip";

export interface BetSlipData extends IUsesAccount, IUsesBetType, IUsesCurrentMarket,
    IUsesResultInput, IUsesMarketId, IUsesOdds, IUsesStake {

}

function useData(accounts: [IAccount, ...IAccount[]], markets: IMarket[], editingBet?: IBet): BetSlipData {
    const marketIdSelectResult = MarketIdInputHook.useMarketIdInput(markets, editingBet?.selection.marketId);
    const currentMarket: IMarket = CurrentMarket.useCurrentMarket(markets, marketIdSelectResult[0]);

    const data: BetSlipData = {
        account: AccountInputHook.useAccountInput(accounts, editingBet?.account),
        betType: BetTypeInputHook.useBetTypeInput(editingBet?.type),
        currentMarket,
        marketId: marketIdSelectResult,
        odds: OddsInputHook.useOddsInput(editingBet?.odds),
        result: ResultInputHook.useResultInput(currentMarket.results, editingBet?.selection.resultId),
        stake: StakeInputHook.useStakeInput(editingBet?.stake),
    };

    ReactHooks.useEffect(() => {
        if (editingBet) {
            data.betType[2](editingBet.type);
            data.odds[2](OddsService.asString(editingBet.odds));
            data.stake[2](`${editingBet.stake}`);

            const accountForEditingBet = AccountService.getAccountForWagerableAccount(accounts, editingBet.account);
            data.account[1](AccountService.createKey(accountForEditingBet));

            const market = MarketService.findMarket(editingBet.selection.marketId, markets);
            data.marketId[2](market.id);
            data.result[1](editingBet.selection.resultId);
        } else {
            data.stake[2]("");
            data.odds[2]("");
        }
    }, [editingBet]);

    return data;
}

function useSubmit(data: BetSlipData, event: IEvent, editingBet?: IBet) {
    const [marketValue] = data.marketId;
    const [resultValue] = data.result;
    const [oddsValue, , setOddsValue] = data.odds;
    const [stakeValue, , setStakeValue] = data.stake;
    const [accountValue] = data.account;
    const [betTypeValue] = data.betType;

    const onSuccess = ReactHooks.useCallback(() => {
        setOddsValue("");
        setStakeValue("");
    }, [setStakeValue, setOddsValue]);

    const onSubmit = WithSubmitBetSlip
        .useSubmit(event.id, betTypeValue, stakeValue, oddsValue, marketValue,
            resultValue, accountValue, editingBet, onSuccess);

    return onSubmit;
}

const exports = {
    useData,
    useSubmit,
};

export default exports;
