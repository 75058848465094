import ISubscriptionList from "../subscription/list/ISubscriptionList";
import { AccountLikeService, AccountType, AccountLike } from "../accounts/types";
import AccountLikeServiceBase from "../accounts/generic/AccountLikeServiceBase";
import AccountsListStoreFactory from "../accounts/generic/AccountsListStoreFactory";

export interface IExchange extends AccountLike {
}

export class ExchangeServiceImpl extends AccountLikeServiceBase<IExchange> implements AccountLikeService<IExchange> {
    protected readonly accountType: AccountType = "exchange";
    protected readonly store: ISubscriptionList<IExchange, string> = AccountsListStoreFactory.create("exchange");
}

export default new ExchangeServiceImpl();
