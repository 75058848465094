function once<T>(calculate: () => T): () => T {
    let hasResult = false;
    let result: T;

    return () => {
        if (!hasResult) {
            hasResult = true;
            result = calculate();
        }

        return result;
    };
}

export default once;
