import ISubscriptionListMap from "../../subscription/list/ISubscriptionListMap";
import { ITransaction } from "../types";
import SubscriptionListMapFactory from "../../subscription/list/SubscriptionListMapFactory";
import loadTransactions from "./loadTransactions";
import transactionComparator from "../comparators/transactionComparator";

export class TransactionsListCacheProviderImpl {
    private cached?: ISubscriptionListMap<ITransaction, unknown>;

    public get(): ISubscriptionListMap<ITransaction, unknown> {
        if (!this.cached) {
            this.cached = SubscriptionListMapFactory.create(
                (key: string) => () => loadTransactions(key),
                transactionComparator,
            );
        }

        return this.cached;
    }
}

export default new TransactionsListCacheProviderImpl();
