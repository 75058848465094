import * as React from "react";
import { Link } from "react-router-dom";
import Envelope from "./envelope.svg";

import Styles from "./CheckMail.module.scss";
import Page from "../layout/page/Page";
import Primary from "../layout/page/Primary";
import Card from "../card/Card";

const CheckMail: React.FC = () => (
    <Page mode="dead-center">
        <Primary>
            <Card>
                <div className={Styles.container} >
                    <img src={Envelope} alt="" width="200" height="144" />
                    <h1>Check your email</h1>
                    <p>
                        We have sent an email to your registered address. Click the link in the mail to complete the login process.
                    </p>
                    <p>
                        This link is valid for <strong>15</strong> minutes, if it expires you can <Link to="/login">login</Link> again.
                    </p>
                    <p>
                        You can close this page.
                    </p>
                </div>
            </Card>
        </Primary>
    </Page>
);

export default CheckMail;
