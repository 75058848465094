import * as React from "react";
import { IMarket } from "../../type";
import Grouper from "./internal/Grouper";

interface Props extends React.ComponentPropsWithoutRef<'select'> {
    markets: IMarket[];
}

const SelectMarket: React.FC<Props> = ({markets, ...props}) => {
    const groups = Grouper.groupCatagories(markets);

    return (
        <select {...props}>
            {
                groups.map(group => (
                    <optgroup label={group.name} key={group.name}>
                        {
                            group.markets.map((market) => (
                                <option value={market.id} key={market.id}>
                                    {market.name}
                                </option>
                            ))
                        }
                    </optgroup>
                ))
            }
        </select>
    );
};

export default SelectMarket;
