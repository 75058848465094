class ErrorAdaptor {
    public adaptError(caught: any): string {
        if (caught) {
            const caughtType = typeof caught;

            if (caughtType === "string") {
                return caught;
            }

            if (caught instanceof Error) {
                return caught.name + ": " + caught.message;
            }

            if (caughtType === "object") {
                if (typeof caught.message === "string") {
                    return caught.message;
                }
            }
        }
        return "Failed with an unknown error";
    }
}

export default new ErrorAdaptor();
