import * as React from "react";
import classnames from "classnames";
import styles from "./Page.module.scss";

interface Props {
    children: React.ReactNode;
    className?: string;
}

const Secondary: React.FC<Props> = ({children, className}) => (
    <div className={classnames(styles.secondary, className)}>
        {children}
    </div>
);

export default Secondary;
