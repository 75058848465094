import axios, { AxiosInstance } from "axios";
import bearerInterceptor from "./interceptors/BearerToken";

class AxiosProvider {
    private instance?: AxiosInstance;

    private createInstance = () => {
        const axiosInstance = axios.create({
            baseURL: process.env.REACT_APP_API_PATH,
        });

        axiosInstance.interceptors.request.use(bearerInterceptor);

        return axiosInstance;
    }

    public getInstance(): AxiosInstance {
        if (!this.instance) {
            this.instance = this.createInstance();
        }

        return this.instance;
    }
}

export default new AxiosProvider();
