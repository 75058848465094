const canonicalAccountName = (source: string) => {
    return source
        .replace(/-/g, " ")
        .replace(/\s+/g, " ")
        .trim()
        .toLowerCase()
        .split(" ")
        .join("-");
};

export default canonicalAccountName;
