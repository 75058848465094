import { useMemo, useRef } from "react";
import useSet from "./useSet";
import ISet from "./ISet";
import TimeoutService from "../timeout/TimeoutService";
import Once from "../lifecycle/Once";
import MountHooks from "../lifecycle/mount/hooks";

function useTtlSet<T>(timeToLive: number): ISet<T> {
    const internalSet = useSet<T>();

    const activeTimerIds = useRef(new Map<T, number>());

    MountHooks.useOnUnmount(() => {
        activeTimerIds.current.forEach(timerId => TimeoutService.cancel(timerId));
    });

    const add = Once.useCallbackOnce((value: T) => {
        internalSet.add(value);

        const timerId = TimeoutService.defer(() => {
            activeTimerIds.current.delete(value);
            internalSet.remove(value);
        }, timeToLive);

        if (activeTimerIds.current.has(value)) {
            TimeoutService.cancel(activeTimerIds.current.get(value));
        }

        activeTimerIds.current.set(value, timerId);
    });

    const remove = Once.useCallbackOnce((value: T) => {
        TimeoutService.cancel(activeTimerIds.current.get(value));
        internalSet.remove(value);
    });

    return useMemo(() => ({
        ...internalSet,
        add,
        remove,
    }), [add, remove, internalSet]);
}

export default useTtlSet;
