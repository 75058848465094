import * as React from "react";
import FeedbackPane from "./FeedbackPane";
import styles from "./RequestForm.module.scss";

export interface Props {
    children: React.ReactNode;
    disabled: boolean;
    legend: string;
    errorMessage?: string;
    successMessage?: string;
}

const DumbFieldset: React.FC<Props> = ({children, disabled, errorMessage, legend, successMessage}) => (
    <fieldset className={styles.formCardFieldset} data-testid="request-form-fieldset" disabled={disabled}>
        <legend className={styles.legend} data-testid="legend">{legend}</legend>

        <div className={styles.widgets}>
            <FeedbackPane successMessage={successMessage} errorMessage={errorMessage} />
            {children}
        </div>
    </fieldset>
);

export default DumbFieldset;
