import Row from "../../card/Row";
import styles from "./ExpandingRow.module.scss";
import useOnClick from "../hooks/useOnClick";
import classNames from "classnames";
import { IWrapperRowProps } from "../types";
import OverlayAware from "../../overlay/hooks/mouse/OverlayAware";

function ExpandingRow<OriginalDataType extends object, AdditionalDataType extends object>(
    props: IWrapperRowProps<OriginalDataType, AdditionalDataType>,
) {
    const onClick = useOnClick(props.data, props.onClick);
    const [isEntered, onMouseEnter, onMouseLeave] = OverlayAware.useMouseEnteredFunctions();

    if (props.isNew) {
        return (
            <Row void={true} className={classNames(
                styles.expandingVoid,
            )}>
                <Row
                    alternative={props.alternative}
                    className={classNames(styles.scalingContents, props.className)}
                    data-testid="expanding-row"
                >
                    <props.containedComponent data={props.data} additional={props.additionalData} />
                </Row>
            </Row>
        );
    }

    return (
        <Row
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            alternative={props.alternative}
            className={props.className}
            data-testid="expanding-row"
            onClick={onClick}>

            <props.containedComponent data={props.data} additional={props.additionalData} mouseEntered={isEntered} />
        </Row>
    );
}

export default ExpandingRow;
