import OverlayService from "../OverlayService";
import Once from "../../lifecycle/Once";
import { useState } from "react";

function useOverlayIsOpen() {
    const [isOpen, setIsOpen] = useState(() => OverlayService.getIsOpen());
    Once.useEffectOnce(() => OverlayService.subscribe(() => setIsOpen(OverlayService.getIsOpen())));
    return isOpen;
}

const exports = {
    useOverlayIsOpen,
};

export default exports;
