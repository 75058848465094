import WithBets from "../../../bets/hooks/WithBets";
import { IBet } from "../../../bets/service/types";
import { IRequiredVariable } from "../../../markets/criteria/types";
import WithMarkets from "../../../markets/hooks/WithMarkets";
import WithResults from "../../../markets/hooks/WithResults";
import { IMarket } from "../../../markets/type";

const useRequiredVariables = (markets: IMarket[], openBets: IBet[]): IRequiredVariable[] => {
    const betSelections = WithBets.useSelections(openBets);
    const results = WithMarkets.useResultsForSelections(markets, betSelections);
    return WithResults.useRequiredVariables(results);
};

const WithOpenBets = {
    useRequiredVariables,
};

export default WithOpenBets;
