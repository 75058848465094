import { ISport } from "./Sport";
import { IReadOnlyStore } from "../subscription/store/IStore";
import SportsStoreProvider from "./internal/SportsStoreProvider";
import { ApiResponse } from "../api/ApiResponse";

const FOOTBALL = "80";

export class SportsServiceImpl {
    private get store(): IReadOnlyStore<ISport[]> {
        return SportsStoreProvider.get();
    }

    public getSports(): ISport[] | Promise<ApiResponse<ISport[]>> {
        return this.store.get();
    }

    public getDefaultSelection(): string {
        return FOOTBALL;
    }

    public getTotalParts(sportId: string): number {
        return 2;
    }

    public getDisplayableName(sportId: string, sports: ISport[]): string {
        const foundSport = sports.find(({id}) => sportId === id);
        return foundSport ? foundSport.name : sportId;
    }

    public getPartDisplayName(sportId: string, partNumber: number) {
        if (partNumber < 0) {
            throw new Error(`Impossible part number "${partNumber}"`);
        }

        if (sportId === FOOTBALL) {
            if (partNumber === 0) {
                return "Half Time";
            }

            if (partNumber === 1) {
                return "Full Time";
            }
        }

        return `Part ${partNumber+1}`;
    }
}

export default new SportsServiceImpl();
