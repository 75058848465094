import { isSinkType, SinkType, ITransactionableAccount } from "../types";

const createAccountKey = (type: SinkType, canonicalAccountName: string): string => {
    return type + "-" + canonicalAccountName;
};

const keyToDetails = (key: string): ITransactionableAccount => {
    const parts = key.split("-");
    const type = parts.shift();
    const canonicalAccountName = parts.join("-");

    if (isSinkType(type)) {
        return {
            canonicalAccountName,
            type,
        };
    }

    throw new Error(`Unknown sink type '${type}'`);
};

export {
    createAccountKey,
    keyToDetails,
};
