import MarketService from "../../../MarketService";
import { IMarket } from "../../../type";

export interface IGroup {
    name: string;
    markets: IMarket[];
}

class Grouper {
    private static getOrCreateGroup(output: IGroup[], market: IMarket): IGroup {
        const thisName = MarketService.getCategoryName(market.category);
        const existing = output.find(({name}) => thisName === name);

        if (existing) {
            return existing;
        }

        const newGroup: IGroup = {
            name: thisName,
            markets: [],
        };

        output.push(newGroup);
        return newGroup;
    }

    public groupCatagories(allMarkets: IMarket[]): IGroup[] {
        const output: IGroup[] = [];

        for (const market of allMarkets) {
            const group = Grouper.getOrCreateGroup(output, market);
            group.markets.push(market);
        }

        return output;
    }
}

export default new Grouper();
