import { IValueSubscriptionMap } from "./types";
import ValueSubscriptionMap from "./ValueSubscriptionMap";

class ValueSubscriptionMapFactory {
    public create<KeyType, ValueType>(getCalculatorForKey: (key: KeyType) => () => ValueType): IValueSubscriptionMap<KeyType, ValueType> {
        return new ValueSubscriptionMap<KeyType, ValueType>(getCalculatorForKey);
    }
}

export default new ValueSubscriptionMapFactory();
