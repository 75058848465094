import { useCallback } from "react";
import { IBet } from "../../service/types";
import styles from "./BetRow.module.scss";

const EDITING_ROW_CLASSES = `${styles.betRow} ${styles.editing}`;

function useGetBetRowClassName(betIdThatIsSelectedForEditing?: string) {
    return useCallback((bet: IBet): string => {
        return bet.id === betIdThatIsSelectedForEditing ?
            EDITING_ROW_CLASSES : styles.betRow;
    }, [betIdThatIsSelectedForEditing]);
}

const exports = {
    useGetBetRowClassName,
};

export default exports;
