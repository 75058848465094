import * as React from "react";

interface IIfProps {
    children: React.ReactNode;
    condition: boolean;
}

const If: React.FC<IIfProps> = ({children, condition}) => {
    return condition ? <>{children}</> : null;
};

export default If;
