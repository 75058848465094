import * as React from "react";
import { IActionComponentProps } from "../../layout/action/ActionComponent";
import FormCard from "../../form/FormCard";
import { IMarket } from "../../markets/type";
import { IEvent } from "../../events/types";
import { IAccount } from "../../accounts/types";
import { IBet } from "../../bets/service/types";
import BetSlipStore from "./BetSlipStore";
import FormWidgets from "./components/FormWidgets";
import WithBetSlipEditingBet from "./hooks/WithBetSlipEditingBet";

interface BetSlipFormProps {
    editingBet?: IBet;
    event: IEvent;
    markets: IMarket[];
    accounts: [IAccount, ...IAccount[]];
}

const BetSlipForm: React.FC<IActionComponentProps<BetSlipFormProps>> = ({
    accounts,
    editingBet,
    event,
    markets,
    onActionDone,
}) => {
    const betSlipData = BetSlipStore.useData(accounts, markets, editingBet);
    const onSubmit = BetSlipStore.useSubmit(betSlipData, event, editingBet);

    return (
        <FormCard
            legend={WithBetSlipEditingBet.useLegendText(editingBet)}
            onSubmit={onSubmit}
            onSubmitSuccess={onActionDone}
            submitCaption={WithBetSlipEditingBet.useSubmitCaption(editingBet)}
            clearMessageEvents={WithBetSlipEditingBet.useClearMessagesSource(editingBet)}
        >
            <FormWidgets accounts={accounts} betSlipData={betSlipData} markets={markets} />
        </FormCard>
    );
};

export default BetSlipForm;
