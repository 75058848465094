import * as React from "react";
import styles from "./Overflow.module.scss";
import classnames from "classnames";

const H1OverflowEllipsis: React.FC<React.HTMLAttributes<HTMLHeadingElement>> = ({className, ...rest}) => {
    return (
        /* eslint-disable-next-line */
        <h1
            className={
                classnames(
                    className,
                    styles["…"],
                )
            }
            {...rest}
        />
    );
};

export default H1OverflowEllipsis;
