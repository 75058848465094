import ClosePaneButton from "../../../../../button/close-pane/ClosePaneButton";

interface StopEditingProps {
    clearEditingBet: () => void;
}

const StopEditingInteraction = ({clearEditingBet}: StopEditingProps) => {
    return <ClosePaneButton description="Cancel edit" onClick={clearEditingBet} />;
};

export default StopEditingInteraction;
