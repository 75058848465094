import { Fetch } from "../../fetch";
import { ISport } from "../Sport";
import UseFetchRequest from "../../fetch/hooks/UseFetchRequest";
import SportsService from "../SportsService";

function useSports(): Fetch<ISport[]> {
    const [fetch] = UseFetchRequest.useFetchRequest(() => SportsService.getSports());
    return fetch;
}

const exports = {
    useSports,
};

export default exports;
