import ISubscriptionListMap from "./ISubscriptionListMap";
import { ApiResponse } from "../../api/ApiResponse";
import SubscriptionListFactory from "./SubscriptionListFactory";
import ISubscriptionList from "./ISubscriptionList";

class SubscriptionListMap<T, ID> implements ISubscriptionListMap<T, ID> {
    private readonly keyToList: Map<string, ISubscriptionList<T, ID>> = new Map();

    constructor(
        private readonly loadData: (key: string) => () => Promise<ApiResponse<T[]>>,
        private readonly comparator?: (a: T, b: T) => number,
        private readonly getId?: (item: T) => ID) {

    }

    get(key: string): Promise<ApiResponse<T[]>> | T[] {
        let store = this.keyToList.get(key);

        if (!store) {
            store = SubscriptionListFactory.create(this.loadData(key), this.comparator, this.getId);
            this.keyToList.set(key, store);
        }

        return store.get();
    }

    subscribe(key: string, callback: (data: T[]) => void): () => void {
        const store = this.keyToList.get(key);

        if (store) {
            return store.subscribe(callback);
        }

        throw new Error("subscribe called before loaded");
    }

    insert(key: string, newItem: T): void {
        const store = this.keyToList.get(key);

        if (store) {
            store.insert(newItem);
        } else {
            throw new Error("insert called before loaded");
        }
    }

    attemptInsert(key: string, newItem: T): void {
        const store = this.keyToList.get(key);

        if (store) {
            store.insert(newItem);
        }
    }

    update(key: string, data: T[]): void {
        const store = this.keyToList.get(key);

        if (store) {
            store.update(data);
        } else {
            throw new Error("update called before loaded");
        }
    }

    findWithId(storeKey: string, itemId: ID): T | undefined {
        const store = this.keyToList.get(storeKey);
        return store ? store.findWithId(itemId) : undefined;
    }

    updateWithId(storeKey: string, itemId: ID, newValue: T): void {
        const store = this.keyToList.get(storeKey);

        if (store) {
            store.updateOne(itemId, newValue);
        } else {
            throw new Error("updateWithId called before loaded");
        }
    }

    deleteOne(storeKey: string, itemId: ID): void {
        const store = this.keyToList.get(storeKey);

        if (store) {
            store.deleteOne(itemId);
        } else {
            throw new Error("deleteOne called before loaded");
        }
    }
}

export default SubscriptionListMap;
