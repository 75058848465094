
class LoginUserErrors {
    public throwHumanReadableError(status?: number) {
        switch (status) {
            case 400:
                throw new Error("Unknown account, check your email address or signup");
            case 500:
                throw new Error("Server error, try again shortly");
            default:
                throw new Error("Unknown error, try again shortly");
        }
    }
}

export default new LoginUserErrors();
