import { IBet } from "../../../bets/service/types";
import ReactHooks from "../../../react/ReactHooks";
import UseObserver, { Subscriber } from "../../../subscription/observer/UseObserver";

function useClearMessagesSource(editingBet: IBet | undefined): Subscriber<void> {
    const [emitClearPane, clearPaneStream] =  UseObserver.useObserver<void>();

    ReactHooks.useEffect(() => emitClearPane(undefined), [editingBet]);

    return clearPaneStream;
}

function useLegendText(editingBet?: IBet): string {
    return editingBet ? "Edit bet" : "Add a bet";
}

function useSubmitCaption(editingBet?: IBet): string {
    return editingBet ? "Save changes": "Add bet";
}

const exports = {
    useClearMessagesSource,
    useLegendText,
    useSubmitCaption,
};

export default exports;
