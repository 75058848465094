import Callbacks from "./Callbacks";
import ICallbacks from "./ICallbacks";

class CallbacksFactory {
    public create<T>(): ICallbacks<T> {
        return new Callbacks<T>();
    }
}

export default new CallbacksFactory();
