import * as React from "react";
import { Redirect } from "react-router-dom";

type RedirectType = "logged-out" | "unauthenticated" | "verify";

export interface GotoLoginProps {
    redirectNotificationType?: RedirectType;
}

const getSearchForRedirectType = (redirectType?: RedirectType) => {
    switch (redirectType) {
        case "unauthenticated": return "unauthenticated";
        case "verify": return "failed-to-verify";
        case "logged-out": return "logged-out";
        default: return undefined;
    }
};

const GotoLogin: React.FC<GotoLoginProps> = (props) => (
    <Redirect
        to={{
            pathname: "/login",
            search: getSearchForRedirectType(props.redirectNotificationType),
        }}
    />
);

export default GotoLogin;
