import MarketService from "../../../markets/MarketService";
import { IMarket } from "../../../markets/type";
import { IBet } from "../../service/types";
import { BetHeaderData } from "./types";

class HeaderDataProviderFactory {
    public getProvider(markets: IMarket[]) {
        return ({selection}: IBet): BetHeaderData => {
            const {category, id, name} = MarketService.findMarket(selection.marketId, markets);

            return {
                key: `${category}-${id}`,
                marketId: id,
                marketName: name,
            };
        };
    }
}

export default new HeaderDataProviderFactory();
