import Once from "../../lifecycle/Once";

function useSubscription<T>(subscribeToSource: (cb: (newData: T) => void) => () => void,  setFetch: (value: T) => void) {
    Once.useEffectOnce(() => {
        const unsubscribe = subscribeToSource((updatedData: T) => {
            setFetch(updatedData);
        });

        return unsubscribe;
    });
}

const exports = {
    useSubscription,
};

export default exports;
