import { ApiMarket, IMarket } from "../type";
import ResultFactory from "./ResultFactory";

class MarketFactory {
    public fromApi(source: ApiMarket): IMarket {
        return {
            id: source.id,
            category: source.category,
            name: source.name,
            results: source.results.map((apiResult) => ResultFactory.fromApi(apiResult)),
        };
    }
}

export default new MarketFactory();
