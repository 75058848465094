import { useMemo } from "react";
import styles from "../BetRow.module.scss";
import { IAccount } from "../../../../accounts/types";
import { IBet, WagerableAccount } from "../../../service/types";
import Stake from "./Stake";
import AccountName from "./AccountName";
import CanonicalNameService from "../../../../accounts/canon/CanonicalNameService";

interface StakedAtProps {
    accounts: IAccount[];
    bet: IBet;
}

const StakedAt = ({
    accounts,
    bet,
}: StakedAtProps) => {
    const account = useMemo(() => findAccount(bet.account, accounts), [accounts, bet.account]);

    return (
        <div className={styles.row} data-testid="staked-at">
            <Stake account={account} bet={bet} />
            <AccountName className={styles.with_divisor} account={account} />
        </div>
    );
};

function findAccount(forAccount: WagerableAccount | undefined, accounts: IAccount[]): IAccount | undefined {
    return forAccount && accounts.find(({name, type}) =>
        type === forAccount.type &&
        CanonicalNameService.getCanonicalName(name) === forAccount.canonicalAccountName,
    );
}

export default StakedAt;
