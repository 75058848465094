export type SinkType = "bank" | "exchange" | "bookmaker";
export type MetaType = "bet";

export interface ISink {
    name: string;
    type: SinkType;
}

export interface ITransaction {
    amount: number;
    destination: ISink;
    reason?: string;
    source: null | ISink;
    time: Date;
    meta?: {
        type: MetaType;
        data: any;
    };
}

export function isSinkType(maybeSinkType: any): maybeSinkType is SinkType {
    switch (maybeSinkType) {
        case "bank":
        case "bookmaker":
        case "exchange":
            return true;
    }

    return false;
}

export interface ITransactionableAccount {
    type: SinkType;
    canonicalAccountName: string;
}
