import * as React from "react";
import { ITransaction } from "../../types";
import Money from "../../../money/Money";

import styles from "./TransactionRow.module.scss";
import DescribeTransaction from "../describe/DescribeTransaction";
import LocaleDateString from "../../../date/components/LocaleDateString";
import { IAnimatedRowPlusAdditionalProps } from "../../../list/types";

interface ITransactionRowAdditional {
    currencyCode: string;
}

const TransactionRow: React.FC<IAnimatedRowPlusAdditionalProps<ITransaction, ITransactionRowAdditional>> = ({
    data,
    additional,
}) => {
    const transaction = data;

    return (
        <>
            <div className={styles.left}>
                <DescribeTransaction transaction={transaction} />
                <div><LocaleDateString date={transaction.time}/></div>
            </div>
            <div className={styles.right}>
                <Money amount={transaction.amount} currency={additional.currencyCode} />
            </div>
        </>
    );
};

export default TransactionRow;
