import { IValueSubscriptionMap } from "../../../subscription/value/types";
import ValueSubscriptionMapFactory from "../../../subscription/value/ValueSubscriptionMapFactory";
import BetService from "../BetService";

export class BetDeleteValueCacheImpl {
    private cacheMap?: IValueSubscriptionMap<string, boolean>;

    public get(): IValueSubscriptionMap<string, boolean> {
        if (!this.cacheMap) {
            this.cacheMap = ValueSubscriptionMapFactory.create((betId: string) => {
                return () => BetService.queryBetDeletion(betId);
            });
        }

        return this.cacheMap;
    }
}

export default new BetDeleteValueCacheImpl();
