import styles from "./BetRow.module.scss";
import { useMemo } from "react";
import * as React from "react";
import { IAccount } from "../../../accounts/types";
import { IMarket } from "../../../markets/type";
import { IBet } from "../../service/types";
import ExpandingRowList from "../../../list/ExpandingRowList";
import { SelectedBetForEditingState } from "../../hooks/WithEditingBet";
import getBetKey from "../../getBetKey";
import { IAdditionalBetRowProps } from "./types";
import BetRow from "./BetRow";
import HeaderComponent from "./HeaderComponent";
import HeaderDataProviderFactory from "./HeaderDataProviderFactory";
import WithClassNames from "./WithClassNames";

interface Props {
    accounts: IAccount[];
    markets: IMarket[];
    data: IBet[];
    eventId: string;
    selectedBetForEditingState: SelectedBetForEditingState;
}

const BetList: React.FC<Props> = ({accounts, data, eventId, markets, selectedBetForEditingState}) => {
    const headerDataProvider = useMemo(() => HeaderDataProviderFactory.getProvider(markets), [markets]);
    const getRowClassName = WithClassNames.useGetBetRowClassName(selectedBetForEditingState[0]);

    const additional: IAdditionalBetRowProps = useMemo(() => ({
        accounts,
        eventId,
        markets,
        selectedBetForEditingState,
    }), [accounts, eventId, markets, selectedBetForEditingState]);

    return (
        <ExpandingRowList
            additional={additional}
            component={BetRow}
            data={data}
            emptyMessage="There are no bets placed for this event yet."
            getKey={getBetKey}
            rowClassName={getRowClassName}
            headerRowClassName={styles.betRow}
            headerComponent={HeaderComponent}
            getHeaderData={headerDataProvider}
        />
    );
};

export default BetList;
