import { IConfirmPrompt } from "../confirm/types";

export type QuickActionIcon = "cross" | "bin" | "pencil";
export enum QuickActionState {
    ACTIVE,
    INACTIVE,
    LOADING,
}

export interface IQuickActionConfiguration<IdType extends string> {
    id: IdType;
    caption: string;
    icon: QuickActionIcon;
    confirmation?: IConfirmPrompt;
    state: QuickActionState;
}
