import * as React from "react";
import ScreenSizes from "./ScreenSizes";
import UseWidth from "./hooks/UseWidth";

export interface IMobileProps {
    children: React.ReactNode;
}

const Phone: React.FC<IMobileProps> = ({children}) => {
    const screenWidth = UseWidth.useWidth();

    if (screenWidth < ScreenSizes.getTabletMinimumWidth()) {
        return (
            <>
                {children}
            </>
        );
    }

    return null;
};

export default Phone;
