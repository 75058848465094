import Card from "../../../../card/Card";
import LocaleDateString from "../../../../date/components/LocaleDateString";
import { IEvent } from "../../../types";
import { EVENT_DATE_TIME_FORMAT_OPTIONS } from "../../constants";

export interface EventSummaryCardProps {
    event: IEvent;
}

const EventSummaryCard = ({
    event,
}: EventSummaryCardProps) => (
    <Card>
        <h1 data-testid="event-name">{event.name}</h1>
        <LocaleDateString data-testid="event-when" date={event.when} options={EVENT_DATE_TIME_FORMAT_OPTIONS} />
    </Card>
);

export default EventSummaryCard;
