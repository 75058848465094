import { useState } from "react";

function useNeverChange<T>(data: T) {
    const [originalValue] = useState(data);
    if (originalValue !== data) {
        throw new Error(`Mutating the value is not expected was '${originalValue}', changed to '${data}'`);
    }
}

const exports = {
    useNeverChange,
};

export default exports;
