import { useState } from "react";
import ScoreInput from "../../../../../score/components/ScoreInput";
import SportsService from "../../../../../sports/SportsService";
import { ILogPart } from "../../../store/types";

interface Props {
    part: number;
    sportId: string;
    store: ILogPart;
}

const PartPointsEntry = ({part, sportId, store}: Props) => {
    const [localValue, setLocalValue] = useState(() => store.getPartPoints(part));
    const onChange = (newValue: string) => {
        store.setPartPoints(part, newValue);
        setLocalValue(newValue);
    };

    return (
        <label>
            <span data-testid="part-description">{SportsService.getPartDisplayName(sportId, part)}</span>
            <ScoreInput onChange={onChange} value={localValue} />
        </label>
    );
};

export default PartPointsEntry;
