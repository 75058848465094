import { createDoneFetch, Fetch, isDone } from ".";

class FetchMapper {
    public map<Payload, Out>(fetch: Fetch<Payload>, adaptor: (payload: Payload) => Out): Fetch<Out> {
        return isDone(fetch) ? createDoneFetch(adaptor(fetch.payload)) : fetch;
    }

    public sort<ItemType>(fetch: Fetch<ItemType[]>, comparator: (a: ItemType, b: ItemType) => number): Fetch<ItemType[]> {
        return this.map(fetch, (payload: ItemType[]) => payload.sort(comparator));
    }
}

export default new FetchMapper();
