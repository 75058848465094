import { useCallback } from "react";
import BetService from "../../bets/service/BetService";
import { BetCreationMeta, BetType,  IBet } from "../../bets/service/types";
import { SubmitFunction } from "../../form/types";

function useSubmit(
    eventId: string, betType: BetType, stake: string, odds: string,
    marketId: string, result: string, accountValue: string, editingBet: IBet | undefined,
    doClear: () => void,
): SubmitFunction {

    const doSubmit: SubmitFunction = useCallback(async () => {

        const payload: BetCreationMeta = {
            accountValue,
            betType,
            eventId,
            marketId,
            odds,
            result,
            stake,
        };

        if (editingBet) {
            await BetService.editBet(editingBet, payload);
            doClear();
            return "Bet changes saved";
        } else {
            await BetService.placeBet(payload);
            doClear();
            return "New bet was logged";
        }
    }, [accountValue, betType, eventId, editingBet, marketId, odds, result, stake, doClear]);

    return doSubmit;
}

const exports = {
    useSubmit,
};

export default exports;
