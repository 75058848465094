import UseFetchAndSubscribe from "../../fetch/hooks/UseFetchAndSubscribe";
import EventsService from "../EventsService";
import { Fetch } from "../../fetch";
import { IEvent } from "../types";

function useEvents(): Fetch<IEvent[]> {
    return UseFetchAndSubscribe.useFetchAndSubscribe(
        () => EventsService.getEvents(),
        (cb) => EventsService.subscribe(cb),
    );
}

const exports = {
    useEvents,
};

export default exports;
