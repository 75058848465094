import { AddAccount, AccountType } from "../types";
import { SubmitFunction } from "../../form/types";
import AccountService from "../AccountService";
import { useCallback } from "react";

const useSubmit = (accountType: AccountType, name: string, balance: string): SubmitFunction => {
    const doSubmit: SubmitFunction = useCallback(async () => {
        const addAccount: AddAccount = {
            name,
            balance: Number(balance),
        };

        await AccountService.addAccount(accountType, addAccount);
        return `Added new ${accountType} account - ${addAccount.name}`;
    }, [accountType, name, balance]);

    return doSubmit;
};

const exports = {
    useSubmit,
};

export default exports;
