import { useMemo } from "react";
import { IMarket } from "../../../../markets/type";
import { Selection } from "../../../service/types";

function useSelectedResultName(markets: IMarket[], selection: Selection) {
    const market = useMemo(() => markets.find(({id}) => id === selection.marketId), [selection, markets]);
    const result = useMemo(() => market && market.results.find(({id}) => id === selection.resultId), [market, selection.resultId]);

    return result ? result.name : selection.resultId;
}


const exports = {
    useSelectedResultName,
};

export default exports;
