import { IAddEvent, IApiAddEvent } from "../types";

class AddEventAdaptor {
    public toApi(addEvent: IAddEvent): IApiAddEvent {
        return {
            name: addEvent.name,
            sport: Number(addEvent.sportId),
            when: addEvent.when.toISOString(),
        };
    }
}

export default new AddEventAdaptor();
