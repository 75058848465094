import SelectMarket from "../../../../markets/components/select/SelectMarket";
import { IMarket } from "../../../../markets/type";
import { IUsesMarketId } from "./types";

interface Props extends IUsesMarketId {
    markets: IMarket[];
}

const MarketEntry = ({
    marketId,
    markets,
}: Props) => (
    <label>
        Market
        <SelectMarket
            data-testid="betslip-market-selector"
            onChange={marketId[1]}
            markets={markets}
            value={marketId[0]}
        />
    </label>
);

export default MarketEntry;
