import { IRequiredVariable } from "../criteria/types";
import VariableService from "../criteria/variables/VariableService";
import { ResultType } from "../type";

const useRequiredVariables = (results: ResultType[]): IRequiredVariable[] => {
    const output: IRequiredVariable[] = [];

    const notInOutput = (variable: IRequiredVariable) => {
        return output.every((outputVariable) => !VariableService.requiredVariablesAreEqual(outputVariable, variable));
    };

    for (const { criteria } of results) {
        for (const criterion of criteria) {
            for (const { requiredVariables } of criterion) {
                for (const variable of requiredVariables) {
                    if (notInOutput(variable)) {
                        output.push(variable);
                    }
                }
            }
        }
    }

    return output;
};

const WithResults = {
    useRequiredVariables,
};

export default WithResults;
