import WithInput from "../../../input/WithInput";

function useStakeInput(initialStake?: number) {
    return WithInput.useInput(initialStake === undefined ? undefined : `${initialStake}`);
}

const exports = {
    useStakeInput,
};

export default exports;
