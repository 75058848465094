import WithInput from "../../../input/WithInput";
import MarketService from "../../../markets/MarketService";
import { IMarket } from "../../../markets/type";

function useMarketIdInput(markets: IMarket[], initialId?: string) {
    if (markets.length === 0) {
        throw new Error("Unexpected state, can't populate market input as there are none");
    }

    const initialMarket: IMarket = (initialId === undefined) ? markets[0] :
        MarketService.findMarket(initialId, markets,
            `Unexpected state, can't populate market input as there is no market with the id "${initialId}"`);

    return WithInput.useSelect(initialMarket.id);
}

const exports = {
    useMarketIdInput,
};

export default exports;
