import API from "../../api";
import TransactionAdaptor, { SinkAndType } from "./../internal/TransactionAdaptor";
import CanonicalNameService from "../../accounts/canon/CanonicalNameService";
import { ISink } from "../types";
import WaitOnOriginatorTransactions from "./WaitOnOriginatorTransactions";

class TransferRequest {
    public makeTransferRequest(amount: number, destination: ISink, source?: ISink) {

        const normalisedDestinationName = CanonicalNameService.getCanonicalName(destination.name);

        let from: undefined | SinkAndType;
        if (source) {
            from = {
                normalisedName: CanonicalNameService.getCanonicalName(source.name),
                sinkType: source.type,
            };
        }

        return WaitOnOriginatorTransactions.waitOn(destination, source)
            .then(() => API.putJson("transaction", TransactionAdaptor.addTransactionToApi({
                amount,
                to: {
                    normalisedName: normalisedDestinationName,
                    sinkType: destination.type,
                },
                from,
            }),
        ));
    }
}

export default new TransferRequest();
