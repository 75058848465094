import { SubmitFunction } from "../../../form/types";
import throwError from "@ducktorscience/throw-error";
import SettleBetStoreFactory from "./SettleBetStoreFactory";
import { SettleBetData } from "./types";
import ReactHooks from "../../../react/ReactHooks";

function useStore(): SettleBetData {
    return ReactHooks.useState(() => SettleBetStoreFactory.create())[0];
}

function useSubmit(data: SettleBetData): SubmitFunction {
    return throwError("Not Implemented");
}

const WithSettleBetStore = {
    useStore,
    useSubmit,
};

export default WithSettleBetStore;
