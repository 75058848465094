import AccountService from "../../../accounts/AccountService";
import { IAccount } from "../../../accounts/types";
import { WagerableAccount } from "../../../bets/service/types";
import ReactHooks from "../../../react/ReactHooks";

function useAccountInput(accounts: [IAccount, ...IAccount[]], initialWagerableAccount?: WagerableAccount) {
    const initialValue = initialWagerableAccount ?
        AccountService.createKey(AccountService.getAccountForWagerableAccount(accounts, initialWagerableAccount)) :
        "";

    return ReactHooks.useState(initialValue);
}

const exports = {
    useAccountInput,
};

export default exports;
