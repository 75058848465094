import * as React from "react";
import classnames from "classnames";
import styles from "./Button.module.scss";

interface Props {
    className?: string;
    kind?: "primary" | "secondary";
}

type CombinedProps = Props & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button: React.FC<CombinedProps> = ({ className, kind, ...restProps }) => {
    const kindStyle: string = kind === "secondary" ? styles.secondary : styles.primary;

    return (
        <button
            className={classnames(
                kindStyle,
                styles.button,
                className,
            )}

            {...restProps}
        />
    );
};

export default Button;
