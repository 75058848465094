import * as React from "react";
import { Link } from "react-router-dom";
import styles from "./Masthead.module.scss";
import BurgerButton from "../burger/button/BurgerButton";
import Desktop from "../screen/Desktop";
import LinkList from "../routing/components/linklist/LinkList";
import Handheld from "../screen/Handheld";
import Routes from "../routing/Routes";

const Masthead: React.FC = () => {
    return (
        <nav className={styles.masthead}>
            <Handheld>
                <BurgerButton />
            </Handheld>

            <ul>
                <li>
                    <Link to="/">SITE NAME</Link>
                </li>

                <Desktop>
                     <LinkList routes={Routes.getMastheadDesktopRoutes()} />
                </Desktop>
            </ul>
        </nav>
    );
};

export default Masthead;
