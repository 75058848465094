import * as React from "react";
import ErrorDisplay from "./internal/ErrorDisplay";
import SpinnerGuard from "../spinner/SpinnerGuard";
import { Fetch, isLoading, isError, isDone } from "./";

interface FetchConditionalProps<T> {
    children: React.ReactNode;
    errorComponent?: React.ComponentType<{message: string}>;
    fetch: Fetch<T>;
    loadingMessage?: string;
}

export default function fetchConditional<T>(props: FetchConditionalProps<T>) {
    const fetch = props.fetch;

    return  (
        <SpinnerGuard loading={isLoading(fetch)} message={props.loadingMessage}>
            {isError(fetch) ? <ErrorDisplay component={props.errorComponent} message={fetch.message} /> : null}
            {isDone(fetch) && props.children}
        </SpinnerGuard>
    );
}
