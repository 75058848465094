import Once from "../../lifecycle/Once";
import ReactHooks from "../../react/ReactHooks";
import CallbacksFactory from "../callback/CallbacksFactory";
import WithCallbacks from "../callback/hooks/WithCallbacks";
import ICallbacks from "../callback/ICallbacks";

export type Emit<T> = (data: T) => void;
export type Stream<T> = (onChange: (data: T) => void) => () => void;
export type Subscriber<T> = (onChange: (data: T) => void) => () => void;
export type OnChange<T> = (data: T) => void;

export type UseObserverOutput<T> = [Emit<T>, Stream<T>];

function createCallbacks<T>(): ICallbacks<T> {
    return CallbacksFactory.create<T>();
}

function useObserver<T>(): UseObserverOutput<T> {
    const callbacks = Once.useMemoOnce(createCallbacks as () => ICallbacks<T>);

    return [
        WithCallbacks.useEmitChanges<T>(callbacks),
        WithCallbacks.useSubscribeToChanges<T>(callbacks),
    ];
}

function useOnObserverChange<T>(subscribe: Subscriber<T> | undefined, onChange: OnChange<T>) {
    ReactHooks.useEffect(() => {
        if (subscribe) {
            return subscribe(onChange);
        }
    }, [subscribe, onChange]);
}


const exports = {
    useObserver,
    useOnObserverChange,
};

export default exports;
