import { ApiCriterion } from "../api-types";
import ConditionFactory from "../condition/ConditionFactory";
import { Criterion } from "../types";

class CriterionFactory {
    fromApi([first, ...rest]: ApiCriterion): Criterion {
        return [
            ConditionFactory.fromApi(first),
            ...rest.map((apiCondition) => ConditionFactory.fromApi(apiCondition)),
        ];
    }
}

export default new CriterionFactory();
