import ScreenSizes, { ScreenType } from "../ScreenSizes";
import Once from "../../lifecycle/Once";
import ScreenSizeChangedObserverFactory from "../ScreenSizeChangedObserverFactory";
import { useState } from "react";

function useScreenSize(): ScreenType {
    const [screenSize, setScreenSize] = useState(ScreenSizes.getScreenType);

    Once.useEffectOnce(() => {
        return ScreenSizeChangedObserverFactory.createListener(() => {
            setScreenSize(ScreenSizes.getScreenType());
        });
    });

    return screenSize;
}

const exports = {
    useScreenSize,
};

export default exports;
