import { useCallback } from "react";
import * as React from "react";
import isNumber from "../../numbers/isNumber";
import styles from "./ScoreInput.module.scss";

interface Props {
    value: string;
    onChange: (newValue: string) => void;
}

const ScoreInput: React.FC<Props> = ({onChange, value}) => {
    const values = extractValues(value);

    const homeValue = values ? safeString(values[0]) : "";
    const awayValue = values ? safeString(values[1]) : "";

    const onHomeChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(`${e.target.value}-${awayValue}`);
    }, [awayValue, onChange]);

    const onAwayChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(`${homeValue}-${e.target.value}`);
    }, [homeValue, onChange]);

    return (
        <div className={styles.scoreInput}>
            <input
                data-testid="score-input-home"
                min="0"
                max="9"
                onChange={onHomeChange}
                required={true}
                type="number"
                value={homeValue}
            />
            <span className={styles.dash}>–</span>
            <input
                data-testid="score-input-away"
                min="0"
                max="9"
                onChange={onAwayChange}
                required={true}
                type="number"
                value={awayValue}
            />
        </div>
    );
};

function extractValues(input: string): [string, string] | null {
    const parts = input.split("-");

    if (parts.length === 1 && input.length > 0) {
        const asNumber = Number(parts[0]);
        if (isNumber(asNumber)) {
            return [safeString(parts[0]), ""];
        }
    }

    return parts.length === 2 ?
        [safeString(parts[0]), safeString(parts[1])] :
        null;
}

function safeString(maybeString?: string): string {
    return maybeString || "";
}

export default ScoreInput;
