import * as React from "react";
import styles from "./Header.module.scss";
import classnames from "classnames";

interface Props {
    className?: string;
}

const Header: React.FC<Props> = ({children, className}) => (
    <div className={classnames(styles.header, className)}>
        {children}
    </div>
);

export default Header;
