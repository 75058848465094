import { IRequiredVariable } from "../types";

class VariableService {
    public requiredVariablesAreEqual(a: IRequiredVariable, b: IRequiredVariable) {
        if (a.variableName === b.variableName) {
            if (a.references.size === b.references.size) {
                let allSame = true;

                a.references.forEach((value, key) => {
                    if (b.references.get(key) !== value) {
                        allSame = false;
                    }
                });

                return allSame;
            }
        }

        return false;
    }
}

export default new VariableService();
