import * as React from "react";
import styles from "./EmptyMessage.module.scss";

interface EmptyMessageProps {
    text: string;
}

const EmptyMessage: React.FC<EmptyMessageProps> = (props) => (
    <div className={styles.emptyMessage} data-testid="empty-message">
        {props.text}
    </div>
);

export default EmptyMessage;
