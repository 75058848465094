import * as React from "react";
import Pill from "../../../pill/Pill";
import { ITransaction } from "../../types";
import styles from "./DescribeTransaction.module.scss";

interface DescribeTransactionProps {
    transaction: ITransaction;
}

const DescribeTransaction: React.FC<DescribeTransactionProps> = ({transaction}) => {
    const {source} = transaction;

    if (source) {
        const isSource = transaction.amount < 0;

        return (
            <span className={styles.description}>
                Transfer {isSource ? "into" : "from"} <strong>{isSource ? transaction.destination.name : source.name}</strong>
            </span>
        );
    }

    if (transaction.meta) {
        return <span>
            <Pill caption="BET" data-testid="meta-pill" kind="primary" />
        </span>;
    }

    return <span>Manual adjustment</span>;
};

export default DescribeTransaction;
