import * as React from "react";
import styles from "./Actions.module.scss";

interface Props {
    children: React.ReactNode;
}

const Actions: React.FC<Props> = ({children}) => (
    <div className={styles.actions}>
        {children}
    </div>
);

export default Actions;
