import * as React from "react";
import { IActionComponentProps } from "../ActionComponent";
import ActionButton from "./ActionButton/ActionButton";
import UseRequiresOverlay from "./UseRequiresOverlay";
import ClosableOverlay from "../../../overlay/ClosableOverlay/ClosableOverlay";
import ActionContents from "./ActionContents/ActionContents";

type Props<T> = T & {
    caption: string;
    component: React.ComponentType<IActionComponentProps<T>>;
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
};

function ActionLayout<T>({caption, component, isOpen, onOpen, onClose, ...rest}: Props<T>) {
    const useOverlay = UseRequiresOverlay.useRequiresOverlay();

    return (
        <>
            <ActionButton caption={caption} onClick={onOpen} />
            {useOverlay && <ClosableOverlay isOpen={isOpen} onClose={onClose} />}
            <ActionContents component={component} isOpen={isOpen} onClose={onClose} {...(rest as any as T)} />
        </>
    );
}

export default ActionLayout;
