import CallbacksFactory from "../callback/CallbacksFactory";
import ICallbacks from "../callback/ICallbacks";
import { IValueSubscription } from "./types";

export default class ValueSubscription<ValueType> implements IValueSubscription<ValueType> {
    private callbacks: ICallbacks<ValueType> = CallbacksFactory.create();
    private currentValue: ValueType;

    constructor(private readonly calculateValue: () => ValueType) {
        this.currentValue = calculateValue();
    }

    public subscribe(onChange: (value: ValueType) => void) {
        return this.callbacks.subscribe(onChange);
    }

    public getCurrentValue(): ValueType {
        return this.currentValue;
    }

    public recalculate() {
        this.currentValue = this.calculateValue();
        this.callbacks.fireCallbacks(this.currentValue);
    }
}
