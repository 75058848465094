import * as React from "react";

interface SelectBetTypeProps extends React.ComponentPropsWithoutRef<'select'> {

}

const SelectBetType = (props: SelectBetTypeProps) => {
    return (
        <select {...props}>
            <option value="Back">Back</option>
            <option value="Lay">Lay</option>
            <option value="Qualifier">Qualifier</option>
            <option value="SNR" data-testid="bet-selector-option-snr">Stake NOT returned free bet</option>
            <option value="SR" data-testid="bet-selector-option-sr">Stake returned free bet</option>
        </select>
    );
};

export default SelectBetType;
