import { AxiosRequestConfig } from "axios";
import AuthenticationService from "../../authentication/AuthenticationService";

const bearerTokenInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig => {
    const token = AuthenticationService.getToken();

    if (token) {
        config.headers.Authorization = "Bearer " + token;
    }

    return config;
};

export default bearerTokenInterceptor;
