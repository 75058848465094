import * as React from "react";
import { IAnimatedRowPlusAdditionalProps } from "../../../list/types";
import { IBet } from "../../service/types";
import { IAdditionalBetRowProps } from "./types";
import Details from "./internal/Details";
import Interactions from "./internal/Interactions";

const BetRow: React.FC<IAnimatedRowPlusAdditionalProps<IBet, IAdditionalBetRowProps>> = ({data, additional, mouseEntered}) => {
    const { accounts, eventId, markets, selectedBetForEditingState } = additional;

    return (
        <>
            <Details bet={data} accounts={accounts} markets={markets} />
            <Interactions
                bet={data}
                eventId={eventId}
                selectedBetForEditingState={selectedBetForEditingState}
                mouseEnteredRow={!!mouseEntered}
            />
        </>
    );
};

export default BetRow;
