import { IAddEvent, IEvent } from "./types";
import api from "../api";
import AddEventAdaptor from "./internal/AddEventAdaptor";
import { fromUnsuccessfulResponse, ApiResponse } from "../api/ApiResponse";
import EventListStoreFactory from "./internal/EventListStoreFactory";
import EventFactory from "./internal/EventFactory";

export class EventsServiceImpl {
    private readonly eventListStore = EventListStoreFactory.create();

    public getEvents(): Promise<ApiResponse<IEvent[]>> | IEvent[] {
        return this.eventListStore.get();
    }

    public getEventsWithCanonicalIds(canonicalIds: Set<string>): Promise<IEvent[]> {
        throw new Error("Not Implemented");
    }

    public async addEvent(addEvent: IAddEvent): Promise<undefined> {
        const response = await api.putJson("event", AddEventAdaptor.toApi(addEvent));

        if (!response.successful) {
            return Promise.reject(fromUnsuccessfulResponse(response, `Failed to add the new event`));
        }

        this.eventListStore.insert(EventFactory.fromAddEvent(addEvent));
    }

    public subscribe(onChange: (events: IEvent[]) => void): () => void {
        return this.eventListStore.subscribe(onChange);
    }
}

export default new EventsServiceImpl();
