import * as React from "react";
import styles from "./BurgerButton.module.scss";
import BurgerManager from "../BurgerManager";

const Bars: React.FC = () => (
    <>
        <div className={styles.bar} />
        <div className={styles.bar} />
        <div className={styles.bar} />
    </>
);

const openBurger = () => BurgerManager.openMenu();

const BurgerButton: React.FC = () => {
    return (
        <div className={styles.burger} aria-label="Navigation Menu" role="button" aria-expanded={false} tabIndex={0} onClick={openBurger}>
            <Bars />
        </div>
    );
};

export default BurgerButton;
