import IHasId from "../id/IHasId";
import { ApiCriteria } from "./criteria/api-types";
import { Criteria } from "./criteria/types";

/* Any changes must be reflected in all the below:
 * - MarketService.getCategoryName
 * - CategoryToInputType
 */
export enum MarketCategory {
    Win,
    Statistics,
    Score,
    Handicap,
    Player,
    Goals,
}

export interface ResultType extends IHasId {
    criteria: Criteria;
    name: string;
}

export interface IMarket extends IHasId {
    category: MarketCategory;
    name: string;
    results: ResultType[];
}

export interface ApiResultType {
    id: string;
    criteria: ApiCriteria;
    name: string;
}

export interface ApiMarket {
    id: string;
    category: MarketCategory;
    results: ApiResultType[];
    name: string;
}
