import * as React from "react";
import MoneyFormatter from "./MoneyFormatter";

interface MoneyProps {
    amount: number;
    currency: string;
}

const Money: React.FC<MoneyProps> = ({amount, currency}) => (
    <strong>
        {MoneyFormatter.format(amount, currency)}
    </strong>
);

export default Money;
