import * as React from "react";
import { AccountType } from "../../types";
import Page from "../../../layout/page/Page";
import Primary from "../../../layout/page/Primary";
import Secondary from "../../../layout/page/Secondary";
import AccountsDashboard from "../dashboard/AccountsDashboard";
import Actions from "../../../layout/action/Actions";
import Action from "../../../layout/action/Action";
import AddAccount from "../add/AddAccount";
import { capitaliseFirstLetter } from "../../../text/StringUtilities";

interface Props {
    accountType: AccountType;
}

const AccountsPage: React.FC<Props> = ({accountType}) => (
    <Page>
        <Primary>
            <AccountsDashboard accountType={accountType} />
        </Primary>
        <Secondary>
            <Actions>
                <Action accountType={accountType} caption={`Add ${capitaliseFirstLetter(accountType)}`} component={AddAccount} />
            </Actions>
        </Secondary>
    </Page>
);

export default AccountsPage;
