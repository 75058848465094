import { useState } from "react";
import * as React from "react";
import AuthenticationService from "../../../authentication/AuthenticationService";
import Once from "../../../lifecycle/Once";

export interface NotLoggedInProps {
    children: React.ReactNode;
}

const NotLoggedIn: React.FC<NotLoggedInProps> = (props) => {
    const [isAuthenticated, setIsAuthenticated] = useState(AuthenticationService.isLoggedIn());

    Once.useEffectOnce(() => {
        return AuthenticationService.registerAuthenticationListener(() => {
            setIsAuthenticated(AuthenticationService.isLoggedIn());
        });
    });

    if (isAuthenticated) {
        return null;
    }

    return (
        <>
            {props.children}
        </>
    );
};

export default NotLoggedIn;
