
function findOrThrow<T>(array: T[], predicate: (value: T) => boolean, throwMessage?: string): T {
    const result = array.find(predicate);
    if (result === undefined) {
        throw new Error(throwMessage || "Find predicate failed to find any matching entries");
    }
    return result;
}

export default findOrThrow;
