import BetService from "../service/BetService";
import NeverChange from "../../lifecycle/NeverChange";
import UseFetchAndSubscribe from "../../fetch/hooks/UseFetchAndSubscribe";
import { IMarket } from "../../markets/type";
import { Fetch } from "../../fetch";
import { IBet } from "../service/types";
import CompareBetsFactory from "../comparators/CompareBetsFactory";
import FetchMapper from "../../fetch/FetchMapper";
import ReactHooks from "../../react/ReactHooks";

const hooks = {
    useForEvent,
    useForEventSorted,
};

function useForEvent(eventId: string): Fetch<IBet[]> {
    NeverChange.useNeverChange(eventId);

    return UseFetchAndSubscribe.useFetchAndSubscribe(
        () => BetService.getBetsForEvent(eventId),
        (cb) => BetService.subscribe(eventId, cb),
    );
}

function useForEventSorted(eventId: string, markets: IMarket[]) {
    const comparator = ReactHooks.useMemo(() => CompareBetsFactory.createBetComparator(markets), markets);

    return FetchMapper.sort(hooks.useForEvent(eventId), comparator);
}

const exports = {
    hooks,
};

export default exports;
