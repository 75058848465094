import * as React from "react";

import { NavLink } from "react-router-dom";
import { IRouteSpec } from "../../internal/RouteDefinitions";
import ConditionalWrap from "./internal/ConditionalWrap";

interface Props {
    activerouteClassName?: string;
    routes: IRouteSpec[];
}

const LinkList: React.FC<Props> = (props) => {
    return (
        <>
            {
                props.routes.map(route => {
                    return (
                        <ConditionalWrap route={route} key={route.title}>
                            <li>
                                <NavLink
                                    activeClassName={props.activerouteClassName}
                                    to={route.path}
                                >
                                    {route.title}
                                </NavLink>
                            </li>
                        </ConditionalWrap>
                    );

                })
            }
        </>
    );
};

export default LinkList;
