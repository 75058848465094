import { SubmitFunction } from "../../../../form/types";
import { IdentifiableAccount } from "../../../../accounts/types";
import TransactionService from "../../../TransactionService";
import { useCallback } from "react";

function useSubmit(account: IdentifiableAccount, amount: string): SubmitFunction {

    const doSubmit: SubmitFunction = useCallback(async () => {
        await TransactionService.adjustBalance(Number(amount), account);
        return "Balance adjusted";
    }, [account, amount]);

    return doSubmit;
}

const exports = {
    useSubmit,
};

export default exports;
