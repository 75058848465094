import * as React from "react";
import { IAccount } from "../../accounts/types";
import UseBets from "../../bets/hooks/UseBets";
import Page from "../../layout/page/Page";
import { IMarket } from "../../markets/type";
import { ISport } from "../../sports/Sport";
import { IEvent } from "../types";
import WithEditingBet from "../../bets/hooks/WithEditingBet";
import PrimaryPage from "./internal/primary/PrimaryPage";
import SecondaryPage from "./internal/secondary/SecondaryPage";

interface Props {
    event: IEvent;
    sports: ISport[];
    markets: IMarket[];
    accounts: IAccount[];
}

const EventPage: React.FC<Props> = ({accounts, event, markets}) => {
    const betsFetch = UseBets.hooks.useForEventSorted(event.id, markets);
    const selectedBetForEditingState = WithEditingBet.useSelectedForEditBet();
    const editingBet = WithEditingBet.useActualBet(betsFetch, selectedBetForEditingState[0]);

    return (
        <Page>
            <PrimaryPage
                accounts={accounts}
                betsFetch={betsFetch}
                event={event}
                markets={markets}
                selectedBetForEditingState={selectedBetForEditingState}
            />
            <SecondaryPage
                accounts={accounts}
                betsFetch={betsFetch}
                clearEditingBetSelection={selectedBetForEditingState[2]}
                editingBet={editingBet}
                event={event}
                markets={markets}
            />
        </Page>
    );
};

export default EventPage;
