import { Dispatch, SetStateAction } from "react";
import ReactHooks from "../react/ReactHooks";
import Once from "../lifecycle/Once";

type OnInputChangeEvent = (e: React.ChangeEvent<HTMLInputElement>) => void;

export type UseInputResult = [string, OnInputChangeEvent, Dispatch<SetStateAction<string>>];

const useInput = (initialState: string = ""): UseInputResult => {
    const [value, setter] = ReactHooks.useState(initialState);

    const onChange: OnInputChangeEvent = Once.useCallbackOnce(({target}) => {
        setter(target.value);
    });

    return [value, onChange, setter];
};

export default useInput;
