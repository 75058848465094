import { ApiResponse, createSuccess, fromUnsuccessfulResponse } from "../../../api/ApiResponse";
import API from "../../../api";
import { AccountLike, AccountType } from "../../types";

class LoadAccounts {
    async loadAccounts<T extends AccountLike>(type: AccountType): Promise<ApiResponse<T[]>> {
        const response = await API.get<T[]>(`/${type}`);

        if (response.successful) {
            return createSuccess(response.data);
        }

        return fromUnsuccessfulResponse(response, `Failed to get ${type} accounts`);
    }
}

export default new LoadAccounts();
