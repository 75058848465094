import * as React from "react";
import Overlay from "../overlay/Overlay";
import InternalMessageBox, { InternalMessageBoxProps } from "./internal/InternalMessageBox";

interface Props extends InternalMessageBoxProps {
    isOpen: boolean;
}

const MessageBox: React.FC<Props> = ({
    isOpen,
    ...internalMessageBoxProps
}) => {
    return (
        <Overlay open={isOpen}>
            <InternalMessageBox {...internalMessageBoxProps} />
        </Overlay>
    );
};

export default MessageBox;
