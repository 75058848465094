import * as React from "react";
import ScreenSizes from "./ScreenSizes";
import UseWidth from "./hooks/UseWidth";

export interface IDesktopProps {
    children: React.ReactNode;
}

const Desktop: React.FC<IDesktopProps> = ({children}) => {
    const screenWidth = UseWidth.useWidth();

    if (screenWidth >= ScreenSizes.getDesktopMinimumWidth()) {
        return (
            <>
                {children}
            </>
        );
    }

    return null;
};

export default Desktop;
