import WindowSizeProvider from "../window/WindowSizeProvider";

export type ScreenType = "desktop" | "phone" | "tablet";

class ScreenSizes {
    private static MINIMUM_DESKTOP = 960; // This number is mirrored in sizes.scss as $desktop-minimum-width
    private static MINIMUM_TABLET = 768; // This number is mirrored in sizes.scss as $tablet-minimum-width

    public getDesktopMinimumWidth(): number {
        return ScreenSizes.MINIMUM_DESKTOP;
    }

    public getTabletMinimumWidth(): number {
        return ScreenSizes.MINIMUM_TABLET;
    }

    public getScreenType(): ScreenType {
        const screenWidth = WindowSizeProvider.getWindowWidth();

        if (screenWidth < ScreenSizes.MINIMUM_TABLET) {
            return "phone";
        }

        return screenWidth < ScreenSizes.MINIMUM_DESKTOP ? "tablet" : "desktop";
    }
}

export default new ScreenSizes();
