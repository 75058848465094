import { useState } from "react";
import * as React from "react";
import LoginForm from "./LoginForm";
import LoginService from "./LoginService";

import { RouteComponentProps } from "react-router-dom";
import ErrorMessage from "../feedback/message/error/ErrorMessage";
import Once from "../lifecycle/Once";

export type LoginScreenProps = RouteComponentProps<{}>;

const getErrorMessage = (forceHideMessage: boolean, search: string) => {
    if (!forceHideMessage) {
        switch (search) {
            case "?failed-to-verify":
                return "Failed to complete login. Perhaps the verification link expired and you can login again below.";
            case "?logged-out":
                return "You have successfully logged out";
            case "?unauthenticated":
                return "Could not access that page, you must login.";
        }
    }
};

const LoginScreen: React.FC<LoginScreenProps> = (props) => {
    const [forceHideMessage, setForceHideMessage] = useState(false);
    const errorMessage = getErrorMessage(forceHideMessage, props.location.search);

    Once.useEffectOnce(() => {
        return LoginService.registerLoginAttemptListener(() => {
            setForceHideMessage(true);
        });
    });

    return (
        <>
            <ErrorMessage message={errorMessage} />
            <LoginForm />
        </>
    );
};

export default LoginScreen;
