import Logger from "../../../logger/Logger";
import { MarketCategory } from "../../../markets/type";

export type CategoryInputType = "results-dropdown" | "score" | "free-text";

class CategoryToInputType {
    private static dropDownCategories = new Set<MarketCategory>([MarketCategory.Win, MarketCategory.Goals]);
    private static scoreCategories = new Set<MarketCategory>([MarketCategory.Score]);
    private static freeTextCategories = new Set<MarketCategory>([
        MarketCategory.Handicap, MarketCategory.Player, MarketCategory.Statistics,
    ]);

    public categoryToInputType(category: MarketCategory): CategoryInputType {
        if (CategoryToInputType.dropDownCategories.has(category)) {
            return "results-dropdown";
        }

        if (CategoryToInputType.scoreCategories.has(category)) {
            return "score";
        }

        if (CategoryToInputType.freeTextCategories.has(category)) {
            return "free-text";
        }

        const errorMessage = `Unexpected category with id ${category}`;
        Logger.error(errorMessage);
        throw new Error(errorMessage);
    }
}

export default new CategoryToInputType();
