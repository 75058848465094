
class GetOrThrowMap<K, V> extends Map<K, V> {
    get(key: K): V {
        if (this.has(key as any)) {
            return super.get(key as any) as V;
        }

        throw new Error("Key not present: " + key);
    }
}

export default GetOrThrowMap;
