import { IBet } from "../../bets/service/types";
import FormCard from "../../form/FormCard";
import { IMarket } from "../../markets/type";
import WithSettlement from "../spec/WithSettlement";
import SettleBetWidgets from "./widgets/SettleBetWidgets";
import WithSettleBetStore from "./store/WithSettleBetStore";
import { SettleBetData } from "./store/types";

interface SettleBetFormProps {
    openBets: [IBet, ...IBet[]];
    markets: IMarket[];
    sportId: string;
}

const SettleBetForm = ({
    openBets,
    markets,
    sportId,
}: SettleBetFormProps) => {
    const data: SettleBetData = WithSettleBetStore.useStore();
    const onSubmit = WithSettleBetStore.useSubmit(data);

    const settleSpec = WithSettlement.useSettleSpec(markets, openBets);

    return (
        <FormCard
            legend="Settle event"
            onSubmit={onSubmit}
            submitCaption="Settle"
        >
            <SettleBetWidgets settleSpec={settleSpec} store={data} sportId={sportId} />
        </FormCard>
    );
};

export default SettleBetForm;
