import * as React from "react";
import Card from "../../card/Card";

import NotPhone from "../../screen/NotPhone";
import Phone from "../../screen/Phone";
import UseObserver from "../../subscription/observer/UseObserver";
import ClosableOverlay from "../ClosableOverlay/ClosableOverlay";
import UseOverlayControl from "../hooks/UseOverlayControl";

interface HandlesOnClick {
    onClick: () => void;
}

interface Props {
    children: React.ReactNode;
    phoneTriggerComponent: React.ComponentType<HandlesOnClick>;
    subscribeToCloseEvents: (onChange: (data: void) => void) => () => void;
    inlineEngaged: boolean;
}

function InlineOrCardOverlay({
    children,
    inlineEngaged,
    subscribeToCloseEvents,
   ...props
}: Props) {
    const TriggerComponent = props.phoneTriggerComponent;

    const [isOpen, open, close] = UseOverlayControl.hooks.useAutoCloseWhenNotPhoneOverlayControl();

    UseObserver.useOnObserverChange(subscribeToCloseEvents, close);

    return (
        <>
            <NotPhone>
                {inlineEngaged && children}
            </NotPhone>
            <Phone>
                <TriggerComponent onClick={open} />
            </Phone>
            <ClosableOverlay isOpen={isOpen} onClose={close}>
                <Card>
                    {children}
                </Card>
            </ClosableOverlay>
        </>
    );
}

export default InlineOrCardOverlay;
