import * as React from "react";
import Card from "../../../card/Card";
import { ISport } from "../../../sports/Sport";
import { IEvent } from "../../types";
import EventList from "../list/EventList";

interface Props {
    events: IEvent[];
    sports: ISport[];
}

const EventsDashboard: React.FC<Props> = ({events, sports}) => {
    return (
        <div>
            <Card>
                <h1>Events</h1>
            </Card>
            <Card>
                <EventList events={events} sports={sports} />
            </Card>
        </div>
    );
};

export default EventsDashboard;
