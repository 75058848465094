import ReactHooks from '../react/ReactHooks';

const EMPTY_ARRAY = Object.freeze([]);

function useCallbackOnce<T extends (...args: any[]) => any>(callback: T) {
    return ReactHooks.useCallback(callback, EMPTY_ARRAY);
}

function useEffectOnce(effect: React.EffectCallback) {
    ReactHooks.useEffect(effect, EMPTY_ARRAY);
}

function useMemoOnce<T>(factory: () => T): T {
    return ReactHooks.useMemo(factory, EMPTY_ARRAY);
}

const exports = {
    useCallbackOnce,
    useEffectOnce,
    useMemoOnce,
};

export default exports;
