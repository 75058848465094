import BurgerManager from "../BurgerManager";
import Once from "../../lifecycle/Once";
import { useState } from "react";

const useBurgerMenuOpen: () => boolean = () => {
    const [isOpen, setIsOpen] = useState(BurgerManager.isOpen());

    Once.useEffectOnce(() => BurgerManager.subscribe(setIsOpen));

    return isOpen;
};

export default useBurgerMenuOpen;
