import { ITransaction, ISink, SinkType, ITransactionableAccount } from "../types";
import { IApiSink, IApiTransaction, IAddNewTransaction, ApiSinkType } from "./ApiTypes";
import GetOrThrowMap from "../../collections/map/GetOrThrowMap";
import CanonicalNameService from "../../accounts/canon/CanonicalNameService";

const ApiToSinkTypeLookup = new GetOrThrowMap<number, SinkType>([
    [0, "bank"],
    [1, "bookmaker"],
    [2, "exchange"],
]);

const SinkTypeToApiLookup = new GetOrThrowMap<SinkType, ApiSinkType>([
    ["bank", 0],
    ["bookmaker", 1],
    ["exchange", 2],
]);

export type SinkAndType = {
    sinkType: SinkType;
    normalisedName: string;
};

export interface AddTransaction {
    from?: SinkAndType;
    to: SinkAndType;
    amount: number;
}

class TransactionAdaptor {
    public apiToTransaction(originator: ITransactionableAccount, apiTransaction: IApiTransaction): ITransaction {
        const { source, destination, time, amount, ...rest } = apiTransaction;

        const hydratedSource = source ? TransactionAdaptor.apiSinkToSink(source) : null;

        const displayAmount = hydratedSource && (hydratedSource.type === originator.type &&
            CanonicalNameService.getCanonicalName(hydratedSource.name) === originator.canonicalAccountName) ?
                -amount : amount;

        return {
            ...rest,
            amount: displayAmount,
            destination: TransactionAdaptor.apiSinkToSink(destination),
            source: hydratedSource,
            time: new Date(time),
        };
    }

    public addTransactionToApi(addTransaction: AddTransaction): IAddNewTransaction {
        return {
            amount: addTransaction.amount,
            fromName: addTransaction.from?.normalisedName,
            fromType: addTransaction.from ? SinkTypeToApiLookup.get(addTransaction.from.sinkType) : undefined,
            toName: addTransaction.to.normalisedName,
            toType: SinkTypeToApiLookup.get(addTransaction.to.sinkType),
        };
    }

    private static adaptToSinkType(apiValue: number): SinkType {
        try {
            return ApiToSinkTypeLookup.get(apiValue);
        } catch(e) {
            throw new Error("Unsupported sink type: " + apiValue);
        }
    }

    private static apiSinkToSink({name, type}: IApiSink): ISink {
        return {
            name,
            type: TransactionAdaptor.adaptToSinkType(type),
        };
    }
}

export default new TransactionAdaptor();
