import styles from "../BetRow.module.scss";
import { IAccount } from "../../../../accounts/types";
import { IMarket } from "../../../../markets/type";
import { IBet } from "../../../service/types";
import classnames from "classnames";
import Meta from "./Meta";
import StakedAt from "./StakedAt";

interface DetailsProps {
    accounts: IAccount[];
    bet: IBet;
    markets: IMarket[];
}

const Details = ({
    accounts,
    bet,
    markets,
}: DetailsProps) => {

    const isCancelled = bet.state === "cancelled";

    return (
        <div data-testid="bet-details" className={classnames(styles.fill_available, isCancelled && styles.cancelled)}>
            <Meta bet={bet} markets={markets} />
            <StakedAt accounts={accounts} bet={bet} />
        </div>
    );
};

export default Details;
