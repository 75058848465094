import * as React from "react";
import styles from "./Row.module.scss";
import CardStyles from "./Card.module.scss";
import classnames from "classnames";

interface RowProps {
    children: React.ReactNode;
    className?: string;
    "data-testid"?: string;
    void?: true;
    onClick?: () => void;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    alternative?: boolean;
}

const Row: React.FC<RowProps> = ({onClick, className, alternative, children, void: isVoid, ...props}) => (
    <div
        onClick={onClick}
        className={classnames(
            styles.row,
            CardStyles.full,
            isVoid ? styles.void : null,
            className,
            onClick ? styles.clickable : null,
            alternative ? styles.alternative : undefined,
        )}
        {...props}
    >
        {children}
    </div>
);

export default Row;
