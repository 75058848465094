import { useState } from "react";

let nextCount = 1;

function useNextId(prefix: string): string {
    return useState(() => {
        return `${prefix}${nextCount++}`;
    })[0];
}

export const reset = () => nextCount = 1;

const exports = {
    useNextId,
};

export default exports;
