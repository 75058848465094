import { ApiResponse, isSuccess } from "../../api/ApiResponse";
import PromiseCache from "../../cache/promise/PromiseCache";
import CallbacksFactory from "../callback/CallbacksFactory";
import IStore from "./IStore";

function isPromiseCache<T>(maybe: PromiseCache<ApiResponse<T>> | T): maybe is PromiseCache<ApiResponse<T>> {
    return maybe instanceof PromiseCache;
}

class SubscriptionStore<T> implements IStore<T> {
    private dataOrRequest: PromiseCache<ApiResponse<T>> | T;
    private readonly callbacks = CallbacksFactory.create<T>();

    constructor(
        loadData: () => Promise<ApiResponse<T>>,
    ) {
        this.dataOrRequest = new PromiseCache(async () => {
            const response = await loadData();
            if (isSuccess(response)) {
                this.dataOrRequest = response.data;
            }
            return response;
        });
    }

    public get(): Promise<ApiResponse<T>> | T {
        return isPromiseCache(this.dataOrRequest) ?
            this.dataOrRequest.getPromise() :
            this.dataOrRequest;
    }

    public update(data: T) {
        if (isPromiseCache(this.dataOrRequest)) {
            throw new Error("Updated store before it loaded");
        }

        this.callbacks.fireCallbacks(this.dataOrRequest = data);
    }

    public subscribe(callback: (data: T) => void): () => void {
        return this.callbacks.subscribe(callback);
    }
}

export default SubscriptionStore;
