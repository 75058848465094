import * as React from "react";

interface MultilineProps {
    content?: string;
}

const Multiline: React.FC<MultilineProps> = ({content}) => (
    <>
    {
        content ? content
            .split(/\r?\n/)
            .map(((line, n) => <div key={n}>{line}</div>))
            : null
    }
    </>
);

export default Multiline;
