
class DateFactory {
    public createDate(value: Date | number | string): Date {
        return new Date(value);
    }

    public parse(value: string): number {
        return Date.parse(value);
    }

    public parseInUsersTimeZone(dateYyyyMmDd: string, time24hr: string) {
        return this.createDate(this.parse(`${dateYyyyMmDd}T${time24hr}`));
    }
}

export default new DateFactory();
