import { createDoneFetch, createErrorFetch, Fetch, isDone } from "../../../fetch";
import UseAll from "../../../fetch/hooks/UseAll";
import NeverChange from "../../../lifecycle/NeverChange";
import UseSports from "../../../sports/hooks/UseSports";
import { ISport } from "../../../sports/Sport";
import UseEvents from "../../hooks/UseEvents";
import { IEvent } from "../../types";

function useEventsAndSports() {
    return UseAll.useAll(
        UseEvents.useEvents(),
        UseSports.useSports(),
    );
}

function useEventAndSports(eventId: string): Fetch<[IEvent, ISport[]]> {
    NeverChange.useNeverChange(eventId);

    const fetch = useEventsAndSports();

    if (isDone(fetch)) {
        const theEvent = fetch.payload[0].find(({id}: IEvent) => id === eventId);
        return theEvent ?
            createDoneFetch([theEvent, fetch.payload[1]]) :
            createErrorFetch(`Could not find an event with the id '${eventId}'`);
    }

    return fetch;
}

const exports = {
    useEventAndSports,
    useEventsAndSports,
};

export default exports;
