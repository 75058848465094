import { AccountType, AccountLike } from "../../types";
import LoadAccounts from "./LoadAccounts";

class LoadAccountProvider {
    public getForType<T extends AccountLike>(accountType: AccountType) {
        switch(accountType) {
            case "bank":
            case "bookmaker":
            case "exchange":
                return () => LoadAccounts.loadAccounts<T>(accountType);
            default:
                throw new Error(`Unexpected type '${accountType}'`);
        }
    }
}

export default new LoadAccountProvider();
