import * as React from "react";
import MessagePane from "../../feedback/pane/MessagePane";
import Logger from "../../logger/Logger";

interface FeedbackPaneProps {
    errorMessage?: string;
    successMessage?: string;
}

const FeedbackPane: React.FC<FeedbackPaneProps> = ({errorMessage, successMessage}) => {
    if (successMessage && errorMessage) {
        Logger.error("Incorrectly called with both success and failure messages:\n"
        + successMessage + "\n"
        + errorMessage);
        return null ;
    }

    if (successMessage || errorMessage) {
        let message: string;
        let type: "error" | "success";

        if (successMessage) {
            type = "success";
            message = successMessage;
        } else {
            type = "error";
            message = errorMessage as string;
        }

        return <MessagePane type={type} message={message} />;
    }

    return null;
};

export default FeedbackPane;
