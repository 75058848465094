class TimeoutService {
    public defer(callback: () => void, delay?: number): number {
        return window.setTimeout(callback, delay);
    }

    public cancel(timerIdFromCallToDefer?: number) {
        window.clearTimeout(timerIdFromCallToDefer);
    }
}

export default new TimeoutService();
