import * as React from "react";
import SpinnerGuard from "../spinner/SpinnerGuard";
import { Fetch, isLoading, isError, isDone } from "./";
import ErrorDisplay from "./internal/ErrorDisplay";

interface FetchGuardProps<T, U = {}> {
    component?: React.ComponentType<{data: T} & U>;
    additionalData?: U;
    errorComponent?: React.ComponentType<{message: string}>;
    fetch: Fetch<T>;
    loadingMessage?: string;
    loadingClassName?: string;
}

const EMPTY_OBJECT = Object.freeze({});

export default function fetchGuard<T, U>(props: FetchGuardProps<T, U>) {
    const fetch = props.fetch;
    const Component = props.component;
    const additional: U = props.additionalData ? props.additionalData : EMPTY_OBJECT as U;

    return  (
        <SpinnerGuard loading={isLoading(fetch)} loadingClassName={props.loadingClassName} message={props.loadingMessage}>
            {isError(fetch) ? <ErrorDisplay component={props.errorComponent} message={fetch.message} /> : null}
            {Component && isDone(fetch) ? <Component data={fetch.payload} {...additional}  /> : null}
        </SpinnerGuard>
    );
}
