import SelectBetType from "../../../../bets/components/SelectBetType";
import { IUsesBetType } from "./types";

interface Props extends IUsesBetType {

}

const BetTypeEntry = ({betType}: Props) => (
    <label>
        Bet type
        <SelectBetType
            onChange={betType[1]}
            value={betType[0]}
            data-testid="betslip-bet-type-selector"
        />
    </label>
);

export default BetTypeEntry;
