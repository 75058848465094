import { ApiCriteria } from "./api-types";
import CriterionFactory from "./criterion/CriterionFactory";
import { Criteria } from "./types";

class CriteriaFactory {
    fromApi([first, ...rest]: ApiCriteria): Criteria {
        return [
            CriterionFactory.fromApi(first),
            ...rest.map((apiCriterion) => CriterionFactory.fromApi(apiCriterion)),
        ];
    }
}

export default new CriteriaFactory();
