import {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";

const ReactHooks = {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
};

export default ReactHooks;
